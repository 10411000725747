import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-floating-btn-full-map',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './floating-btn-full-map.component.html',
  styleUrls: ['./floating-btn-full-map.component.scss'],
})
export class FloatingBtnFullMapComponent {
  constructor(private readonly router: Router) {}
  public openMap(): void {
    this.router.navigate(['karte']);
  }
}
