<form [formGroup]="form">
  <div *ngIf="currentPage === 'start'" class="vnb-user-registration">
    <mat-label class="headline-gotham-black">{{ 'registration.select_user' | translate }}</mat-label>
    <mat-radio-group
      aria-labelledby="Auswahl welche Registration"
      class="user-registration-radio-group"
      formControlName="registrationType">
      <mat-radio-button class="customer-radio" value="0">
        {{ 'registration.select_user.customer' | translate }}
      </mat-radio-button>
      <mat-radio-button class="vnb-radio" value="1">
        {{ 'registration.select_user.vnb' | translate }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <form *ngIf="currentPage !== 'start' && !!companyDataForm"
        [formGroup]="companyDataForm">
    <h4>{{ 'registration.company_data' | translate }}</h4>
    <div class="split-pane">
      <div class="split-pane-container">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>{{ 'registration.company_name' | translate }}</mat-label>
          <input
            autocomplete="company"
            formControlName="companyName"
            matInput
            type="text"
          />
        </mat-form-field>
      </div>
      <div class="split-pane-container">
        <app-address-search-autocomplete
          title="{{'registration.company_address' | translate}}">
        </app-address-search-autocomplete>
      </div>
    </div>
    <ng-container *ngIf="currentPage === 'vnb'">
      <app-customer-vnb-registration></app-customer-vnb-registration>
    </ng-container>
  </form>
</form>
