/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TUserAdminplusCompanyDTO { 
    user_id?: number;
    user_first_name?: string | null;
    user_last_name?: string | null;
    user_mail?: string | null;
    user_state_id?: number;
    user_state_setter_company_name?: string | null;
    user_state_setter_company_id?: number | null;
    user_is_admin?: boolean;
    user_company_name?: string | null;
    user_company_type_id?: number;
    user_insert_time?: string;
}

