import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '@env';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-floating-app-open-button',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  templateUrl: './floating-app-open-button.component.html',
  styleUrls: ['./floating-app-open-button.component.scss'],
})
export class FloatingAppOpenButtonComponent implements OnInit {
  public isIFrame = false;

  constructor(private readonly router: Router) {}

  public ngOnInit(): void {
    if (window.location !== window.parent.location) {
      this.isIFrame = true;
    } else {
      this.isIFrame = false;
    }
  }

  public openAppInNewTab(): void {
    const currentPage = this.router.url;
    window.open(`${environment.webBasePath}${currentPage}`, '_blank');
  }
}
