import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AddressSearchAutocompleteComponent } from './components/address-search-autocomplete/address-search-autocomplete.component';
import { MapComponent } from './components/map/map.component';
import { MapsGeocodingService } from './services/maps-geocoding/maps-geocoding.service';
import { MapsMarkerService } from './services/maps-marker/maps-marker.service';
import { SharedModule } from '../../shared/shared.module';
import { MapsHelperService } from '@map/services/maps-helper.service';
import { MapZipAreaDirective } from './components/map/directives/map-zip-area.directive';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SharedModule,
    GoogleMapsModule,
  ],
  declarations: [
    AddressSearchAutocompleteComponent,
    MapComponent,
    MapZipAreaDirective,
  ],
  exports: [AddressSearchAutocompleteComponent, MapComponent],
  providers: [
    MapsGeocodingService,
    MapsMarkerService,
    MapsHelperService,
    { provide: google.maps, useValue: google.maps },
  ],
})
export class MapModule {}
