import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxsModule } from '@ngxs/store';
import { MapPageComponent } from './map-page.component';
import { FeaturesModule } from '../../features/features.module';
import { SharedModule } from '../../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FloatingAppOpenButtonComponent } from '@components';

@NgModule({
  imports: [
    CommonModule,
    FeaturesModule,
    MatProgressSpinnerModule,
    NgxsModule,
    SharedModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    FloatingAppOpenButtonComponent,
  ],
  declarations: [MapPageComponent],
})
export class MapPageModule {}
