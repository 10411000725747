import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { SetSelectedFacilityId } from '@appState/app.actions';
import { Select, Store } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable } from 'rxjs';
import { BaseFacilityFacade } from '../facade/base-facility.facade';
import { TH2FacilityExtendetDTO } from '@api';

@Component({
  selector: 'app-h2-facility-tile',
  templateUrl: './h2-facility-tile.component.html',
  styleUrls: ['./h2-facility-tile.component.scss'],
  providers: [BaseFacilityFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class H2FacilityTileComponent {
  @Input()
  public facility: TH2FacilityExtendetDTO;

  @Select(AppStateSelectors.isUserH2Customer())
  public isUserH2Customer$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isUserCo2Customer$: Observable<boolean>;

  @Select(AppStateSelectors.isUserVNB())
  public isVNB$: Observable<boolean>;

  public constructor(
    private readonly store: Store,
    private readonly baseFacilityFacade: BaseFacilityFacade,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public openNewPLZ(facilityId: number): void {
    this.store.dispatch(new SetSelectedFacilityId(facilityId));
    this.baseFacilityFacade.openNewPlzDialog();
  }

  public onShowInformation(facility: TH2FacilityExtendetDTO): void {
    this.baseFacilityFacade.onShowInformation(facility);
  }

  public onDeleteFacility(facility: TH2FacilityExtendetDTO): void {
    this.baseFacilityFacade.onDeleteFacility(facility);
    this.changeDetectorRef.detectChanges();
  }
}
