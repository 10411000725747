<button (click)="backToStart()"
        *ngIf="currentPage !== 'start' && domainIsNotExists; else filler"
        class="back-button"
        mat-icon-button>
    <mat-icon>west</mat-icon>
</button>
<ng-template #filler>
    <div class="filler"></div>
</ng-template>
<h3 *ngIf="domainIsNotExists" class="color-white">{{ 'registration.register_company' | translate }}</h3>
<h3 *ngIf="!domainIsNotExists" class="color-white">{{ 'registration.register_company_for_company' | translate: {'company': (usersCompanyData$ | async)?.company_name} }}</h3>