/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TCreateStandardLocationDTO { 
    location_city?: string | null;
    location_latitude?: number;
    location_longitude?: number;
    location_street?: string | null;
    location_street_number?: string | null;
    location_zip_code?: string | null;
}

