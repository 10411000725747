import { Inject, Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  GetUsersCompany,
  InitAppState,
  LoadCustomerLogo,
  SetAccountInfo,
  SetCompany,
  SetUser,
  SetUserMustCompleteRegistration,
  UpdateUserInBE,
} from '@appState/app.actions';
import { Select, Store } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable, of, take } from 'rxjs';
import {
  AuthenticationResult,
  EndSessionRequest,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  TCompanyShortPlusOptionalPlzListeDTO,
  TUserDTO,
  TUserWithStateDTO,
  UserDomainService,
  UserService,
} from '@api';
import { environment } from '@env';
import { DialogService } from '@services';
import { MatDialogRef } from '@angular/material/dialog';
import { UserStatus } from '@enums';
import { RegistrationEntryComponent } from '@registration/components/registration-entry/registration-entry.component';
import { Router } from '@angular/router';
import { AppViewEnum } from '../../../shared/enums/app-view-enum';

@UntilDestroy()
@Injectable()
export class AuthenticationService {
  @Select(AppStateSelectors.isUserLoggedIn())
  public readonly isUserLoggedIn$?: Observable<boolean>;

  @Select(AppStateSelectors.getNameOfUser())
  public readonly username$?: Observable<string>;

  @Select(AppStateSelectors.getUsersCompanyName())
  public readonly userCompanyName$: Observable<string>;

  @Select(AppStateSelectors.getCompanyLogo())
  public companyLogo$: Observable<string>;

  @Select(AppStateSelectors.isUserAllowed())
  public readonly isUserAllowed$: Observable<boolean>;

  @Select(AppStateSelectors.isUserOGE())
  public readonly isUserOGE$: Observable<boolean>;

  @Select(AppStateSelectors.getAppView())
  public readonly appView$: Observable<AppViewEnum>;

  @Select(AppStateSelectors.isUserVNB())
  public readonly isUserVNB$: Observable<boolean>;

  @Select(AppStateSelectors.isUserAdmin())
  public readonly isUserAdmin$: Observable<boolean>;

  @Select(AppStateSelectors.getUser())
  public readonly user$: Observable<TUserWithStateDTO | TUserDTO>;

  private registrationDialog: MatDialogRef<RegistrationEntryComponent> = null;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly userService: UserService,
    private readonly userDomainService: UserDomainService,
    private readonly dialogService: DialogService,
    @Inject('Window') private readonly windowRef: Window
  ) {}

  public reloginUser() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          const account = this.msalService.instance.getActiveAccount();
          if (account) {
            this.msalService
              .acquireTokenSilent({
                scopes: [],
                forceRefresh: true,
                authority:
                  environment.oauth.b2cPolicies.authorities.signUpSignIn
                    .authority,
                account: account,
              })
              .subscribe({
                next: (result: AuthenticationResult) => {
                  this.setActiveAccount(result);
                  this.loadUserDataAfterLogin();
                },
                error: () => {
                  this.logout();
                },
              });
          }
        },
      });
  }

  public login(request?: RedirectRequest): void {
    if (this.windowRef.location !== this.windowRef.parent.location) {
      // over iFrame
      this.msalService.loginPopup(request).subscribe({
        next: (result: AuthenticationResult) =>
          this.handleMsalLoginRequestResult(result),
      });
    } else {
      this.msalService.loginRedirect(request);
    }
  }

  public logout(): void {
    if (this.windowRef.location !== this.windowRef.parent.location) {
      // over iFrame
      this.msalService.logoutPopup({ mainWindowRedirectUri: '/karte' });
    } else {
      const isCo2 = this.router.url.includes('co2');
      const request: EndSessionRequest = {
        postLogoutRedirectUri: isCo2
          ? `${environment.oauth.postLogoutRedirectUri}/co2`
          : environment.oauth.postLogoutRedirectUri,
      };
      this.msalService.logoutRedirect(request);
    }
    this.unsetAccountInfo();
    this.store.dispatch(new InitAppState());
  }

  public register(): void {
    const isUserLoggedIn = this.store.selectSnapshot(
      AppStateSelectors.isUserLoggedIn()
    );
    if (isUserLoggedIn) {
      this.store.dispatch(new SetUserMustCompleteRegistration());
      if (!this.registrationDialog) {
        this.registrationDialog = this.dialogService.openDialog(
          RegistrationEntryComponent,
          {
            backdropClass: 'blurred',
            maxWidth: '100vw',
            maxHeight: '100vh',
            hasBackdrop: true,
            disableClose: true,
            panelClass: 'form-dialog',
          }
        );
      }

      if (!this.registrationDialog) {
        this.registrationDialog
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => (this.registrationDialog = null));
      }
    } else {
      const isCo2 = this.router.url.includes('co2');
      const request: RedirectRequest = {
        scopes: [],
        authority: environment.oauth.b2cPolicies.authorities.signUp.authority,
        redirectUri: isCo2
          ? environment.oauth.redirectUriCo2
          : environment.oauth.redirectUri,
      };
      this.login(request);
    }
  }

  public handleMsalLoginRedirect() {
    this.msalService
      .handleRedirectObservable()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: AuthenticationResult) =>
          this.handleMsalLoginRequestResult(result),
        error: error => {
          console.error(`MSAL-Login Fehler: ${error}`);
          // this.logout();
        },
      });

    this.loadUserDataAfterLogin();
  }

  private handleMsalLoginRequestResult(result: AuthenticationResult) {
    if (result?.account) {
      this.setActiveAccount(result);
      if (
        result.authority ===
        environment.oauth.b2cPolicies.authorities.signUp.authority
      ) {
        this.register();
      }
    } else {
      this.reloginUser();
    }
  }

  private setActiveAccount(result: AuthenticationResult) {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts.length > 0) {
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.store.dispatch(new SetAccountInfo(result));
    }
  }

  private unsetAccountInfo(): void {
    this.msalService.instance.setActiveAccount(null);
    this.store.dispatch(new SetAccountInfo());
  }

  private loadUserDataAfterLogin(): void {
    this.isUserLoggedIn$.pipe(untilDestroyed(this)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        const userMail = this.store.selectSnapshot(
          AppStateSelectors.getUsername()
        );
        const mailDomain = userMail.split('@')[1];
        this.userDomainService
          .apiUserDomainDomainGet(mailDomain)
          .pipe(
            switchMap(
              (userDomainResult: TCompanyShortPlusOptionalPlzListeDTO) => {
                if (
                  userDomainResult.non_optionale_versions_nummer !==
                  environment.versionNumber
                ) {
                  return of(null);
                }
                if (userDomainResult.company_id > 0) {
                  this.store.dispatch(new SetCompany(userDomainResult));
                }
                // check is user done with registration
                const userMail = this.store.selectSnapshot(
                  AppStateSelectors.getUsername()
                );
                return this.userService.apiUserEmailGet(userMail);
              }
            )
          )
          .subscribe({
            next: (userResult: TUserWithStateDTO) => {
              if (userResult !== null) {
                // check is user locked!!
                if (userResult.user_state_id === UserStatus.closed) {
                  this.store.dispatch(new SetUser(userResult, true));
                  // show dialog and disabled anything!
                  const contentParams =
                    userResult.user_state_setter_company_id === 1
                      ? null
                      : {
                          company_name:
                            userResult.user_state_setter_company_name,
                        };
                  this.dialogService.showCustomDialog({
                    title: 'app.user.locked_title',
                    content:
                      userResult.user_state_setter_company_id === 1
                        ? 'app.user.locked_content_oge'
                        : userResult.user_state_setter_company_name ===
                            userResult.user_company_name
                          ? 'app.user.locked_content_vnb'
                          : 'app.user.locked_content_customer',
                    contentParams,
                    color: 'INFO',
                  });
                } else {
                  // user has company and domain
                  this.store.dispatch(new SetUser(userResult));
                  this.store.dispatch(new GetUsersCompany());
                  this.store.dispatch(new LoadCustomerLogo());
                  this.store.dispatch(new UpdateUserInBE());
                }
              } else {
                // show dialog?
                this.dialogService.showCustomDialog({
                  title: 'app.old_version.title',
                  content: 'app.old_version.content',
                  color: 'INFO',
                });
              }
            },
            error: err => {
              // user has no company
              this.register();
            },
          });
      }
    });
  }
}
