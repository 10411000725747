<div class="dashboard-wrapper">
  <div class="dashboard-background"></div>
  <div class="grid">
    <div class="grid-item info-box item-graph">
      <app-dashboard-graph *ngIf="(isCo2View$ | async) === false; else co2Graph"></app-dashboard-graph>
      <ng-template #co2Graph>
        <app-co2-graph></app-co2-graph>
      </ng-template>
    </div>

    <div class="grid-item info-box item-table">
      <app-dashboard-facilities-companies></app-dashboard-facilities-companies>
    </div>

    <div class="grid-item info-box item-map">
      <app-map></app-map>
      <app-floating-btn-full-map></app-floating-btn-full-map>
      <app-floating-app-open-button></app-floating-app-open-button>
    </div>
    <div class="grid-item grid-item-container">
      <div class="info-box item-overview">
        <app-dashboard-network-overview></app-dashboard-network-overview>
      </div>
      <button (click)="openRegisterLocation()"
              [disabled]="(isUserVnb$ | async) || (isUserOGE$ | async)" class="grid-item-button headline-gotham-black"
              color="primary" mat-flat-button>
        {{ 'registration.register_location_button' | translate }}
        <mat-icon fontIcon="add_circle" iconPositionEnd></mat-icon>
      </button>
    </div>
    <div class="small-screen-only grid-item item-button">
      <button (click)="openRegisterLocation()"
              [disabled]="(isUserVnb$ | async)|| (isUserOGE$ | async)" class="grid-item-button headline-gotham-black"
              color="primary" mat-flat-button>
        {{ 'registration.register_location_button' | translate }}
        <mat-icon fontIcon="add_circle" iconPositionEnd></mat-icon>
      </button>
    </div>
  </div>
</div>
