import { NgModule } from '@angular/core';
import { DashboardPageModule } from './dashboard/dashboard.page.module';
import { MapPageModule } from './map/map-page.module';
import { OssLicensesComponent } from './oss-licenses/oss-licenses.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FaqPage } from './faq/faq.page';
import { ImprintPage } from './imprint/imprint.page';
import { SharedModule } from '../shared/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { DataTablePageModule } from '@pages/data-table/data-table.page.module';

@NgModule({
  declarations: [OssLicensesComponent, AboutUsComponent, FaqPage, ImprintPage],
  imports: [
    DashboardPageModule,
    MapPageModule,
    MatExpansionModule,
    CommonModule,
    SharedModule,
    DataTablePageModule,
  ],
})
export class PagesModule {}
