import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DashboardStateModel } from './dashboard-state.model';
import {
  GetDashboardFacilitiesAndCompanies,
  GetDashboardOverviewNetworkArea,
  GetVnbPlz,
  GetYearlySummary,
  SetActiveUnit,
  SetDataType,
  SetDisplayUnit,
} from './dashboard.actions';
import { H2YearlySummaryGraphDataModel } from '../models/h2-yearly-summary-graph-data.model';
import { UnitType } from '../models/unit-type';
import { DataType } from '../models/data-type';
import {
  DashboardOverviewNetworkAreaService,
  NewRegistrationService,
  TAmountPerYearDTO,
  TDashboardOverviewNetworkDTO,
  TNewRegistrationDTO,
  TVNBListDTO,
  VNBPLZService,
  YearlySummaryService,
} from '@api';
import { ActionState } from '@interfaces';

const DASHBOARD_STATE_TOKEN: StateToken<DashboardStateModel> = new StateToken(
  'dashboardState'
);

@State<DashboardStateModel>({
  name: DASHBOARD_STATE_TOKEN,
  defaults: {
    actionState: ActionState.create(),
    activeUnitType: UnitType.KWH,
    activeDataType: DataType.POWER,
    vnbList: [],
  },
})
@Injectable()
export class DashboardState {
  public constructor(
    private readonly dashboardOverviewNetworkAreaService: DashboardOverviewNetworkAreaService,
    private readonly yearlySummaryService: YearlySummaryService,
    private readonly vnbPlzService: VNBPLZService,
    private readonly newRegistrationService: NewRegistrationService
  ) {}

  @Action(GetYearlySummary)
  public getYearlySummary(
    context: StateContext<DashboardStateModel>
  ): Observable<TAmountPerYearDTO> {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    return this.yearlySummaryService.apiYearlySummaryGet().pipe(
      tap({
        next: (response: TAmountPerYearDTO): void => {
          //  H2 Data
          if (response.h2_amounts.length !== 0) {
            const demand: Record<
              keyof H2YearlySummaryGraphDataModel['demand'],
              number[]
            > = {
              powerKg: [],
              powerKwh: [],
              energyKg: [],
              energyKwh: [],
            };
            const production: typeof demand = {
              powerKg: [],
              powerKwh: [],
              energyKg: [],
              energyKwh: [],
            };
            const labels: string[] = [];

            for (const amountPerYearDTO of response.h2_amounts) {
              demand.powerKg.push(amountPerYearDTO.demand.powerKg);
              demand.powerKwh.push(amountPerYearDTO.demand.powerKwh);
              demand.energyKg.push(amountPerYearDTO.demand.energyKg);
              demand.energyKwh.push(amountPerYearDTO.demand.energyKwh);

              production.powerKg.push(amountPerYearDTO.production.powerKg);
              production.powerKwh.push(amountPerYearDTO.production.powerKwh);
              production.energyKg.push(amountPerYearDTO.production.energyKg);
              production.energyKwh.push(amountPerYearDTO.production.energyKwh);

              labels.push(amountPerYearDTO.year.toString(10));
            }

            context.patchState({
              actionState: ActionState.onDone(),
              company: response.company,
              graphData: {
                labels,
                demand,
                production,
              },
            });
          }
          // Co2 Data
          else if (response.co2_amounts.length !== 0) {
            const sink: number[] = [];
            const source: number[] = [];
            const labels: string[] = [];

            for (const amountPerYearDTO of response.co2_amounts) {
              sink.push(amountPerYearDTO.demand.tons);
              source.push(amountPerYearDTO.production.tons);
              labels.push(amountPerYearDTO.year.toString(10));
            }

            context.patchState({
              actionState: ActionState.onDone(),
              company: response.company,
              graphData: {
                labels,
                sink,
                source,
              },
            });
          }
        },
        error: () => {
          context.patchState({
            actionState: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(GetDashboardOverviewNetworkArea)
  public dashboardOverviewNetworkArea(
    context: StateContext<DashboardStateModel>
  ): Observable<TDashboardOverviewNetworkDTO> {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    return this.dashboardOverviewNetworkAreaService
      .apiDashboardOverviewNetworkAreaGet()
      .pipe(
        tap({
          next: (response: TDashboardOverviewNetworkDTO): void => {
            context.patchState({
              actionState: ActionState.onDone(),
              networkAreaOverview: response,
            });
          },
          error: () => {
            context.patchState({
              actionState: ActionState.onError(true),
            });
          },
        })
      );
  }

  @Action(GetDashboardFacilitiesAndCompanies)
  public getDashboardFacilitiesAndCompanies(
    context: StateContext<DashboardStateModel>
  ): Observable<TNewRegistrationDTO> {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    return this.newRegistrationService.apiNewRegistrationGet().pipe(
      tap({
        next: (response: TNewRegistrationDTO): void => {
          context.patchState({
            actionState: ActionState.onDone(),
            facilitiesAndCompanies: response,
          });
        },
        error: () => {
          context.patchState({
            actionState: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(SetDisplayUnit)
  public setDisplayUnit(
    context: StateContext<DashboardStateModel>,
    action: SetDisplayUnit
  ): void {
    context.setState(
      patch({
        displayUnit: action.unit,
      })
    );
  }

  @Action(SetActiveUnit)
  public setActiveUnit(
    context: StateContext<DashboardStateModel>,
    action: SetActiveUnit
  ): void {
    context.setState(
      patch({
        activeUnitType: action.unit,
      })
    );
  }

  @Action(SetDataType)
  public setDataType(
    context: StateContext<DashboardStateModel>,
    action: SetDataType
  ): void {
    context.setState(
      patch({
        activeDataType: action.dataType,
      })
    );
  }

  @Action(GetVnbPlz)
  public getVnbPlz(
    context: StateContext<DashboardStateModel>,
    action: GetVnbPlz
  ): Observable<TVNBListDTO> {
    context.patchState({
      vnbList: [],
    });
    return this.vnbPlzService.apiVNBPLZZipCodeGet(action.plz).pipe(
      tap({
        next: (result: TVNBListDTO) => {
          context.patchState({
            vnbList: result.vnb_liste,
            actionState: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            actionState: ActionState.onError(true),
          });
        },
      })
    );
  }
}
