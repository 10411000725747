/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EAlternativ } from './eAlternativ';


export interface TCO2FacilityExtendetDTO { 
    facility_name?: string | null;
    facility_city?: string | null;
    facility_latitude?: number;
    facility_longitude?: number;
    facility_street?: string | null;
    facility_street_number?: string | null;
    facility_zip_code?: string | null;
    facility_first_year_of_transport_capability?: number;
    facility_type_id?: number;
    facility_emission_type_id?: number;
    facility_customer_declaration_typ_id?: number;
    facility_customer_declaration?: string | null;
    facility_transport_target?: string | null;
    facility_alternative_selection?: EAlternativ;
    facility_alternative_text?: string | null;
    facility_description?: string | null;
    facility_data_sharing_with_authority_consent?: boolean;
    facility_data_sharing_with_third_part_consent?: boolean;
    facility_id?: number;
    facility_insert_time?: string;
    facility_company_id?: number;
    facility_company_name?: string | null;
}

