<div *ngIf="(mapIsLoading$ | async);"
     class="spinner-container">
  <app-loading-spinner></app-loading-spinner>
</div>
<google-map *ngIf="showMap" width="100%" height="100%"
            [options]="mapOptions">
    <ng-container>
        <map-marker *ngFor="let marker of getFacilityListMarkers$ | async"
                    (mapMouseover)="openInfoWindow(marker)"
                    (mapMouseout)="closeInfoWindow(marker)"
                    (mapClick)="clickEvent(marker)"
                    [visible]="!!marker['map']"
                    [icon]="marker.icon"
                    [options]="marker.options"
                    [position]="marker.position">
        </map-marker>
    </ng-container>
</google-map>

<ng-container *ngIf="map?.googleMap !== null && (isUserAllowed$ | async)">
  <div class="elements">
    <ng-container *ngFor="let zip of companyZipCodes$ | async">
      <app-map-zip-area [map]="map.googleMap"
                        [zipCode]="zip"></app-map-zip-area>
    </ng-container>
  </div>
</ng-container>
