<div class="facility-card-container">
  <div [class.isVNB]="isVNB$ | async" class="facility-card">
    <div class="facility_name">
      <span class="name"> {{ facility.facility_name }}</span>
      <span class="color-copy"> {{ facility.facility_zip_code }} {{ facility.facility_city }}</span>
    </div>
    <div class="facility_facilty_type center">
      <app-facility-type-badge [isCo2]="isUserCo2Customer$ | async" [typeId]="facility.facility_type_id"></app-facility-type-badge>
    </div>
    <div class="facility_malo_id center">
      {{ facility['facility_malo_id'] }}
    </div>
    <div *ngIf="isVNB$ | async" class="facility_company_name center">
      <span>{{ facility.facility_company_name }}</span>
    </div>
    <div class="facility_insert_time actions">
      <button (click)="openNewPLZ(facility.facility_id)"
              *ngIf="facility.facility_is_dangling && (isUserH2Customer$ | async)"
              class="button-gotham"
              color="primary"
              mat-flat-button>
        {{ 'facility-tile.actions.facility_reassign'| translate }}
        <mat-icon iconPositionEnd>arrow_forward</mat-icon>
      </button>
      <button [matMenuTriggerFor]="menu" class="more-icon" mat-icon-button>
        <mat-icon fontIcon="more_horiz"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="onShowInformation(facility)" mat-menu-item>{{ 'facility-tile.show_info' | translate }}</button>
        <button (click)="onDeleteFacility(facility)"
                *ngIf="isUserH2Customer$ | async" mat-menu-item>{{ 'data_table.actions.delete_facility'| translate }}
        </button>
      </mat-menu>
    </div>
  </div>
</div>
