import type { ChartConfiguration, ScriptableContext } from 'chart.js';
import type { TooltipItem } from 'chart.js/dist/types';

export const dashboardGraphOptions: ChartConfiguration<'bar'>['options'] = {
  responsive: true,
  maintainAspectRatio: false,

  animation: {
    delay: (context: ScriptableContext<'bar'>): number => {
      let delay = 0;

      if (context.type === 'data' && context.mode === 'default') {
        delay = context.dataIndex * 50 + context.datasetIndex * 100;
      }

      return delay;
    },
  },

  datasets: {
    bar: {
      borderRadius: 0,
    },
  },

  layout: {
    padding: {
      top: 80,
    },
  },

  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#6187A8',
        font: {
          size: 12,
        },
      },
    },
    y: {
      grid: {
        color: 'rgba(20, 60, 108, 0.10)',
      },
      border: {
        display: false,
      },
      ticks: {
        padding: 20,
        precision: 0,
        color: '#6187A8',
        font: {
          size: 12,
        },
        count: 4,
        align: 'end',
        format: {
          maximumFractionDigits: 3,
        },
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },

    tooltip: {
      backgroundColor: '#fff',
      bodyAlign: 'center',
      bodyColor: '#304257',
      bodyFont: {
        family: 'Arial',
        size: 12,
        weight: 'normal',
      },
      callbacks: {
        title: (): string | string[] | void => '',
        label: (item: TooltipItem<'bar'>): string | string[] | void => {
          return item.formattedValue;
        },
      },
      caretSize: 4,
      caretPadding: 8,
      cornerRadius: 2,
      displayColors: false,
      position: 'nearest',
      titleColor: '#304257',
      xAlign: 'center',
      yAlign: 'bottom',
    },
  },
};
