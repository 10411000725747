<header>
    <button class="close-button" mat-dialog-close mat-icon-button>
        <mat-icon fontIcon="close"></mat-icon>
    </button>
    <h3>{{'dialog.edit_vnb_plz.header' | translate}}</h3>
</header>

<mat-dialog-content>
    <form [formGroup]="form">
        <app-verteilnetzbetreiber></app-verteilnetzbetreiber>
    </form>
</mat-dialog-content>

<mat-dialog-actions [formGroup]="form">
    <div class="checkbox-container">
        <ng-container *ngIf="(vnbs$ | async).length > 0">
            <mat-checkbox color="accent" formControlName="legal">
                <mat-label>
                    {{ 'registration.location.accepted' | translate }}
                </mat-label>
            </mat-checkbox>
            <div *ngIf="hasPolicy()"
                 class="checkbox-href-container default-text">
                <mat-checkbox color="accent" formControlName="policy">
                    <mat-label>
                        {{ 'registration.terms_policy_start' | translate }}
                        <button (click)="openPolicy()" class="button-as-Link medium">{{ 'registration.policy' | translate }}</button>
                        {{ 'registration.terms_policy_end' | translate }}
                    </mat-label>
                </mat-checkbox>
            </div>
        </ng-container>
    </div>
    <button (click)="onSave()"
            color="accent"
            mat-flat-button
            [disabled]="form.invalid"
            type="submit">
        {{ 'dialog.actions.save' | translate }}
    </button>
</mat-dialog-actions>
