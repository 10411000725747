import { Injectable } from '@angular/core';
import { SpecificErrors, noErrorForSpecificUrls } from '../../specific-errors';
import { DialogService } from '@services';
import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private readonly dialogService: DialogService) {}

  public handleGatewayTimeout(error: HttpErrorResponse) {
    this.dialogService.showCustomDialog({
      title: 'dialog.error.title',
      content: environment.production
        ? 'dialog.error.default_content_with_param'
        : 'dialog.error.default_content_with_param',
      contentParams: environment.production ? null : { err: error.message },
      color: 'ERROR',
    });
  }

  public handleVPNError() {
    this.dialogService.showCustomDialog({
      title: 'dialog.error.title',
      content: 'dialog.error.vpn_default_content',
      color: 'ERROR',
    });
  }

  public showError(errorMsg: string | any) {
    // check is error a specific errors
    const errorKeyExists = Object.keys(SpecificErrors).find(key => {
      errorMsg = errorMsg.toString();
      return errorMsg.includes(key);
    });
    if (errorKeyExists) {
      errorMsg = SpecificErrors[errorKeyExists];
    }

    // else use normal details from BE
    this.dialogService.showCustomDialog({
      title: 'dialog.error.title',
      content: errorMsg,
      color: 'ERROR',
      actions: {
        acceptLabel: 'dialog.accept',
      },
    });
  }

  public handleFailedRequests(
    error: HttpErrorResponse,
    request: HttpRequest<any>
  ): boolean {
    // some request are 'okay' with failed so do not show an error
    const showNoError = Object.keys(noErrorForSpecificUrls).find(key => {
      if (request.url.includes(key)) {
        if (error) {
          if (error.error?.detail) {
            return (error.error.detail as string).includes(
              noErrorForSpecificUrls[key]
            );
          } else {
            return (error.error.title as string).includes(
              noErrorForSpecificUrls[key]
            );
          }
        }
      }
      // @TODO set all actionState to done
      return false;
    });
    return !!showNoError;
  }
}
