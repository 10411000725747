import { Injectable } from '@angular/core';
import { DataType } from '@pages/dashboard/models/data-type';
import { UnitType } from '@pages/dashboard/models/unit-type';
import { Store } from '@ngxs/store';
import { SetDisplayUnit } from '@pages/dashboard/state/dashboard.actions';

@Injectable()
export class UnitConvertorService {
  /* Einheiten:
  // Masse => kg-t-kt-Mt-Gt
  // Watt => kWh-MWh-GWh-TWh
  // energy ohne hour
  // power(kapa) mit hour
  */
  constructor(private readonly store: Store) {}

  public convertYearlySummaryToUnit(
    avgLength: number,
    yearlySummary: readonly number[],
    activeDataType: DataType,
    unitType: UnitType
  ): number[] {
    const yearlySummaryCopy = [...yearlySummary];
    const avgUnit = this.getAverageUnitByDataType(
      avgLength,
      activeDataType,
      unitType
    );
    return yearlySummaryCopy.map(year => {
      if (avgUnit === 'mt' || avgUnit === 'twh') {
        // kg zu Mt bzw. kWh zu TWh, z.B. 10.000.000.000.000 kg → 10.000 Mt
        return year / 1000000000;
      } else if (avgUnit === 'kt' || avgUnit === 'gwh') {
        // kg zu kt bzw. kWh zu GWh, z.B. 10.000.000.000 kg → 10.000 kt
        return year / 1000000;
      } else if (avgUnit === 't' || avgUnit === 'mwh') {
        // kg zu t oder kWh zu MWh, z.B. 10.000.000 kg -> 10.000 t
        return year / 1000;
      } else {
        // kg bzw. kWh
        return year;
      }
    });
  }

  public averageUnit(
    productionSummary: readonly number[],
    demandSummary: readonly number[]
  ): number {
    const productionCopySummary = [...productionSummary];
    const productionSum = productionCopySummary.reduce((a, b) => a + b, 0);
    // const productionAvg = productionSum / productionCopySummary.length || 0;
    const productionLengthOfAvg = Math.trunc(productionSum).toString().length;

    const demandCopySummary = [...demandSummary];
    const demandSum = demandCopySummary.reduce((a, b) => a + b, 0);
    // const demandAvg = demandSum / demandCopySummary.length || 0;
    const demandLengthOfAvg = Math.trunc(demandSum).toString().length;

    return (productionLengthOfAvg + demandLengthOfAvg) / 2;
  }

  private getAverageUnitByDataType(
    lengthOfAvg: number,
    dataType: DataType,
    unitType: UnitType
  ): string {
    this.store.dispatch(
      new SetDisplayUnit(
        this.getDisplayUnitByDataType(lengthOfAvg, dataType, unitType)
      )
    );
    if (lengthOfAvg >= 13) {
      // mt oder Twh
      return unitType === UnitType.KG ? 'mt' : 'twh';
    } else if (lengthOfAvg >= 11) {
      // kt oder gwh
      return unitType === UnitType.KG ? 'kt' : 'gwh';
    } else if (lengthOfAvg >= 8) {
      // tonne oder mwh
      return unitType === UnitType.KG ? 't' : 'mwh';
    } else {
      // kg oder kwh
      return unitType === UnitType.KG ? 'kg' : 'kwh';
    }
  }

  private getDisplayUnitByDataType(
    lengthOfAvg: number,
    dataType: DataType,
    unitType: UnitType
  ): string {
    if (lengthOfAvg >= 13) {
      // mt oder Twh
      return this.getUnitByDataType(dataType, unitType, {
        kg: 'mt',
        kwh: 'twh',
      });
    } else if (lengthOfAvg >= 11) {
      // kt oder gwh
      return this.getUnitByDataType(dataType, unitType, {
        kg: 'kt',
        kwh: 'gwh',
      });
    } else if (lengthOfAvg >= 8) {
      // tonne oder mwh
      return this.getUnitByDataType(dataType, unitType, {
        kg: 't',
        kwh: 'mwh',
      });
    } else {
      // kg oder kwh
      return this.getUnitByDataType(dataType, unitType, {
        kg: 'kg',
        kwh: 'kwh',
      });
    }
  }

  private getUnitByDataType(
    dataType: DataType,
    unitType: UnitType,
    displayUnit: { kg: string; kwh: string }
  ): string {
    if (dataType === DataType.ENERGY) {
      return unitType === UnitType.KG ? displayUnit.kg : displayUnit.kwh;
    } else {
      return unitType === UnitType.KG
        ? `${displayUnit.kg}_h`
        : `${displayUnit.kwh}_h`;
    }
  }
}
