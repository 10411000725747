import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentService, TCreateDokumentDTO, TDokumentDTO } from '@api';

export enum DOCUMENT_TYPE_ENUM {
  DATENSCHUTZERKLAERUNG = 0,
  AGB = 1,
  LOGO = 2,
  PROCEDURAL_RULES = 3,
}

@Injectable()
export class LegalService {
  constructor(private readonly documentService: DocumentService) {}

  public getPolicy(companyId?: number): Observable<TDokumentDTO> {
    if (!companyId) {
      // companyId 1 is OGE
      companyId = 1;
    }
    return this.getDocument(
      companyId,
      DOCUMENT_TYPE_ENUM.DATENSCHUTZERKLAERUNG
    );
  }

  public getTermsAndConditions(companyId?: number): Observable<TDokumentDTO> {
    if (!companyId) {
      // companyId 1 is OGE
      companyId = 1;
    }
    return this.getDocument(companyId, DOCUMENT_TYPE_ENUM.AGB);
  }

  public getProceduralRules(): Observable<TDokumentDTO> {
    return this.getDocument(1, DOCUMENT_TYPE_ENUM.PROCEDURAL_RULES);
  }

  public getCustomerLogo(companyId: number): Observable<TDokumentDTO> {
    return this.getDocument(companyId, DOCUMENT_TYPE_ENUM.LOGO);
  }

  public openPDF(data: string, title?: string): void {
    const byteArray = new Uint8Array(
      atob(data)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.style.border = 'none';

    const newWindow = window.open('', '_blank');
    newWindow.document.body.appendChild(iframe);
    newWindow.document.title = title ?? 'HyCo Connect Dokument';
    newWindow.document.body.style.margin = '0';
  }

  public putDocument(documentTypeId: number, file: TCreateDokumentDTO) {
    return this.documentService.apiDokumenteDocumentDocumentTypeIdPut(
      documentTypeId,
      file
    );
  }

  private getDocument(
    companyId: number,
    documentTypeId: number
  ): Observable<TDokumentDTO> {
    return this.documentService.apiDokumenteDocumentCompanyIdDocumentTypeIdGet(
      companyId,
      documentTypeId
    );
  }
}
