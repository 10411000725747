<footer>
    <div class="prod-version">
        <span [class.not-visible]="env.production" class="default-text">Version: {{getReleaseVersion()}}</span>
    </div>
    <nav class="legal">
        <a appRelativeLink="impressum">
            {{ 'navMenu.items.imprint' | translate | titlecase }}
        </a>
        <button class="button-as-Link small default-color" (click)="openPolicy()">
            {{ 'navMenu.items.dataprotection' | translate | titlecase }}
        </button>
        <button class="button-as-Link small default-color" (click)="openTerms()">
            {{ 'navMenu.items.agb' | translate }}
        </button>
        <a appRelativeLink="licenses">
            {{ 'navMenu.items.licenses' | translate }}
        </a>
    </nav>
</footer>