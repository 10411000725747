/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TPutH2Company { 
    company_name?: string | null;
    company_location_city?: string | null;
    company_location_latitude?: number;
    company_location_longitude?: number;
    company_location_street?: string | null;
    company_location_street_number?: string | null;
    company_location_zip_code?: string | null;
    company_zip_code_list?: Array<string> | null;
}

