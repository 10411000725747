import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  CompanyAddressFormModel,
  CustomerCompanyAddressModel,
} from '@registration/interfaces';
import { FacilityCo2Type, FacilityH2Type } from '@enums';

export enum DefaultUnitTypeEnum {
  MWH_H = 'mwh-h',
  MWH = 'mwh',
  KWH_H = 'kwh-h',
  KWH = 'kwh',
  KG_H = 'kg-h',
  KG = 'kg',
}

export enum AlternativeTransportEnum {
  NONE = 0,
  ZUG = 1,
  SCHIFF = 2,
  LKW = 4,
  FREITEXT = 8,
}

export enum CustomerDeclaration {
  NONE = 31,
  YES = 32,
  NO = 33,
}

export enum EmissionType {
  None = 21,
  Process = 22,
  Energy = 23,
  ProcessAndEnergy = 24,
}

export interface RegisterFacilityFormSmall {
  facility: FormGroup<BaseFacilityForm>;
  vnb: FormControl<number>;
  legal: FormControl<boolean>;
  policy: FormControl<boolean>;
}

export interface RegisterH2FacilityForm {
  facility: FormGroup<H2FacilityForm>;
  capacity: FormGroup<UnitForm>;
  energy: FormGroup<UnitForm>;
  vnb: FormControl<number>;
  legal: FormControl<boolean>;
  policy: FormControl<boolean>;
}

export interface RegisterCo2FacilityForm {
  facility: FormGroup<Co2FacilityForm>;
  consume_list: FormArray<FormGroup<Co2YearFormGroup>>;
  produce_list: FormArray<FormGroup<Co2YearFormGroup>>;
  alternativeTransportTypeId: FormControl<AlternativeTransportEnum[]>;
  alternativeTransport: FormControl<string>;
  customerDeclaration: FormControl<string>;
  customerDeclarationTypeId: FormControl<CustomerDeclaration>;
  transportTarget: FormControl<string>;
  description: FormControl<string>;
  sharingWithAuthorities: FormControl<boolean>;
  sharingWithThirdParty: FormControl<boolean>;
}

export interface BaseFacilityForm {
  companyAddress: FormGroup<CompanyAddressFormModel>;
}

export interface H2FacilityForm extends BaseFacilityForm {
  requirementProduction: FormControl<FacilityH2Type | undefined>;
  facilityName: FormControl<string | undefined>;
  maloId: FormControl<number | undefined>;
}

export interface Co2FacilityForm extends BaseFacilityForm {
  typeId: FormControl<FacilityCo2Type>;
  facilityName: FormControl<string>;
  firstYearOfTransportCapability: FormControl<number>;
  emissionTypeId: FormControl<EmissionType>;
}

export interface UnitForm {
  unit: FormControl<DefaultUnitTypeEnum | undefined>;
  years: FormGroup;
}

export interface Co2YearFormGroup {
  year: FormControl<number>;
  ton: FormControl<number>;
  bio_ratio: FormControl<number>;
  hour: FormControl<number>;
}

export interface RegisterFacilityModel {
  facility: {
    requirementProduction: FacilityH2Type;
    facilityName: string;
    companyAddress: CustomerCompanyAddressModel;
    maloId: number;
  };
  vnb: number;
  capacity: {
    unit: DefaultUnitTypeEnum;
    years: any;
  };
  energy: {
    unit: DefaultUnitTypeEnum;
    years: any;
  };
}
