<app-loading-spinner *ngIf="(actionState$ | async)?.isLoading; else data">
</app-loading-spinner>
<ng-template #data>
  <ng-container *ngIf="networkArea$ | async as networkArea; else emptyData">
    <div class="one-line-container">
      <h4>{{ 'dashboard.overview_network.title' | translate }}</h4>
      <button (click)="goToDataTable(dataTableType.facility)" class="button-as-Link default-color body1-gotham">
        {{ 'dashboard.new_data.all' | translate }}
      </button>
    </div>

    <div class="info-item">
      <div class="label">
        <span class="body1-gotham">{{ 'dashboard.overview_network.facilities' | translate }}</span>
        <span class="body1-gotham">{{ networkArea.locations.count }}</span>
      </div>
      <div
              [style.width]="networkArea.locations.percentage + '%'"
              class="progress-bar locations"
      ></div>
    </div>

    <ng-container *ngIf="(isCo2View$ | async); else h2Items">
      <div class="info-item">
        <div class="label">
          <span class="body1-gotham">{{ 'dashboard.overview_network.source' | translate }}</span>
          <span class="body1-gotham">{{ networkArea.production.count }}</span>
        </div>
        <div
                [style.width]="networkArea.production.percentage + '%'"
                class="progress-bar production"
        ></div>
      </div>

      <div class="info-item">
        <div class="label">
          <span class="body1-gotham">{{ 'dashboard.overview_network.sink' | translate }}</span>
          <span class="body1-gotham">{{ networkArea.demand.count }}</span>
        </div>
        <div
                [style.width]="networkArea.demand.percentage + '%'"
                class="progress-bar demand"
        ></div>
      </div>

      <div class="info-item">
        <div class="label">
          <span class="body1-gotham">{{ 'dashboard.overview_network.source_sink' | translate }}</span>
          <span class="body1-gotham">{{ networkArea.both.count }}</span>
        </div>

        <div
                [style.width]="networkArea.both.percentage + '%'"
                class="progress-bar customers"
        ></div>
      </div>
    </ng-container>
    <ng-template #h2Items>
      <div class="info-item">
        <div class="label">
          <span class="body1-gotham">{{ 'dashboard.overview_network.producer' | translate }}</span>
          <span class="body1-gotham">{{ networkArea.production.count }}</span>
        </div>
        <div
                [style.width]="networkArea.production.percentage + '%'"
                class="progress-bar production"
        ></div>
      </div>

      <div class="info-item">
        <div class="label">
          <span class="body1-gotham">{{ 'dashboard.overview_network.consumer' | translate }}</span>
          <span class="body1-gotham">{{ networkArea.demand.count }}</span>
        </div>
        <div
                [style.width]="networkArea.demand.percentage + '%'"
                class="progress-bar demand"
        ></div>
      </div>

      <div class="info-item">
        <div class="label">
          <span class="body1-gotham">{{ 'dashboard.overview_network.customer' | translate }}</span>
          <span class="body1-gotham">{{ networkArea.customers.count }}</span>
        </div>

        <div
                [style.width]="networkArea.customers.percentage + '%'"
                class="progress-bar customers"
        ></div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #emptyData>
    <span>{{'app.no_data' | translate}}</span>
  </ng-template>
</ng-template>
