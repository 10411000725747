import { createSelector } from '@ngxs/store';
import { AppState } from './app.state';
import { Injectable } from '@angular/core';
import { AppStateModel } from './app-state.model';
import { AuthenticationResult } from '@azure/msal-browser';
import {
  TCO2FacilityExtendetDTO,
  TGetCO2Company,
  TGetCO2FacilityDTO,
  TGetH2Company,
  TGetH2FacilityDTO,
  TH2FacilityExtendetDTO,
  TLocationDTO,
  TUserDTO,
  TUserWithStateDTO,
} from '@api';
import { ActionStateModel } from '@interfaces';
import { CompanyTypeEnum } from '@enums';
import { AppViewEnum } from '../shared/enums/app-view-enum';

@Injectable()
export class AppStateSelectors {
  public static isUserAllowed: () => (state: AppStateModel) => boolean = () =>
    createSelector([AppState], (state: AppStateModel) => {
      return (
        !state.userIsLocked &&
        state.userIsApproved &&
        !!state.azureUser?.account.localAccountId
      );
    });

  public static isUserLoggedIn: () => (state: AppStateModel) => boolean = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => !!state.azureUser?.account.localAccountId
    );

  public static isUserApproved: () => (state: AppStateModel) => boolean = () =>
    createSelector([AppState], (state: AppStateModel) => state.userIsApproved);

  public static userMustCompleteRegistration: () => (
    state: AppStateModel
  ) => boolean = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => state.userMustCompleteRegistration
    );

  public static isUserLocked: () => (state: AppStateModel) => boolean = () =>
    createSelector([AppState], (state: AppStateModel) => state.userIsLocked);

  public static getCompanyLogo: () => (state: AppStateModel) => string = () =>
    createSelector([AppState], (state: AppStateModel) => state.companyLogo);

  public static getCookiesAreAccepted: () => (state: AppStateModel) => boolean =
    () =>
      createSelector([AppState], (state: AppStateModel) => {
        const storageAcceptedCookie = localStorage.getItem(
          'hycoconnect-cookies-accepted'
        );
        return Boolean(storageAcceptedCookie)
          ? Boolean(storageAcceptedCookie)
          : state.cookiesAccepted;
      });

  public static getUsername: () => (state: AppStateModel) => string = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => state.azureUser?.account.idTokenClaims['email'] as string
    );

  public static getNameOfUser: () => (state: AppStateModel) => string = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) =>
        `${state.azureUser.account.idTokenClaims['given_name']} ${state.azureUser.account.idTokenClaims['family_name']}`
    );

  public static getUsersCompanyName: () => (state: AppStateModel) => string =
    () =>
      createSelector(
        [AppState],
        (state: AppStateModel) => state.usersCompany.company_name
      );

  public static getAppActionState: () => (
    state: AppStateModel
  ) => ActionStateModel = () =>
    createSelector([AppState], (state: AppStateModel) => state.actionState);

  public static getVNBId: () => (state: AppStateModel) => string = () =>
    createSelector([AppState], (state: AppStateModel) => state.vnbId);

  public static getAzureUser: () => (
    state: AppStateModel
  ) => AuthenticationResult = () =>
    createSelector([AppState], (state: AppStateModel) => state.azureUser);

  public static getUser: () => (
    state: AppStateModel
  ) => TUserDTO | TUserWithStateDTO = () =>
    createSelector([AppState], (state: AppStateModel) => state.user);

  public static getUserToken: () => (state: AppStateModel) => string = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => state.azureUser.idToken
    );

  public static getUsersCompany: () => (
    state: AppStateModel
  ) => TGetH2Company | TGetCO2Company = () =>
    createSelector([AppState], (state: AppStateModel) => state.usersCompany);

  public static getUsersCompanyLocation: () => (
    state: AppStateModel
  ) => TLocationDTO = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => state.usersCompany?.company_location
    );

  public static isUserAdmin: () => (state: AppStateModel) => boolean = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => state.user.user_is_company_admin
    );

  public static isUserOGE: () => (state: AppStateModel) => boolean = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) =>
        state.user.user_company_type_id === CompanyTypeEnum.OGE
    );

  public static isUserVNB: () => (state: AppStateModel) => boolean = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) =>
        state.user.user_company_type_id === CompanyTypeEnum.VNB
    );

  public static isUserH2Customer: () => (state: AppStateModel) => boolean =
    () =>
      createSelector(
        [AppState],
        (state: AppStateModel) =>
          state.user.user_company_type_id === CompanyTypeEnum.H2_CUSTOMER
      );

  public static isUserCo2Customer: () => (state: AppStateModel) => boolean =
    () =>
      createSelector(
        [AppState],
        (state: AppStateModel) =>
          state.user.user_company_type_id === CompanyTypeEnum.CO2_CUSTOMER
      );

  public static getAppView: () => (state: AppStateModel) => AppViewEnum = () =>
    createSelector([AppState], (state: AppStateModel) => state.appView);

  public static getUsersCompanyZipCodes: () => (
    state: AppStateModel
  ) => string[] = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) =>
        (state.usersCompany as TGetH2Company).vnb_zip_codes
    );

  public static getFacilityList: () => (
    state: AppStateModel
  ) => TH2FacilityExtendetDTO[] | TCO2FacilityExtendetDTO[] = () =>
    createSelector([AppState], (state: AppStateModel) => state.facilityList);

  public static getSelectedFacility: () => (
    state: AppStateModel
  ) => TGetH2FacilityDTO | TGetCO2FacilityDTO = () =>
    createSelector(
      [AppState],
      (state: AppStateModel) => state.selectedFacility
    );
}
