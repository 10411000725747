<mat-dialog-content>
    <div class="container">
        <h1>{{ 'cookie_consent.headline' | translate }}</h1>
        <p>{{ 'cookie_consent.text' | translate }} <button class="button-as-Link" (click)="openPolicy()">{{'registration.policy' | translate}}</button>.
        </p>
        <div class="checkbox-container">
            <mat-checkbox color="accent" [formControl]="cookieFunctionalControl">
                <mat-label class="checkbox-headline">
                    {{'cookie_consent.checkbox.functional' | translate}}
                </mat-label>
            </mat-checkbox>
        </div>
        <p class="subtitle"> {{'cookie_consent.checkbox.functional_subtitle' | translate}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <!--<button color="accent"
            mat-flat-button
            type="submit"
            (click)="onAccept()">
        {{'cookie_consent.button.accept_all' | translate}}
    </button>-->
    <button color="accent"
            mat-flat-button
            type="submit"
            class="button-gotham"
            (click)="onAccept()">
        {{'cookie_consent.button.accept' | translate}}
    </button>
</mat-dialog-actions>