import { Injectable } from '@angular/core';
import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { SetActiveDataTypes, SetMapIsLoading } from './map.actions';
import type { MapStateModel } from './map-state.model';
import { ActionState } from '@interfaces';

const MAP_STATE_TOKEN: StateToken<MapStateModel> = new StateToken('mapState');

@Injectable()
@State<MapStateModel>({
  name: MAP_STATE_TOKEN,
  defaults: {
    actionState: ActionState.create(),
    activeDataTypes: [],
  },
})
export class MapState {
  @Action(SetMapIsLoading)
  public setMapIsLoaded(
    context: StateContext<MapStateModel>,
    action: SetMapIsLoading
  ): void {
    context.patchState({
      ...context,
      actionState: action.isLoading
        ? ActionState.onStart()
        : ActionState.onDone(),
    });
  }

  @Action(SetActiveDataTypes)
  public setActiveDataTypes(
    context: StateContext<MapStateModel>,
    action: SetActiveDataTypes
  ): void {
    context.patchState({
      ...context,
      activeDataTypes: action.dataTypes,
    });
  }
}
