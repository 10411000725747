import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Select } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable, take } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { AppViewEnum } from '../../../shared/enums/app-view-enum';
import { AuthenticationService } from '@authentication';
import { BaseFacilityFacade } from '../facade/base-facility.facade';
import { TCO2FacilityExtendetDTO } from '@api';

@UntilDestroy()
@Component({
  selector: 'app-co2-facility-tile',
  templateUrl: './co2-facility-tile.component.html',
  styleUrls: ['./co2-facility-tile.component.scss'],
  providers: [BaseFacilityFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Co2FacilityTileComponent {
  @Select(AppStateSelectors.isUserCo2Customer())
  public isUserCo2Customer$: Observable<boolean>;
  @Input()
  public facility: TCO2FacilityExtendetDTO;

  public constructor(
    private readonly baseFacilityFacade: BaseFacilityFacade,
    private readonly authService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public get isAppViewCo2$(): Observable<boolean> {
    return this.authService.appView$.pipe(
      take(1),
      map(view => view === AppViewEnum.CARBON_DIOXIDE)
    );
  }

  public onShowInformation(facility: TCO2FacilityExtendetDTO): void {
    this.baseFacilityFacade.onShowInformation(facility);
  }

  public onDeleteFacility(facility: TCO2FacilityExtendetDTO): void {
    this.baseFacilityFacade.onDeleteFacility(facility);
    this.changeDetectorRef.detectChanges();
  }
}
