import { AppStateSelectors } from '@appState/app-state.selector';
import {
  DeleteFacilityById,
  SetSelectedFacilityId,
} from '@appState/app.actions';
import {
  RegisterCo2LocationComponent,
  RegisterH2LocationComponent,
} from '@facility';
import { AppViewEnum } from '../../../shared/enums/app-view-enum';
import { Store } from '@ngxs/store';
import { DialogService } from '@services';
import { Injectable } from '@angular/core';
import { TCO2FacilityExtendetDTO, TH2FacilityExtendetDTO } from '@api';
import { DialogVnbPlzComponent } from '../../facility/components/dialog-vnb-plz/dialog-vnb-plz.component';

@Injectable()
export class BaseFacilityFacade {
  public constructor(
    public readonly store: Store,
    public readonly dialogService: DialogService
  ) {}

  public onShowInformation(facility): void {
    const appView = this.store.selectSnapshot(AppStateSelectors.getAppView());
    this.store.dispatch(new SetSelectedFacilityId(facility.facility_id));
    this.dialogService.openRegisterLocation<
      RegisterH2LocationComponent | RegisterCo2LocationComponent
    >(
      appView == AppViewEnum.HYDROGEN
        ? RegisterH2LocationComponent
        : RegisterCo2LocationComponent
    );
  }

  public onDeleteFacility(
    facility: TCO2FacilityExtendetDTO | TH2FacilityExtendetDTO
  ): void {
    this.dialogService.showCustomDialog({
      title: 'data_table.dialog.delete_facility_title',
      content: 'data_table.dialog.delete_facility_content',
      contentParams: { facility: facility.facility_name },
      color: 'INFO',
      actions: {
        acceptLabel: 'data_table.dialog.actions.accept',
        abortLabel: 'data_table.dialog.actions.abort',
        acceptCallback: () =>
          this.store.dispatch(new DeleteFacilityById(facility.facility_id)),
      },
    });
  }

  public openNewPlzDialog(): void {
    this.dialogService.openDialog(DialogVnbPlzComponent, {
      backdropClass: 'blurred',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'form-dialog',
    });
  }
}
