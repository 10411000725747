import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-oss-attributions',
  templateUrl: './oss-licenses.component.html',
  styleUrls: ['./oss-licenses.component.scss'],
})
export class OssLicensesComponent implements OnInit {
  public licenses: any;

  constructor(private readonly httpClient: HttpClient) {}

  public ngOnInit(): void {
    this.httpClient.get('3rdpartylicenses.txt', { responseType: 'text' })
    .pipe(untilDestroyed(this))
    .subscribe((result) => this.licenses = result);
  }
}
