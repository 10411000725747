import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private readonly translateService: TranslateService) {}

  downloadCsvFromBase64(base64String: string, fileName: string): void {
    // Base64 zu UTF-8 dekodieren
    const decodedString = atob(base64String);
    const separator = ';';
    let splittedDecodedString = decodedString.split(separator);
    splittedDecodedString = splittedDecodedString.map(value => {
      if (value.includes('export.')) {
        return this.translateService.instant(value);
      }
      return value;
    });

    const data = splittedDecodedString.join(separator);
    // for utf-8 -> \ufeff
    const blob = new Blob(['\ufeff', data], {
      type: 'text/csv;charset=utf-8',
    });
    saveAs(blob, fileName);
  }
}
