<app-loading-spinner *ngIf="(actionState$ | async)?.isLoading; else data">
</app-loading-spinner>
<ng-template #data>
  <ng-container *ngIf="facilitiesAndCompanies$ | async as facilitiesAndCompanies; else noData">
    <div class="one-line-container">
      <h4>
        {{ 'dashboard.new_data.facilities' | translate }}
      </h4>
      <button (click)="goToDataTable(dataTableType.facility)" class="button-as-Link default-color body1-gotham">
        {{ 'dashboard.new_data.all' | translate }}
      </button>
    </div>
    <ng-container *ngIf="facilitiesAndCompanies.new_facility_list.length > 0; else noFacilities">
      <ul class="default-list">
        <li *ngFor="let facility of facilitiesAndCompanies.new_facility_list" class="body1-gotham">
        <span *ngIf="(isH2Customer$ | async) || (isCo2Customer$ | async)"
              class="customer-facilities">
            {{ facility.facility_name }}
          <span class="facility-address">
                {{ facility.facility_zip_code }}
            {{ facility.facility_city }}
            </span>
        </span>
          <span *ngIf="(isVNB$ | async) || (isUserOge$ | async)"> {{ facility.facility_zip_code }} {{ facility.facility_city }}</span>
        </li>
      </ul>
    </ng-container>
    <ng-template #noFacilities>
      <p>{{ 'dashboard.new_data.facilities.no_data_found' | translate }}</p>
    </ng-template>

    <ng-container *ngIf="(isVNB$ | async) || (isUserOge$ | async)">
      <div class="one-line-container">
        <h4>
          {{ 'dashboard.new_data.companies' | translate }}
        </h4>
        <button (click)="goToDataTable(dataTableType.company)" class="button-as-Link default-color">
          {{ 'dashboard.new_data.all' | translate }}
        </button>
      </div>
      <ng-container *ngIf="facilitiesAndCompanies.new_company_list.length > 0; else noCompanies">
        <ul class="default-list">
          <li *ngFor="let company of facilitiesAndCompanies.new_company_list" class="body1-gotham">
            {{ company.company_name }}
          </li>
        </ul>
      </ng-container>
      <ng-template #noCompanies>
        <p>{{ 'dashboard.new_data.companies.no_data_found' | translate }}</p>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #noData>{{ 'app.no_data' | translate }}</ng-template>
</ng-template>
