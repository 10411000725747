import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable } from 'rxjs';
import { ActionStateModel } from '@interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserRegistrationFormModel } from '@registration/interfaces';
import { Router } from '@angular/router';
import { AuthenticationResult } from '@azure/msal-browser';
import { CompanyTypeEnum } from '@enums';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TGetCO2Company, TGetH2Company } from '@api';

@UntilDestroy()
@Component({
  selector: 'app-registration-entry',
  templateUrl: './registration-entry.component.html',
  styleUrls: ['./registration-entry.component.scss'],
})
export class RegistrationEntryComponent implements OnInit {
  @Select(AppStateSelectors.getAppActionState())
  public readonly actionState$: Observable<ActionStateModel>;

  @Select(AppStateSelectors.getUsersCompany())
  public readonly usersCompany$: Observable<TGetH2Company | TGetCO2Company>;

  public form: FormGroup<UserRegistrationFormModel>;

  public isHydrogenCustomer: boolean;
  public companyName: string;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.buildForm();
    this.fillPersonalDataFromB2ctoForm();

    // check is user co2 via url or is the company already exist as co2 else its hydrogen user/company
    this.usersCompany$.pipe(untilDestroyed(this)).subscribe(company => {
      if (
        company &&
        company.company_type.company_type_id === CompanyTypeEnum.CO2_CUSTOMER
      ) {
        this.isHydrogenCustomer = false;
        this.companyName = company.company_name;
      } else {
        this.isHydrogenCustomer = !this.router.url.includes('co2');
      }
    });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      // personal data
      email: this.formBuilder.nonNullable.control({
        value: '',
        disabled: true,
      }),
      firstname: this.formBuilder.nonNullable.control({
        value: '',
        disabled: true,
      }),
      lastname: this.formBuilder.nonNullable.control({
        value: '',
        disabled: true,
      }),
      userB2cObjectId: this.formBuilder.nonNullable.control(''),
      registrationType: this.formBuilder.nonNullable.control('0'),
    });
  }

  private fillPersonalDataFromB2ctoForm(): void {
    const result: AuthenticationResult = this.store.selectSnapshot(
      AppStateSelectors.getAzureUser()
    );
    if (result?.account) {
      this.form.controls.firstname.setValue(
        (result.account.idTokenClaims as any)['given_name']
      );
      this.form.controls.lastname.setValue(
        (result.account.idTokenClaims as any)['family_name']
      );
      this.form.controls.email.setValue((result.account.idTokenClaims as any)['email'])
      ;
      this.form.controls.userB2cObjectId.setValue(
        result.account.localAccountId
      );
    }
  }
}
