import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardSelectors } from '@pages/dashboard/state/dashboard.selectors';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { TNewRegistrationDTO } from '@api';
import { AppStateSelectors } from '@appState/app-state.selector';
import { SetActiveTable } from '@pages/data-table/state/data-table.actions';
import { Router } from '@angular/router';
import { DataTableType } from '../../../../shared/enums/data-table-type-enum';
import { ActionStateModel } from '@interfaces';

@UntilDestroy()
@Component({
  selector: 'app-dashboard-facilities-companies',
  templateUrl: './dashboard-facilities-companies.component.html',
  styleUrls: ['./dashboard-facilities-companies.component.scss'],
})
export class DashboardFacilitiesCompaniesComponent {
  @Select(DashboardSelectors.getFacilitiesAndCompanies)
  public facilitiesAndCompanies$: Observable<TNewRegistrationDTO>;

  @Select(AppStateSelectors.isUserVNB())
  public isVNB$: Observable<boolean>;

  @Select(AppStateSelectors.isUserOGE())
  public isUserOge$: Observable<boolean>;

  @Select(AppStateSelectors.isUserH2Customer())
  public isH2Customer$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isCo2Customer$: Observable<boolean>;

  @Select(DashboardSelectors.getActionState)
  public readonly actionState$: Observable<ActionStateModel>;

  public dataTableType = DataTableType;

  constructor(
    private readonly store: Store,
    private readonly router: Router
  ) {}

  public goToDataTable(type: DataTableType) {
    this.store.dispatch(new SetActiveTable(type));
    this.router.navigate(['/daten']);
  }
}
