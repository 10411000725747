import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@authentication';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RedirectRequest } from '@azure/msal-browser';
import { environment } from '@env';
import { Router } from '@angular/router';
import { AppViewEnum } from '../../enums/app-view-enum';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetViewApp } from '@appState/app.actions';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  public companyLogoPath: SafeResourceUrl;
  public appViewEnum = AppViewEnum;

  public constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly _sanitizer: DomSanitizer
  ) {}

  public get userCompanyName$(): Observable<string> {
    return this.authService.userCompanyName$;
  }

  public get isUserAllowed$(): Observable<boolean> {
    return this.authService.isUserAllowed$;
  }
  public get isUserAdmin$(): Observable<boolean> {
    return this.authService.isUserAdmin$;
  }

  public get isUserOGE$(): Observable<boolean> {
    return this.authService.isUserOGE$;
  }

  public get isAppViewCo2$(): Observable<boolean> {
    return this.authService.appView$.pipe(
      untilDestroyed(this),
      map(view => view === AppViewEnum.CARBON_DIOXIDE)
    );
  }

  public get isUserVNB$(): Observable<boolean> {
    return this.authService.isUserVNB$;
  }

  public get isUserLoggedIn$(): Observable<boolean> {
    return this.authService.isUserLoggedIn$;
  }

  public get username$(): Observable<string> {
    return this.authService.username$;
  }

  public ngOnInit(): void {
    this.authService.companyLogo$
      .pipe(untilDestroyed(this))
      .subscribe(base64StringLogo => {
        if (base64StringLogo) {
          this.companyLogoPath = this._sanitizer.bypassSecurityTrustResourceUrl(
            `data:image/jpg;base64,${base64StringLogo}`
          );
        }
      });
  }

  public register(): void {
    this.authService.register();
  }

  public login(): void {
    const isCo2 = this.router.url.includes('co2');
    // after login redirect to dashboard
    const request: RedirectRequest = {
      scopes: [],
      authority:
        environment.oauth.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: isCo2
        ? environment.oauth.redirectUriCo2
        : environment.oauth.redirectUri,
    };
    this.authService.login(request);
  }

  public logout(): void {
    this.authService.logout();
  }

  public toggleAppViewForOGE(value: number): void {
    this.reloadComponent(true);
    this.store.dispatch(new SetViewApp(value));
  }

  private reloadComponent(self: boolean, urlToNavigateTo?: string) {
    //skipLocationChange:true means dont update the url to / when navigating
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {});
    });
  }
}
