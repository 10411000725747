import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LegalService } from '@services';
import { TDokumentDTO } from '@api';
import { checkboxMinOneRequired } from '../../../shared/validators/checkbox-min-one-required';

@UntilDestroy()
@Injectable()
export class RegistrationService {
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly legalService: LegalService
  ) {}

  public buildLegalDataForm() {
    return this.formBuilder.group({
      legal: [false, [Validators.requiredTrue]],
      policy: [false, [Validators.requiredTrue]],
    });
  }

  public buildAddressForm(isCo2?: boolean): FormGroup {
    return this.formBuilder.group({
      address: [''],
      street: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ],
      ],
      streetNumber: ['', [Validators.required, Validators.maxLength(16)]],
      zipCode: [
        '',
        [Validators.required, Validators.maxLength(isCo2 ? 10 : 5)],
      ],
      city: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(255),
        ],
      ],
      lat: [0],
      lng: [0],
    });
  }

  public buildCo2RoleGroup(): FormGroup {
    return this.formBuilder.group(
      {
        io: [false],
        consume: [false],
        vchain: [false],
        store: [false],
        hub: [false],
        cluster: [false],
      },
      {
        validators: [Validators.required, checkboxMinOneRequired(1)],
      }
    );
  }

  public getPolicy(vnbId: number): Observable<TDokumentDTO> {
    // VNB ID 2 ist DUMMY VNB!!! 0 is no and 1 is OGE
    if (vnbId > 2) {
      return this.legalService.getPolicy(vnbId).pipe(untilDestroyed(this));
    }
    return of(null);
  }

  public openTerms(): void {
    this.legalService.getTermsAndConditions().subscribe(data => {
      this.legalService.openPDF(data.document_string, 'AGB');
    });
  }

  public openPolicy(): void {
    this.legalService.getPolicy().subscribe(data => {
      this.legalService.openPDF(data.document_string, 'Datenschutzerklärung');
    });
  }

  public openProceduralRules(): void {
    this.legalService.getProceduralRules().subscribe(data => {
      this.legalService.openPDF(data.document_string, 'Verfahrensregeln');
    });
  }
}
