<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>{{ title }}</mat-panel-title>
  </mat-expansion-panel-header>
  <section #sortSection="matSort"
           [class.isVNB]="isVNB"
           [matSortActive]="tableHeaders[0].sortColumn"
           [ngClass]="headerClass"
           class="sort-section body1-gotham mat-header-row"
           matSort
           matSortDirection="desc">
    <span *ngFor="let header of tableHeaders; index as i" [class.actions]="hasActions && i == tableHeaders.length" [mat-sort-header]="header.sortColumn"
          [ngClass]="header.sortColumn"
          class="sort-section-cell">{{ header.labelColumn | translate }}</span>
  </section>
  <ng-content></ng-content>
</mat-expansion-panel>
