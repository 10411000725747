import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionStateModel } from '@interfaces';
import { DashboardSelectors } from '@pages/dashboard/state/dashboard.selectors';
import { Select, Store } from '@ngxs/store';
import { Plugin, TooltipItem } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-annotation';
import { dashboardGraphOptions } from '@pages/dashboard/components/dashboard-graph/dashboard-graph.options';
import { Co2DatasetType } from '@pages/dashboard/models/co2-dataset-type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Co2YearlySummaryGraphDataModel } from '@pages/dashboard/models/Co2YearlySummaryGraphDataModel';
import { formatNumber } from '@angular/common';
import { environment } from '@env';

@UntilDestroy()
@Component({
  selector: 'app-co2-graph',
  templateUrl: './co2-graph.component.html',
  styleUrls: ['./co2-graph.component.scss'],
})
export class Co2GraphComponent {
  @Select(DashboardSelectors.getActionState)
  public readonly actionState$: Observable<ActionStateModel>;

  private readonly yearlySummaryData$: Observable<Co2YearlySummaryGraphDataModel> =
    this.store.select(DashboardSelectors.getGraphData).pipe(
      untilDestroyed(this),
      filter(
        (
          value: Co2YearlySummaryGraphDataModel | undefined
        ): value is Co2YearlySummaryGraphDataModel => !!value
      ),
      shareReplay(1)
    );

  public readonly co2DatasetType = Co2DatasetType;
  public datasets$: Observable<{
    source: number[];
    sink: number[];
    both?: number[];
  }>;

  public labels$: Observable<string[]>;

  public barChartOptions: typeof dashboardGraphOptions = dashboardGraphOptions;

  public readonly barChartPlugins: Plugin[] = [DataLabelsPlugin];

  public activeDatasetType: number[] = [
    this.co2DatasetType.SOURCE,
    this.co2DatasetType.SINK,
    this.co2DatasetType.BOTH,
  ];

  constructor(private readonly store: Store) {
    this.datasets$ = this.yearlySummaryData$.pipe(
      untilDestroyed(this),
      map((yearlySummary: Co2YearlySummaryGraphDataModel) => ({
        source: yearlySummary.source,
        sink: yearlySummary.sink,
        both: yearlySummary.both,
      }))
    );

    this.labels$ = this.yearlySummaryData$.pipe(
      untilDestroyed(this),
      map(
        (yearlySummaryData: Co2YearlySummaryGraphDataModel): string[] =>
          yearlySummaryData.labels
      )
    );

    this.barChartOptions = {
      ...dashboardGraphOptions,
      scales: {
        ...dashboardGraphOptions.scales,
        y: {
          ...dashboardGraphOptions.scales['y'],
          ticks: {
            ...dashboardGraphOptions.scales['y'].ticks,
            callback: (
              tickValue: number | string
            ): string | string[] | number | number[] | null | undefined => {
              if (tickValue === 0) {
                return '';
              }
              tickValue = formatNumber(
                tickValue as number,
                `${
                  environment.defaultLang
                }-${environment.defaultLang.toUpperCase()}`,
                '1.0-0'
              );

              return `${tickValue} t`;
            },
          },
        },
      },
      plugins: {
        ...dashboardGraphOptions.plugins,
        tooltip: {
          ...dashboardGraphOptions.plugins.tooltip,
          callbacks: {
            title: (): string | string[] | void => '',
            label: (item: TooltipItem<'bar'>): string | string[] | void => {
              return `${item.formattedValue} t`;
            },
          },
        },
      },
    };
  }

  public toggleDataset(activeDatasets: readonly number[]): void {
    this.activeDatasetType = [...activeDatasets];
  }
}
