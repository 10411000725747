import { Environment } from './environment.type';

export const baseEnvironment: Environment = {
  webBasePath: 'http://localhost:4200',
  apiBasePath: '',
  productionVersion:'3.4-14.10.24-14:23',
  versionNumber:
    '20240318_Vertrauen_Ist_Gut_Punkt_Kontrolle_Ist_Besser_Sagt_Die_Helikopter_Mami',
  production: false,
  mapCenter: { lat: 51.763361, lng: 10.447683 },
  mapId: '34b13fda9d12cbc7',
  debug: true,
  co2CountryCodes: ['de', 'at', 'ch', 'nl', 'fr'],
  defaultLang: 'de',
  oauth: {
    clientId: '5eda14a6-fc2c-4f64-80f8-722dcc22567b',
    authority:
      //'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1_registration_login',
      'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_DOMAINCHECK_SSPR',
    postLogoutRedirectUri: 'http://localhost:4200',
    redirectUri: 'http://localhost:4200/uebersicht',
    redirectUriCo2: 'http://localhost:4200/co2/uebersicht',
    b2cPolicies: {
      names: {
        /*signUpSignIn: 'B2C_1_registration_login',
        signUp: 'B2C_1_sign_up',
        forgotPassword: 'B2C_1_reset',
        editProfile: 'B2C_1_edit_profile',
        */
        signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN_DOMAINCHECK_SSPR',
        signUp: 'B2C_1A_SIGNUP_DOMAINCHECK',
        forgotPassword: 'B2C_1A_PASSWORDRESET',
        editProfile: 'B2C_1A_PROFILEEDIT', 
      },
      authorities: {
        signUpSignIn: {
          authority:
          'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_DOMAINCHECK_SSPR',
          //'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1_registration_login',
        },
        signUp: {
          authority:
          'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1A_SIGNUP_DOMAINCHECK/',
          //'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1_sign_up/',
        },
        forgotPassword: {
          authority:
          'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1A_PASSWORDRESET',
          //'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1_reset',
        },
        editProfile: {
          authority:
            'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1A_PROFILEEDIT',
            //'https://H2connectDevelopment.b2clogin.com/H2connectDevelopment.onmicrosoft.com/B2C_1_edit_profile',
        },
      },
      authorityDomain: 'H2connectDevelopment.b2clogin.com',
    },
  },
};
