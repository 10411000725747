<mat-dialog-content>
    <div class="custom-container" [ngClass]="customData.color" [class.bottom-padding]="!customData.actions">
        <h3>{{customData.title | translate}}</h3>
        <p>{{customData.content | translate: customData.contentParams}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions *ngIf="customData.actions">
    <button *ngIf="customData.actions.abortLabel"
            mat-button
            (click)="onAbort()">
        {{customData.actions.abortLabel | translate}}
    </button>
    <button *ngIf="customData.actions.acceptLabel"
            color="accent"
            [class.error]="customData.color === 'ERROR'"
            mat-button
            type="submit"
            (click)="onAccept()">
        {{customData.actions.acceptLabel | translate}}
    </button>
</mat-dialog-actions>