import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import type { Plugin } from 'chart.js';
import { TooltipItem } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-annotation';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { dashboardGraphOptions } from './dashboard-graph.options';
import { DashboardSelectors } from '../../state/dashboard.selectors';
import type { H2YearlySummaryGraphDataModel } from '../../models/h2-yearly-summary-graph-data.model';
import { UnitConvertorService } from '@services';
import { DataType } from '../../models/data-type';
import {
  DisplayUnitTypeEnergy,
  DisplayUnitTypePower,
  UnitType,
} from '../../models/unit-type';
import { formatNumber } from '@angular/common';
import { SetActiveUnit, SetDataType } from '../../state/dashboard.actions';
import { DatasetType } from '../../models/dataset-type';
import { ActionStateModel } from '@interfaces';
import { environment } from '@env';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard-graph',
  templateUrl: './dashboard-graph.component.html',
  styleUrls: ['./dashboard-graph.component.scss'],
})
export class DashboardGraphComponent {
  @Select(DashboardSelectors.getDisplayUnit)
  public readonly displayUnit$: Observable<string>;

  @Select(DashboardSelectors.getActiveUnit)
  public readonly activeUnit$: Observable<UnitType>;

  @Select(DashboardSelectors.getActiveDataType)
  public readonly activeDataType$: Observable<DataType>;

  public readonly dataType = DataType;
  public readonly unitType = UnitType;
  public readonly datasetType = DatasetType;
  public activeDatasetType: string[] = [
    this.datasetType.ERZEUGUNG,
    this.datasetType.BEDARF,
  ];

  public readonly actionState$: Observable<ActionStateModel> =
    this.store.select<ActionStateModel>(DashboardSelectors.getActionState);

  public readonly datasets$: Observable<{
    readonly production: readonly number[];
    readonly demand: readonly number[];
  }>;

  public barChartOptions: typeof dashboardGraphOptions = dashboardGraphOptions;

  public readonly barChartPlugins: Plugin[] = [DataLabelsPlugin];

  public readonly labels$: Observable<string[]>;

  private readonly yearlySummaryData$: Observable<H2YearlySummaryGraphDataModel> =
    this.store.select(DashboardSelectors.getGraphData).pipe(
      untilDestroyed(this),
      filter(
        (
          value: H2YearlySummaryGraphDataModel | undefined
        ): value is H2YearlySummaryGraphDataModel => !!value
      ),
      shareReplay(1)
    );

  public constructor(
    private readonly store: Store,
    private readonly unitConvertor: UnitConvertorService
  ) {
    this.datasets$ = combineLatest([
      this.activeUnit$,
      this.activeDataType$,
      this.yearlySummaryData$,
    ]).pipe(
      untilDestroyed(this),
      map(
        ([unitType, dataType, yearlySummary]: readonly [
          UnitType,
          DataType,
          H2YearlySummaryGraphDataModel,
        ]): {
          readonly production: readonly number[];
          readonly demand: readonly number[];
        } => {
          const avgLength = this.unitConvertor.averageUnit(
            yearlySummary.production[`${dataType}${unitType}`],
            yearlySummary.demand[`${dataType}${unitType}`]
          );
          return {
            production: [
              ...this.unitConvertor.convertYearlySummaryToUnit(
                avgLength,
                yearlySummary.production[`${dataType}${unitType}`],
                dataType,
                unitType
              ),
            ],
            demand: [
              ...this.unitConvertor.convertYearlySummaryToUnit(
                avgLength,
                yearlySummary.demand[`${dataType}${unitType}`],
                dataType,
                unitType
              ),
            ],
          };
        }
      )
    );

    this.labels$ = this.yearlySummaryData$.pipe(
      untilDestroyed(this),
      map(
        (yearlySummaryData: H2YearlySummaryGraphDataModel): string[] =>
          yearlySummaryData.labels
      )
    );

    this.displayUnit$
      .pipe(untilDestroyed(this))
      .subscribe((unitType: string) => this.refreshBarOptions(unitType));
  }

  public setDataTypeAndUnitType(dataType: DataType, unit: UnitType): void {
    this.store.dispatch(new SetDataType(dataType));
    this.store.dispatch(new SetActiveUnit(unit));
  }

  public toggleDataset(activeDatasets: readonly string[]): void {
    this.activeDatasetType = [...activeDatasets];
  }

  public getDisplayUnitText(
    textKey: string,
    defaultUnit: string,
    isPower: boolean
  ): string {
    const displayUnit = this.store.selectSnapshot(
      DashboardSelectors.getDisplayUnit
    );
    const activeUnit = this.store.selectSnapshot(
      DashboardSelectors.getActiveUnit
    );
    const activeDataType = this.store.selectSnapshot(
      DashboardSelectors.getActiveDataType
    );
    let unit = '';
    if (isPower) {
      unit = `${defaultUnit}_h`;
      if (
        activeUnit.toLowerCase() === defaultUnit.toLowerCase() &&
        DataType.POWER === activeDataType
      ) {
        unit = displayUnit;
      }
    } else {
      if (
        activeUnit.toLowerCase() === defaultUnit.toLowerCase() &&
        DataType.ENERGY === activeDataType
      ) {
        unit = displayUnit;
      } else {
        unit = defaultUnit;
      }
    }
    return `${textKey}.${unit}`;
  }

  private refreshBarOptions(unit: string): void {
    if (unit) {
      if (unit.includes('_')) {
        unit = DisplayUnitTypePower[unit];
      } else {
        unit = DisplayUnitTypeEnergy[unit];
      }
      this.barChartOptions = {
        ...dashboardGraphOptions,
        scales: {
          ...dashboardGraphOptions.scales,
          y: {
            ...dashboardGraphOptions.scales['y'],
            ticks: {
              ...dashboardGraphOptions.scales['y'].ticks,
              callback: (
                tickValue: number | string
              ): string | string[] | number | number[] | null | undefined => {
                if (tickValue === 0) {
                  return '';
                }
                tickValue = formatNumber(
                  tickValue as number,
                  `${
                    environment.defaultLang
                  }-${environment.defaultLang.toUpperCase()}`,
                  '1.0-0'
                );

                return `${tickValue} ${unit}`;
              },
            },
          },
        },
        plugins: {
          ...dashboardGraphOptions.plugins,
          tooltip: {
            ...dashboardGraphOptions.plugins.tooltip,
            callbacks: {
              title: (): string | string[] | void => '',
              label: (item: TooltipItem<'bar'>): string | string[] | void => {
                return `${item.formattedValue} ${unit}`;
              },
            },
          },
        },
      };
    }
  }
}
