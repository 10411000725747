<div class="company-card-container">
  <div class="company-card">
    <div class="company_name">
      <span class="name"> {{ company.company_name }}</span>
      <span class="color-copy">{{ company.company_zip_code }} {{ company.company_city }}</span>
    </div>
    <div class="company_facilty_count center">
      {{ company.company_facility_count }}
    </div>
    <div class="company_consumer_facilty_count center">
      {{ company.company_consumer_facility_count }}
    </div>
    <div class="company_producer_facilty_count center">
      <span>{{ company.company_producer_facility_count }}</span>
    </div>
    <div class="company_insert_time actions">
      <button [matMenuTriggerFor]="menu" class="more-icon" disabled mat-icon-button>
        <mat-icon fontIcon="more_horiz"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>Aktion 1</button>
        <button mat-menu-item>Aktion 2</button>
        <button mat-menu-item>Aktion 3</button>
      </mat-menu>
    </div>
  </div>
</div>
