import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataTablePageComponent } from '@pages/data-table/data-table.page.component';
import { urlGuard } from '../../shared/guard/url.guard';

const routes: Routes = [
  {
    path: '',
    component: DataTablePageComponent,
    canActivate: [MsalGuard, urlGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DataTablePageRoutingModule {}
