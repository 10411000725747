import { Injectable } from '@angular/core';
import { Selector, createSelector } from '@ngxs/store';
import { DataTableState } from '@pages/data-table/state/data-table.state';
import { DataTableStateModel } from '@pages/data-table/state/date-table-state.model';
import { DataTableType } from '../../../shared/enums/data-table-type-enum';
import { TCO2CompanyDTO, TH2CompanyDTO } from '@api';

@Injectable()
export class DataTableStateSelectors {
  @Selector([DataTableState])
  public static getCompanyTable(
    state: DataTableStateModel
  ): TH2CompanyDTO[] | TCO2CompanyDTO[] {
    return state.companyTable;
  }

  @Selector([DataTableState])
  public static isLoading(state: DataTableStateModel): boolean {
    return state.actionState.isLoading;
  }

  @Selector([DataTableState])
  public static isLoadingFacilityCSV(state: DataTableStateModel): boolean {
    return state.actionStateFacilityCSV.isLoading;
  }

  @Selector([DataTableState])
  public static isLoadingCompanyCSV(state: DataTableStateModel): boolean {
    return state.actionStateCompanyCSV.isLoading;
  }

  @Selector([DataTableState])
  public static getExportFacilityCSVString(state: DataTableStateModel): string {
    return state.exportFacilityCSVString;
  }

  @Selector([DataTableState])
  public static getExportCompanyCSVString(state: DataTableStateModel): string {
    return state.exportCompanyCSVString;
  }

  @Selector([DataTableState])
  public static getTableType(state: DataTableStateModel): DataTableType {
    return state.selectedActiveType;
  }

  @Selector([DataTableState])
  public static actionStateDone(state: DataTableStateModel): boolean {
    return state.actionState.done;
  }

  public static isLoadingOGEVNBData() {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel) => state.actionStateOGEVNBData.isLoading
    );
  }

  public static vnbData() {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel) => state.vnbData
    );
  }

  public static isLoadingOGEVNBCSV() {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel) => state.actionStateOGEVNBCSV.isLoading
    );
  }

  public static exportVNBCSVString() {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel) => state.exportVNBCSVString
    );
  }

  public static isLoadingOGEVNBSummaryCSV() {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel) =>
        state.actionStateOGEVNBSummaryCSV.isLoading
    );
  }

  public static exportVNBSummaryCSVString() {
    return createSelector(
      [DataTableState],
      (state: DataTableStateModel) => state.exportVNBSummaryCSVString
    );
  }
}
