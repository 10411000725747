<form [formGroup]="form">
    <ng-container *ngIf="!isCompanyAlreadyExists()">
        <app-zip-code-area></app-zip-code-area>
                <div class="split-pane">
                    <div class="company-logo">
                        <h4 [class.error]="imagesHasError">
                            {{'registration.company_logo_upload' | translate}}
                            <mat-icon fontIcon="info_outline"
                                      class="mat-icon-headline"
                                      matTooltip="{{'registration.company_logo_upload_tooltip' | translate}}"
                                      matTooltipPosition="right"
                            ></mat-icon>
                        </h4>
                        <div class="error-hint-container">
                            <span class="hint-text">{{'registration.company_logo_upload_image_hint'| translate}}</span>
                            <span *ngIf="imagesHasError" class="error error-text">{{'registration.company_logo_upload_image_size' | translate}}</span>
                        </div>
                        <app-file-upload [acceptedFileType]="['png', 'jpeg']"
                                         [maxFileSizeInMb]="2"
                                         (selectedFile)="onSelectedCompanyLogo($event)"></app-file-upload>

                    </div>
                    <div class="company-policy">
                        <app-upload-company-policy [isRequired]="true"></app-upload-company-policy>
                    </div>
                </div>
    </ng-container>
</form>
