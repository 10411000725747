<app-loading-spinner *ngIf="(actionState$ | async)?.isLoading; else tplGraph">
</app-loading-spinner>
<ng-template #tplGraph>
    <div *ngIf="datasets$ | async as datasets" class="graph">
        <div class="graph-actions">
            <div class="controls">
                <mat-button-toggle-group
                        (change)="toggleDataset($event.value)"
                        [multiple]="true"
                        [value]="activeDatasetType"
                        class="dataset-toggles"
                >
                    <mat-button-toggle class="caption-gotham" [value]="0">
                        <mat-icon class="dataset-icon">arrow_upward</mat-icon>
                        {{'dashboard.graph.source' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle class="caption-gotham" [value]="1">
                        <mat-icon class="dataset-icon">arrow_downward</mat-icon>
                        {{'dashboard.graph.sink' | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <div class="graph-container">
            <canvas [datasets]="[
          {
            label: 'dashboard.graph.source' | translate,
            backgroundColor: '#52a436',
            data: datasets.source,
            hidden: !activeDatasetType.includes(co2DatasetType.SOURCE)
          },
          {
            label: 'dashboard.graph.sink' | translate,
            backgroundColor: '#007CBE',
            data: datasets.sink,
            hidden: !activeDatasetType.includes(co2DatasetType.SINK)
          }
        ]"
              [labels]="labels$ | async"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [type]="'bar'"
              baseChart
              class="chart"
              height="1"
              width="3"
            >
            </canvas>
        </div>
    </div>
    <div class="no-data-container" *ngIf="(labels$ | async) === null">
        {{ 'dashboard.graph.no_data' | translate }}
    </div>
</ng-template>
