<form [formGroup]="form">
  <mat-accordion multi>
    <mat-expansion-panel [expanded]="true"
                         class="oge-expansion-panel register-expansion-panel location-data-container">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'registration.register_location_data' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="facilityForm">
        <mat-radio-group attr.aria-label="{{'registration.register_location.select_unit' | translate}}"
                         class="radio-container requirement-production-container"
                         formControlName="requirementProduction">
          <mat-radio-button [value]="facilityTypeEnum.Producer">{{ 'registration.register_location_data_production' | translate }}</mat-radio-button>
          <mat-radio-button [value]="facilityTypeEnum.Consumer">{{ 'registration.register_location_data_requirement' | translate }}</mat-radio-button>
        </mat-radio-group>
        <div class="location-data">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.register_location_data_name' | translate }}</mat-label>
            <input formControlName="facilityName"
                   matInput
                   type="text">
          </mat-form-field>
          <app-address-search-autocomplete
            title="{{'registration.register_location_data_address' | translate}}">
          </app-address-search-autocomplete>
          <mat-form-field [class.error-input]="maloIdControl.invalid"
                          appearance="outline"
                          class="number-field-without-spinner"
                          color="accent">
            <mat-label>{{ 'registration.malo_id' | translate }}</mat-label>
            <input formControlName="maloId"
                   matInput
                   type="number"
            />
            <mat-error *ngIf="maloIdControl.invalid">
              {{ 'registration.malo_id_error' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-expansion-panel>
    <form [formGroup]="capacityForm" class="unit-container">
      <mat-radio-group attr.aria-label="{{'registration.register_location.select_unit' | translate}}"
                       class="radio-container unit-container"
                       formControlName="unit">
        <mat-radio-button [value]="defaultUnitTypeEnum.MWH_H">{{ 'registration.register_location.unit_mwh' | translate }}</mat-radio-button>
        <mat-radio-button [value]="defaultUnitTypeEnum.KWH_H">{{ 'registration.register_location.unit_kwh' | translate }}</mat-radio-button>
        <mat-radio-button [value]="defaultUnitTypeEnum.KG_H">{{ 'registration.register_location.unit_kg' | translate }}</mat-radio-button>
      </mat-radio-group>
      <p>{{ 'registration.register_location.info_text' | translate }}</p>
    </form>
    <mat-expansion-panel [expanded]="true" class="register-expansion-panel capacity-container">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'registration.register_location.data_type_capacity' | translate }}</mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="capacityForm">
        <form [formGroup]="capacityYearsForm">
          <div class="years-container">
            <ng-container *ngFor="let year of years">
              <div class="year-input-container">
                <mat-label class="left-label">{{ year }}</mat-label>
                <mat-form-field [class.error-input]="getControlIsInvalid(year)"
                                appearance="outline"
                                class="small number-field-without-spinner number-field-right-aligned">
                  <input [formControlName]="year" matInput
                         matInputCommified
                         type="text"/>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </form>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" class="register-expansion-panel energy-container">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'registration.register_location.data_type_energy' | translate }}</mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="energyForm">
        <form [formGroup]="energyYearsForm">
          <div class="years-container">
            <ng-container *ngFor="let year of years">
              <div class="year-input-container">
                <mat-label class="left-label">{{ year }}</mat-label>
                <mat-form-field [class.error-input]="getControlIsInvalid(year)"
                                appearance="outline"
                                class="small number-field-without-spinner number-field-right-aligned">
                  <input [formControlName]="year" matInput
                         matInputCommified
                         type="text"/>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </form>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</form>
