import { Injectable } from '@angular/core';
import { LogLevel } from './log-level';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  logLevel = LogLevel;

  public info(msg: string): void {
    this.logWith(this.logLevel.Info, msg);
  }

  public debug(msg: string): void {
    this.logWith(this.logLevel.Debug, msg);
  }

  public warn(msg: string): void {
    this.logWith(this.logLevel.Warn, msg);
  }

  public error(msg: string): void {
    this.logWith(this.logLevel.Error, msg);
  }

  private logWith(level: number, msg: string): void {
    switch (level) {
      /* NOT needed ?
      case this.logLevel.None:
        console.log(msg);
        break;*/
      case this.logLevel.Info:
        // COLOR: $OGE_mint1
        console.info('%c' + msg, 'color: #058e9b');
        break;
      case this.logLevel.Warn:
        // COLOR: $OGE_yellow
        console.warn('%c' + msg, 'color: #ffef18');
        break;
      case this.logLevel.Error:
        // COLOR: $OGE_error
        console.error('%c' + msg, 'color: #E93636');
        break;
      default:
        // default is DEBUG COLOR: $OGE_blue
        console.debug('%c' + msg, 'color: #143c6c');
        break;
    }
  }
}
