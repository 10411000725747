export * from './company.service';
import { CompanyService } from './company.service';
export * from './company.serviceInterface'
export * from './companyExport.service';
import { CompanyExportService } from './companyExport.service';
export * from './companyExport.serviceInterface'
export * from './companyTable.service';
import { CompanyTableService } from './companyTable.service';
export * from './companyTable.serviceInterface'
export * from './dashboardOverviewNetworkArea.service';
import { DashboardOverviewNetworkAreaService } from './dashboardOverviewNetworkArea.service';
export * from './dashboardOverviewNetworkArea.serviceInterface'
export * from './document.service';
import { DocumentService } from './document.service';
export * from './document.serviceInterface'
export * from './facility.service';
import { FacilityService } from './facility.service';
export * from './facility.serviceInterface'
export * from './facilityExport.service';
import { FacilityExportService } from './facilityExport.service';
export * from './facilityExport.serviceInterface'
export * from './generateToken.service';
import { GenerateTokenService } from './generateToken.service';
export * from './generateToken.serviceInterface'
export * from './newRegistration.service';
import { NewRegistrationService } from './newRegistration.service';
export * from './newRegistration.serviceInterface'
export * from './ogeVnbExport.service';
import { OgeVnbExportService } from './ogeVnbExport.service';
export * from './ogeVnbExport.serviceInterface'
export * from './ogeVnbSummaryExport.service';
import { OgeVnbSummaryExportService } from './ogeVnbSummaryExport.service';
export * from './ogeVnbSummaryExport.serviceInterface'
export * from './ogeVnbTable.service';
import { OgeVnbTableService } from './ogeVnbTable.service';
export * from './ogeVnbTable.serviceInterface'
export * from './user.service';
import { UserService } from './user.service';
export * from './user.serviceInterface'
export * from './userDomain.service';
import { UserDomainService } from './userDomain.service';
export * from './userDomain.serviceInterface'
export * from './userExport.service';
import { UserExportService } from './userExport.service';
export * from './userExport.serviceInterface'
export * from './userStatus.service';
import { UserStatusService } from './userStatus.service';
export * from './userStatus.serviceInterface'
export * from './vNB.service';
import { VNBService } from './vNB.service';
export * from './vNB.serviceInterface'
export * from './vNBPLZ.service';
import { VNBPLZService } from './vNBPLZ.service';
export * from './vNBPLZ.serviceInterface'
export * from './vNBSuggestion.service';
import { VNBSuggestionService } from './vNBSuggestion.service';
export * from './vNBSuggestion.serviceInterface'
export * from './version.service';
import { VersionService } from './version.service';
export * from './version.serviceInterface'
export * from './yearlySummary.service';
import { YearlySummaryService } from './yearlySummary.service';
export * from './yearlySummary.serviceInterface'
export const APIS = [CompanyService, CompanyExportService, CompanyTableService, DashboardOverviewNetworkAreaService, DocumentService, FacilityService, FacilityExportService, GenerateTokenService, NewRegistrationService, OgeVnbExportService, OgeVnbSummaryExportService, OgeVnbTableService, UserService, UserDomainService, UserExportService, UserStatusService, VNBService, VNBPLZService, VNBSuggestionService, VersionService, YearlySummaryService];
