export enum FacilityH2Type {
  Consumer = 0,
  Producer = 1,
}

export enum FacilityCo2Type {
  Einspeisung = 11,
  Ausspeisung = 12,
  Hybrid = 13,
}
