/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { TUserStateDTO } from '../model/models';
import { TUserStatusDTO } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserStatusServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     */
    apiUserStatusGet(extraHttpRequestParams?: any): Observable<TUserStatusDTO>;

    /**
     * 
     * 
     * @param userId 
     * @param tUserStateDTO 
     */
    apiUserStatusUserIdPut(userId: number, tUserStateDTO?: TUserStateDTO, extraHttpRequestParams?: any): Observable<{}>;

}
