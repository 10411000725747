import { Injectable, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, of, switchMap } from 'rxjs';
import { Select } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@UntilDestroy()
@Injectable()
export class AuthGuardService {
  private readonly router = inject(Router);

  @Select(AppStateSelectors.isUserLoggedIn())
  public isUserLoggedIn$: Observable<boolean>;

  @Select(AppStateSelectors.isUserAllowed())
  public isUserAllowed$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isCo2User$: Observable<boolean>;

  /* Because the authGuard in Route CanActive is too soon, have not the correct data */
  public authGuard() {
    return this.isUserLoggedIn$.pipe(
      untilDestroyed(this),
      switchMap(isUserLoggedIn => {
        if (isUserLoggedIn) {
          return this.isUserAllowed$;
        } else {
          return of(false);
        }
      }),
      map(isUserAllowed => {
        return !!isUserAllowed;
      })
    );
  }

  public authGuardWithRedirect() {
    this.authGuard().subscribe(isAllowed => {
      if (!isAllowed) {
        const isCo2 = this.router.url.includes('co2');
        const url = isCo2 ? 'co2/karte' : 'karte';
        this.router.navigate([url]);
      }
    });
  }

  public authGuardForUrl(url: string) {
    const isCo2Url = this.router.url.includes('co2');
    combineLatest([this.authGuard(), this.isCo2User$]).subscribe(
      ([isAllowed, isCo2User]) => {
        if (isAllowed) {
          if (isCo2Url && !isCo2User) {
            this.router.navigate([url]);
          }

          if (isCo2User && !isCo2Url) {
            this.router.navigate(['co2', url]);
          }
        }
      }
    );
  }
}
