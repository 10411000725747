import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import {
  ContentPageComponent,
  CookieConsentComponent,
  CustomDialogComponent,
  FileUploadComponent,
  FooterComponent,
  LoadingSpinnerComponent,
  NavigationBarComponent,
} from '@components';
import {
  DialogService,
  LegalService,
  NumericService,
  UnitConvertorService,
} from '@services';
import { RelativeLinkDirective, UploadDirective } from '@directives';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FacilityTypeBadgeComponent } from './components/facility-type-badge/facility-type-badge.component';
import { MatInputCommifiedDirective } from './directives/numeric/material-numeric.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CharCounterComponent } from './components/char-counter/char-counter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    RouterModule,
    TranslateModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
  ],
  declarations: [
    ContentPageComponent,
    NavigationBarComponent,
    RelativeLinkDirective,
    FileUploadComponent,
    UploadDirective,
    FooterComponent,
    LoadingSpinnerComponent,
    CookieConsentComponent,
    FacilityTypeBadgeComponent,
    CustomDialogComponent,
    MatInputCommifiedDirective,
    CharCounterComponent,
  ],
  exports: [
    ContentPageComponent,
    NavigationBarComponent,
    RelativeLinkDirective,
    FileUploadComponent,
    FooterComponent,
    LoadingSpinnerComponent,
    TranslateModule,
    CookieConsentComponent,
    FacilityTypeBadgeComponent,
    CustomDialogComponent,
    MatInputCommifiedDirective,
    CharCounterComponent,
  ],
  providers: [
    LegalService,
    UnitConvertorService,
    DialogService,
    DecimalPipe,
    NumericService,
  ],
})
export class SharedModule {}
