/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TGetPlannedH2Amount } from './tGetPlannedH2Amount';


export interface TGetH2FacilityDTO { 
    facility_name?: string | null;
    facility_type_id?: number;
    facility_city?: string | null;
    facility_latitude?: number;
    facility_longitude?: number;
    facility_street?: string | null;
    facility_street_number?: string | null;
    facility_zip_code?: string | null;
    facility_related_vnb_id?: number;
    facility_malo_id?: string | null;
    facility_id?: number;
    facility_is_dangling?: boolean;
    facility_plan?: TGetPlannedH2Amount;
}

