<ng-container [formGroup]="addressForm">
  <mat-form-field [class.error-input]="addressForm.invalid && errorKey" appearance="outline"
                  color="accent">
    <mat-label>{{ title }}</mat-label>
    <input
      #input
      formControlName="address"
      matInput
      placeholder=" "
      required
      type="search"
      [tabindex]="tabOrder"
    />

    <button *ngIf="withClearIcon" mat-icon-button matSuffix>
      <mat-icon>close</mat-icon>
    </button>
    <mat-error>
      <span *ngIf="errorKey; else required">{{ 'address.error.title' | translate }} {{ 'address.error_' + errorKey | translate }}</span>
      <ng-template #required>
        <span *ngIf="addressForm.controls.address.hasError('required')">
        {{ 'registration.co2.required' | translate }}
      </span>

      </ng-template>
    </mat-error>
  </mat-form-field>
</ng-container>
