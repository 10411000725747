import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { CompanyService } from './api/company.service';
import { CompanyExportService } from './api/companyExport.service';
import { CompanyTableService } from './api/companyTable.service';
import { DashboardOverviewNetworkAreaService } from './api/dashboardOverviewNetworkArea.service';
import { DocumentService } from './api/document.service';
import { FacilityService } from './api/facility.service';
import { FacilityExportService } from './api/facilityExport.service';
import { GenerateTokenService } from './api/generateToken.service';
import { NewRegistrationService } from './api/newRegistration.service';
import { OgeVnbExportService } from './api/ogeVnbExport.service';
import { OgeVnbSummaryExportService } from './api/ogeVnbSummaryExport.service';
import { OgeVnbTableService } from './api/ogeVnbTable.service';
import { UserService } from './api/user.service';
import { UserDomainService } from './api/userDomain.service';
import { UserExportService } from './api/userExport.service';
import { UserStatusService } from './api/userStatus.service';
import { VNBService } from './api/vNB.service';
import { VNBPLZService } from './api/vNBPLZ.service';
import { VNBSuggestionService } from './api/vNBSuggestion.service';
import { VersionService } from './api/version.service';
import { YearlySummaryService } from './api/yearlySummary.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
