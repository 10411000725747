<mat-sidenav-container>
  <!-- Main Content -->
  <mat-sidenav-content>
    <app-navigation-bar
    ></app-navigation-bar>
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
