<h4>
    {{'registration.company_policy_uplaod' | translate}} {{isRequired ? '*' : ''}}
    <mat-icon fontIcon="info_outline"
              class="mat-icon-headline"
              matTooltip="{{'registration.company_policy_upload_tooltip' | translate}}"
              matTooltipPosition="right"
    ></mat-icon>
</h4>
<!-- for company data -->
<ng-content></ng-content>
<div *ngIf="isRequired" class="error-hint-container">
    <span class="hint-text">{{'registration.company_policy_hint_required'| translate}}</span>
</div>
<app-file-upload [acceptedFileType]="['pdf']"
                 (selectedFile)="onSelectedPolicy($event)"></app-file-upload>