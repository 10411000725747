import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Co2UserRegistrationFormModel } from '@registration/interfaces';
import { RegistrationService } from '@registration/services';
import { Co2RoleEnum } from '../../../../../shared/enums/co2-role-enum';
import { TGetCO2Company, UserService } from '@api';
import { GetUsersCompany } from '@appState/app.actions';
import { Select, Store } from '@ngxs/store';
import { ErrorHandlingService } from '@services';
import { MatDialogRef } from '@angular/material/dialog';
import { RegistrationEntryComponent } from '@registration/components';
import { Router } from '@angular/router';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-co2-actions',
  templateUrl: './co2-actions.component.html',
  styleUrls: ['./co2-actions.component.scss'],
})
export class Co2ActionsComponent implements OnInit {
  @Select(AppStateSelectors.getUsersCompany())
  public usersCompany$: Observable<TGetCO2Company>;
  public form: FormGroup<Co2UserRegistrationFormModel>;
  public get legalFormGroup(): FormGroup {
    return this.form.controls.legalData;
  }

  private roleEnum = Co2RoleEnum;
  private company: TGetCO2Company = null;

  constructor(
    private readonly parentFormGroup: FormGroupDirective,
    private readonly registrationService: RegistrationService,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly router: Router,
    private readonly dialogRef: MatDialogRef<RegistrationEntryComponent>
  ) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;

    this.usersCompany$
      .pipe(untilDestroyed(this))
      .subscribe(company => (this.company = company));
  }

  public onCo2Registration(): void {
    this.userService
      .apiUserPost({ h2_co2_user: this.mapDataToBackendStructur() })
      .subscribe({
        next: () => {
          // load data
          this.store.dispatch(new GetUsersCompany());
          this.dialogRef.close();
          this.router.navigate([this.router.url]);
        },
        error: err => this.errorHandlingService.showError(err),
      });
  }

  public openTerms(): void {
    this.registrationService.openTerms();
  }

  public openPolicy(): void {
    this.registrationService.openPolicy();
  }

  public openProceduralRules(): void {
    this.registrationService.openProceduralRules();
  }

  private mapDataToBackendStructur() {
    const rawValue = this.form.getRawValue();
    if (!!this.company) {
      return {
        user_first_name: rawValue.firstname,
        user_last_name: rawValue.lastname,
        user_mail: rawValue.email,
        user_b2c_object_id: rawValue.userB2cObjectId,
        company_id: this.company.company_id,
        company_name: this.company.company_name,
        company_city: this.company.company_location.location_city,
        company_latitude: this.company.company_location.location_latitude,
        company_longitude: this.company.company_location.location_longitude,
        company_street: this.company.company_location.location_street,
        company_street_number:
          this.company.company_location.location_street_number,
        company_zip_code: this.company.company_location.location_zip_code,
        company_contact_first_name: null,
        company_contact_last_name: null,
        company_contact_telephone: null,
        company_contact_function: null,
        company_roles_list: null,
      };
    } else {
      const address = rawValue.companyAddress;
      return {
        user_first_name: rawValue.firstname,
        user_last_name: rawValue.lastname,
        user_mail: rawValue.email,
        user_b2c_object_id: rawValue.userB2cObjectId,
        company_id: 0,
        company_name: rawValue.companyName,
        company_city: address.city,
        // IMPORTANT !! Switch LAT - LNG for BE
        company_latitude: address.lng,
        company_longitude: address.lat,
        company_street: address.street,
        company_street_number: address.streetNumber,
        company_zip_code: address.zipCode,
        company_contact_first_name: rawValue.contactPersonFirstname,
        company_contact_last_name: rawValue.contactPersonLastname,
        company_contact_telephone: rawValue.phoneNumberOfContactPerson,
        company_contact_function:
          rawValue.roleOfContactPerson.length === 0
            ? null
            : rawValue.roleOfContactPerson,
        company_roles_list: Object.keys(rawValue.role)
          .filter(r => {
            const control = this.form.controls.role.get(r);
            return !!control.getRawValue();
          })
          .map(r => {
            return this.roleEnum[r.toUpperCase()];
          }),
      };
    }
  }
}
