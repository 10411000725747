import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MapsPlacesService } from '@map/services/maps-places/maps-places.service';
import { CompanyAddressFormModel } from '@registration/interfaces';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-address-search-autocomplete',
  templateUrl: './address-search-autocomplete.component.html',
  styleUrls: ['./address-search-autocomplete.component.scss'],
})
export class AddressSearchAutocompleteComponent
  implements OnInit, AfterViewInit
{
  @Input({ required: true })
  public title: string;
  @Input({ required: false })
  public withClearIcon?: boolean = false;
  @Input({ required: false })
  public tabOrder?: number;
  @Input({ required: false })
  public isCo2: boolean = false;
  @ViewChild('input')
  public inputElement: ElementRef;

  public addressForm: FormGroup<CompanyAddressFormModel>;
  public errorKey: string = null;

  public constructor(
    private mapsPlacesService: MapsPlacesService,
    private readonly parentForm: FormGroupDirective,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.parentForm?.control) {
      this.addressForm = this.parentForm.control?.get(
        'companyAddress'
      ) as FormGroup;
      if (this.addressForm?.get('address')?.value != '') {
        this.checkAddress();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  public async ngAfterViewInit(): Promise<void> {
    const places = await this.mapsPlacesService.getAutocompletePlace(
      this.inputElement,
      this.isCo2
    );
    if (places) {
      google.maps.event.addListener(places, 'place_changed', () => {
        const place = places.getPlace();
        this.setAddressForm(place);
      });
    }
  }

  private setAddressForm(place: google.maps.places.PlaceResult) {
    const addressComponent = place.address_components;
    this.addressForm.setValue({
      address: place.formatted_address,
      street: this.getAddressComponentByType(addressComponent, 'route'),
      streetNumber: this.getAddressComponentByType(
        addressComponent,
        'street_number'
      ),
      city: this.getAddressComponentByType(addressComponent, 'locality'),
      zipCode: this.getAddressComponentByType(addressComponent, 'postal_code'),
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    this.checkAddress();
  }

  private checkAddress() {
    if (this.addressForm.valid) {
      this.addressForm.get('address')?.setErrors(null);
      this.errorKey = null;
    } else {
      Object.keys(this.addressForm.controls).forEach(key => {
        const control = this.addressForm.get(key);
        if (control.invalid) {
          this.errorKey = key;
          this.addressForm.get('address')?.setErrors({ required: true });
        }
      });
    }
  }

  private getAddressComponentByType(
    addressComponent: google.maps.GeocoderAddressComponent[],
    type: string
  ) {
    const types = addressComponent.filter(x => x?.types.includes(type));
    if (types.length > 0) {
      return types[0]?.long_name;
    } else {
      return '';
    }
  }
}
