<app-content-page>
  <h1 class="headline-gotham">{{ 'faq.@' | translate }}</h1>

  <ng-container *ngIf="(isCo2User$ | async) === false && !isCo2Url">
    <div *ngFor="let category of (h2Faq$ | async)?.categories" class="category">
      <h4 class="color-copy">
        {{ category.title }}
      </h4>

      <mat-expansion-panel *ngFor="let question of category.questions">
        <mat-expansion-panel-header>
          <mat-panel-title class="headline-gotham-black">
            {{ question.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="question.answer" class="answer body1-gotham"></div>
      </mat-expansion-panel>

      <p class="faq-info" [innerHTML]="'faq.footerInfo' | translate"></p>
    </div>
  </ng-container>

  <ng-container *ngIf="(isUserOGE$ | async) || (isCo2User$ | async) || isCo2Url">
    <div  *ngFor="let category of (co2Faq$ | async)?.categories" class="category">
      <h4 class="color-copy">
        {{ category.title }}
      </h4>

      <mat-expansion-panel *ngFor="let question of category.questions">
        <mat-expansion-panel-header>
          <mat-panel-title class="headline-gotham-black">
            {{ question.question }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div [innerHTML]="question.answer" class="answer body1-gotham"></div>
      </mat-expansion-panel>

      <p class="faq-info" [innerHTML]="'faq.footer_info_co2' | translate"></p>
    </div>
  </ng-container>
</app-content-page>
