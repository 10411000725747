/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TTokenDTO { 
    mail?: string | null;
    token?: string | null;
    company_name?: string | null;
    company_id?: number;
    is_company_admin?: boolean;
    user_status_id?: number;
    user_status_name?: string | null;
}

