/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TNameundIdDTO } from './tNameundIdDTO';


export interface TCompanySuggestionDTO { 
    company_type_id?: number;
    company_liste?: Array<TNameundIdDTO> | null;
}

