import { Component } from '@angular/core';
import { LegalService } from '../../services/legal/legal.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  protected env = environment;

  constructor(private readonly legalService: LegalService) {}

  public openTerms(): void {
    this.legalService.getTermsAndConditions().subscribe((data) => {
      this.legalService.openPDF(data.document_string);
    });
  }

  public openPolicy(): void {
    this.legalService.getPolicy().subscribe((data) => {
      this.legalService.openPDF(data.document_string);
    });
  }

  public getReleaseVersion(): string {
    return this.env.productionVersion;
  }
}
