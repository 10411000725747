import { CanActivateFn, Router } from '@angular/router';
import { AppStateSelectors } from '@appState/app-state.selector';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';

export const urlGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  const isUserLoggedIn = store.selectSnapshot(
    AppStateSelectors.isUserLoggedIn()
  );
  const isCo2Customer = store.selectSnapshot(
    AppStateSelectors.isUserCo2Customer()
  );
  const mustCompleteRegistration = store.selectSnapshot(
    AppStateSelectors.userMustCompleteRegistration()
  );
  const isCo2Url = state.url.includes('co2');
  if (mustCompleteRegistration) {
    return true;
  }
  if (isUserLoggedIn) {
    if (isCo2Url && !isCo2Customer) {
      const newUrl = state.url.split('co2')[1];
      router.navigate([newUrl]);
      return false;
    }
    if (isCo2Customer && !isCo2Url) {
      router.navigate([`/co2${state.url}`]);
      return false;
    }
  }
  return true;
};
