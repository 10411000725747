import { FormControl, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function validateExtrenumValue(
  extremum: string,
  value: number
): ValidatorFn {
  return function validate(formControl: FormControl) {
    const controlValue = Number(formControl.value);
    if (extremum == 'min') {
      if (controlValue >= value) {
        return null;
      } else {
        return {
          min: true,
        };
      }
    }
    if (extremum == 'max') {
      if (controlValue <= value) {
        return null;
      } else {
        return {
          max: true,
        };
      }
    }
    return null;
  };
}

export class ValidateExtrenumValueMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return control && control.invalid;
  }
}
