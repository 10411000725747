<div class="upload-container caption-gotham" appUpload (fileDropped)="onFileDropped($event)">
    <input type="file" [id]="'fileDropRef-'+id"
           [accept]="getAcceptedFileTypes()"
           (change)="fileBrowseHandler($event)"/>
    <mat-icon fontIcon="upload"></mat-icon>
    <label class="upload-paragraph caption-gotham" [for]="'fileDropRef-'+id">
        <ng-container *ngFor="let fileType of acceptedFileType; let i = index">
            <span *ngIf="i === 0" class="bold">{{fileType.toUpperCase()}}</span>
            <span *ngIf="i > 0"> {{'file_upload.or' | translate}} <span class="bold">{{fileType.toUpperCase()}}</span></span>
        </ng-container>
        {{getFileMaxSize()}} {{'file_upload.drap_and_drop' | translate}} <span class="button-gotham">{{'file_upload.lokal_file' | translate}}</span></label>
</div>
<div *ngIf="file" class="file-list">
    <span>{{file.name}}</span>
    <span>
        <mat-icon *ngIf="!errorOnFile" fontIcon="done"></mat-icon>
        <mat-icon *ngIf="errorOnFile" class="error" color="warn" fontIcon="close"></mat-icon>
    </span>
</div>