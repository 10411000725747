import { AuthenticationResult } from '@azure/msal-browser';
import {
  TCompanyShortDTO,
  TCompanyShortPlusOptionalPlzListeDTO,
  TCreateDokumentDTO,
  TPutCompanyDTO,
  TUserWithStateDTO,
} from '@api';
import { DOCUMENT_TYPE_ENUM } from '@services';
import { AppViewEnum } from '../shared/enums/app-view-enum';
const APP_ACTION_TYPE = '[App]';

export class InitAppState {
  public static type = `${APP_ACTION_TYPE} InitAppState`;
}

export class SetVNBId {
  public static type = `${APP_ACTION_TYPE} SetVNBId`;

  public constructor(public readonly vnbId?: string) {}
}

export class SetAccountInfo {
  public static type = `${APP_ACTION_TYPE} SetAccountInfo`;

  public constructor(public readonly account?: AuthenticationResult) {}
}

export class SetCompany {
  public static type = `${APP_ACTION_TYPE} SetCompany`;

  public constructor(
    public readonly company?:
      | TCompanyShortDTO
      | TCompanyShortPlusOptionalPlzListeDTO
  ) {}
}

export class GetUsersCompany {
  public static type = `${APP_ACTION_TYPE} GetUsersCompany`;
}

export class SaveCompanyData {
  public static type = `${APP_ACTION_TYPE} SaveCompanyData`;

  public constructor(public readonly putCompanyDTO: TPutCompanyDTO) {}
}

export class SaveDocumentByType {
  public static type = `${APP_ACTION_TYPE} SaveDocumentByType`;

  public constructor(
    public readonly type: DOCUMENT_TYPE_ENUM,
    public readonly file: TCreateDokumentDTO
  ) {}
}

export class LoadCustomerLogo {
  public static type = `${APP_ACTION_TYPE} LoadCustomerLogo`;
}

export class SetUser {
  public static type = `${APP_ACTION_TYPE} SetUser`;

  public constructor(
    public readonly userDTO?: TUserWithStateDTO,
    public readonly isUserLocked?: boolean
  ) {}
}

export class SetCookiesAccepted {
  public static type = `${APP_ACTION_TYPE} SetCookiesToAccepted`;

  public constructor(public readonly cookieAccepted: boolean) {}
}

export class LoadFacilityList {
  public static type = `${APP_ACTION_TYPE} LoadFacilityList`;
}

export class SetSelectedFacilityId {
  public static type = `${APP_ACTION_TYPE} SetSelectedFacilityId`;

  public constructor(public readonly id: number | null) {}
}

export class LoadSelectedFacilityById {
  public static type = `${APP_ACTION_TYPE} LoadSelectedFacilityById`;

  public constructor(public readonly id?: number | null) {}
}

export class DeleteFacilityById {
  public static type = `${APP_ACTION_TYPE} DeleteFacilityById`;

  public constructor(public readonly id: number | null) {}
}

export class UpdateUserInBE {
  public static type = `${APP_ACTION_TYPE} UpdateUserInBE`;
}

export class SetViewApp {
  public static type = `${APP_ACTION_TYPE} SetViewApp`;

  public constructor(public readonly appView: AppViewEnum) {}
}
export class SetUserMustCompleteRegistration {
  public static type = `${APP_ACTION_TYPE} SetUserMustCompleteRegistration`;
}
