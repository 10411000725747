import { Environment } from './environment.type';
import { baseEnvironment } from './environment.base';

const changes: Partial<Environment> = {
  webBasePath: 'https://hyco-connect-dev.net/',
  //apiBasePath: 'https://h2-connect-v-31.azurewebsites.net',
  apiBasePath: 'https://dev-api.hyco-connect.net',
  debug: false,
  oauth: {
    ...baseEnvironment.oauth,
    ...{
      postLogoutRedirectUri: 'https://hyco-connect-dev.net',
      redirectUri: 'https://hyco-connect-dev.net/uebersicht',
      redirectUriCo2: 'https://hyco-connect-dev.net/co2/uebersicht',
    },
  },
};

export const environment: Environment = { ...baseEnvironment, ...changes };
