<header>
    <button class="close-button" mat-icon-button mat-dialog-close>
        <mat-icon fontIcon="close"></mat-icon>
    </button>
    <h2 class="color-white">{{ (usersCompany$ | async)?.company_name }}</h2>
    <h3 class="color-white">{{ 'company_data.headline' | translate }}</h3>
</header>


<mat-dialog-content>
    <form [formGroup]="companyForm">
        <h4>{{ 'registration.company_data' | translate }}</h4>
        <div class="split-pane">
            <div class="split-pane-container">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>{{ 'registration.company_name' | translate }}</mat-label>
                    <input
                            autocomplete="company"
                            formControlName="companyName"
                            matInput
                            type="text"
                    />
                </mat-form-field>
            </div>
            <div class="split-pane-container">
                <app-address-search-autocomplete title="{{'registration.company_address' | translate}}"
                [isCo2]="isUserCo2Customer$ | async">
                </app-address-search-autocomplete>
            </div>
        </div>
        <div *ngIf="isUserVNB$ | async" class="zip-policy-container">
            <app-zip-code-area></app-zip-code-area>
            <div class="split-pane">
                <div class="company-logo">
                    <h4 [class.error]="imagesHasError">
                        {{'registration.company_logo_upload' | translate}}
                        <mat-icon fontIcon="info_outline"
                                  class="mat-icon-headline"
                                  matTooltip="{{'registration.company_logo_upload_tooltip' | translate}}"
                                  matTooltipPosition="right"
                        ></mat-icon>
                    </h4>
                    <div class="error-hint-container">
                        <span class="hint-text">{{'registration.company_logo_upload_image_hint'| translate}}</span>
                        <span *ngIf="imagesHasError" class="error error-text">{{'registration.company_logo_upload_image_size' | translate}}</span>
                    </div>
                    <app-file-upload [acceptedFileType]="['png', 'jpeg']"
                                     [maxFileSizeInMb]="2"
                                     (selectedFile)="onSelectedCompanyLogo($event)"></app-file-upload>
                </div>
                <div>
                    <app-upload-company-policy [isRequired]="false">
                        <button class="body1-gotham" type="button" (click)="getPolicy()" mat-button>
                            {{ 'company_data.download_own_policy' | translate }}
                            <mat-icon fontIcon="download" iconPositionEnd></mat-icon>
                        </button>
                    </app-upload-company-policy>
                </div>
            </div>
        </div>
        <div *ngIf="isUserCo2Customer$ | async" class="co2-container">
            <div class="split-pane">
                <div class="split-pane-container">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>{{ 'registration.contact_person_first_name' | translate }}</mat-label>
                        <input formControlName="contactFirstname"
                               matInput
                               type="text"
                        />
                    </mat-form-field>
                    <mat-form-field  appearance="outline" color="accent">
                        <mat-label>{{ 'registration.phone_contact_person' | translate }}</mat-label>
                        <input formControlName="contactTelephone"
                               matInput
                               type="text"
                        />
                    </mat-form-field>
                </div>
                <div class="split-pane-container">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>{{ 'registration.contact_person_last_name' | translate }}</mat-label>
                        <input formControlName="contactLastname"
                               matInput
                               type="text"
                        />
                    </mat-form-field>
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>{{ 'registration.role_contact_person' | translate }}</mat-label>
                        <input formControlName="contactFunction"
                               matInput
                               type="text"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="split-pane">
                <div class="split-pane§-container">
                    <app-co2-company-role></app-co2-company-role>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div></div>
    <button (click)="save()"
            color="accent"
            mat-flat-button
            type="submit">
        {{ 'company_data.accept' | translate }}
    </button>
</mat-dialog-actions>
