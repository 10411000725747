import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input()
  public id?: string = uuidv4();
  @Input({ required: true })
  public acceptedFileType: string[];
  @Input()
  public maxFileSizeInMb: number;
  @Output()
  public selectedFile: EventEmitter<any> = new EventEmitter<any>();

  public file: File;
  public errorOnFile: boolean;

  public onFileDropped($event: FileList): void {
    this.file = $event[0];
    if (this.isFileTypeCorrect(this.file.type)) {
      this.selectedFile.emit(this.file);
    }
  }

  public fileBrowseHandler($event: any): void {
    this.file = $event.target.files[0];
    if (this.isFileTypeCorrect(this.file.type)) {
      this.selectedFile.emit(this.file);
    }
  }

  public getFileMaxSize(): string {
    if (!!this.maxFileSizeInMb) {
      return `(${this.maxFileSizeInMb} MB)`;
    }
    return '';
  }

  public getAcceptedFileTypes(): string {
    if (this.acceptedFileType.length === 1) {
      return `.${this.acceptedFileType[0]}`;
    }
    return `.${this.acceptedFileType.join(',.')}`;
  }

  private isFileTypeCorrect(fileType: string): boolean {
    const isCorrect = this.acceptedFileType.includes(
      fileType.replace(/.*\//, '')
    );
    this.errorOnFile = !isCorrect;
    return isCorrect;
  }
}
