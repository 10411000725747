/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TCreateDokumentDTO } from './tCreateDokumentDTO';
import { TCreateStandardCompanyDTO } from './tCreateStandardCompanyDTO';


export interface TCreateVNBUserDTO { 
    user_first_name?: string | null;
    user_last_name?: string | null;
    user_mail?: string | null;
    user_b2c_object_id?: string | null;
    user_company?: TCreateStandardCompanyDTO;
    company_plz_liste?: Array<string> | null;
    company_logo?: TCreateDokumentDTO;
    company_datenschutz_erklaerung?: TCreateDokumentDTO;
}

