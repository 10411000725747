import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  type Event as RouterEvent,
} from '@angular/router';

import { Store } from '@ngxs/store';

import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { AppState } from '@appState/app.state';
import { AppStateModel } from '@appState/app-state.model';

@Directive({
  selector: '[appRelativeLink]',
})
export class RelativeLinkDirective implements OnInit, OnDestroy {
  @Input()
  public set appRelativeLink(href: string) {
    this.appRelativeLink$.next(href);
  }

  @HostBinding('class.state-active')
  public get isActive(): boolean {
    return this._isActive;
  }

  @HostBinding('attr.href')
  public get href(): string {
    return this._href;
  }

  private readonly appAbsoluteLink$: BehaviorSubject<string | undefined> =
    new BehaviorSubject(undefined);

  private readonly appRelativeLink$: BehaviorSubject<string | undefined> =
    new BehaviorSubject(undefined);

  private readonly destroy$: Subject<void> = new Subject();

  private _isActive = false;

  private _href?: string;

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    this.watchActiveStateChanges();
    this.watchUrlChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click', ['$event'])
  public async click(event: Event): Promise<void> {
    event.preventDefault();
    const co2Url =
      this.router.url.includes('co2') && !this._href.includes('co2');
    const url = co2Url ? `/co2/${this._href}` : this._href;
    await this.router.navigate([url]);
  }

  private watchActiveStateChanges(): void {
    combineLatest([
      this.appAbsoluteLink$.pipe(
        filter(
          (appAbsoluteLink: string | undefined): appAbsoluteLink is string =>
            !!appAbsoluteLink
        )
      ),
      this.router.events.pipe(
        filter(
          (event: RouterEvent): event is NavigationEnd =>
            event instanceof NavigationEnd
        )
      ),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([appAbsoluteLink, navigationEnd]: readonly [
          string,
          NavigationEnd,
        ]): void => {
          this._isActive = navigationEnd.url === appAbsoluteLink;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  private watchUrlChanges(): void {
    combineLatest([
      this.appRelativeLink$.pipe(
        filter(
          (appRelativeLink: string | undefined): appRelativeLink is string =>
            !!appRelativeLink
        )
      ),
      this.store.select<AppStateModel>(AppState),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(
          ([appRelativeLink, appState]: readonly [
            string,
            AppStateModel,
          ]): string =>
            appState.vnbId
              ? `/vnb/${appState.vnbId}/${appRelativeLink}`
              : `/${appRelativeLink}`
        )
      )
      .subscribe({
        next: (appAbsoluteLink: string): void => {
          this._href = appAbsoluteLink;
          this.appAbsoluteLink$.next(appAbsoluteLink);
          this.changeDetectorRef.markForCheck();
        },
      });
  }
}
