import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-date-table-expansion-panel',
  templateUrl: './date-table-expansion-panel.component.html',
  styleUrls: ['./date-table-expansion-panel.component.scss'],
})
export class DateTableExpansionPanelComponent {
  @Input()
  public expanded: boolean;

  @Input({ required: true })
  public title: string;

  @Input({ required: true })
  public tableHeaders: { sortColumn: string; labelColumn: string }[];

  @Input()
  public hasActions: boolean;

  @Output() sortFunction: EventEmitter<any> = new EventEmitter();

  @Input() public isVNB!: boolean | null;
  @Input() public isOGE!: boolean | null;
  @Input() public isCo2Customer!: boolean | null;
  @Input()
  public headerClass:
    | 'h2-company'
    | 'co2-company'
    | 'h2-facility'
    | 'co2-facility'
    | 'vnb';

  @ViewChild('sortSection') set sortSection(sort: MatSort) {
    this.sortFunction.emit(sort);
  }
}
