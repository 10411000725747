/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EExportBrille = 0 | 1562 | 1792;

export const EExportBrille = {
    NUMBER_0: 0 as EExportBrille,
    NUMBER_1562: 1562 as EExportBrille,
    NUMBER_1792: 1792 as EExportBrille
};

