<app-loading-spinner *ngIf="(actionState$ | async)?.isLoading; else tplGraph">
</app-loading-spinner>

<ng-template #tplGraph>
  <div *ngIf="datasets$ | async as datasets" class="graph">
    <div *ngIf="activeDataType$ | async as activeDataType" class="graph-actions">
      <div class="controls">
        <mat-button-toggle-group
          (change)="toggleDataset($event.value)"
          [multiple]="true"
          [value]="activeDatasetType"
          class="dataset-toggles"
        >
          <mat-button-toggle class="caption-gotham" value="0">
            <mat-icon class="dataset-icon">arrow_upward</mat-icon>
            {{ 'dashboard.graph.production' | translate }}
          </mat-button-toggle>
          <mat-button-toggle class="caption-gotham" value="1">
            <mat-icon class="dataset-icon">arrow_downward</mat-icon>
            {{ 'dashboard.graph.demand' | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div *ngIf="activeUnit$ | async as activeUnitType" class="graph-actions">
        <div class="controls data-type-controls caption-gotham">
          <button (click)="setDataTypeAndUnitType(dataType.POWER, displayUnitPowerToggleGroup.value)"
                  [class.state-active]="activeDataType === dataType.POWER"
                  class="data-type-button caption-gotham"
                  mat-button
          >
            {{ 'dashboard.graph.filter.power' | translate }}
          </button>

          <mat-button-toggle-group
            #displayUnitPowerToggleGroup="matButtonToggleGroup"
            (change)="setDataTypeAndUnitType(dataType.POWER, $event.value)"
            (click)="setDataTypeAndUnitType(dataType.POWER, activeUnitType)"
            [class.disabled]="activeDataType !== dataType.POWER"
            [class.state-active]="activeDataType === dataType.POWER"
            [value]="unitType.KWH"
          >
            <mat-button-toggle [value]="unitType.KWH">{{
              getDisplayUnitText('dashboard.graph.filter.power', 'kwh', true) | translate
              }}
            </mat-button-toggle>
            <mat-button-toggle [value]="unitType.KG">{{
                getDisplayUnitText('dashboard.graph.filter.power', 'kg', true) | translate
              }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="controls data-type-controls">
          <button (click)="setDataTypeAndUnitType(dataType.ENERGY, displayUnitEnergyToggleGroup.value)"
                  [class.state-active]="activeDataType === dataType.ENERGY"
                  class="data-type-button caption-gotham"
                  mat-button>
            {{ 'dashboard.graph.filter.energy' | translate }}
          </button>

          <mat-button-toggle-group
            #displayUnitEnergyToggleGroup="matButtonToggleGroup"
            (change)="setDataTypeAndUnitType(dataType.ENERGY, $event.value)"
            (click)="setDataTypeAndUnitType(dataType.ENERGY, activeUnitType)"
            [class.disabled]="activeDataType !== dataType.ENERGY"
            [class.state-active]="activeDataType === dataType.ENERGY"
            [value]="unitType.KWH"
            class="caption-gotham"
          >
            <mat-button-toggle [value]="unitType.KWH">{{
              getDisplayUnitText('dashboard.graph.filter.energy', 'kwh', false) | translate
              }}
            </mat-button-toggle>
            <mat-button-toggle [value]="unitType.KG">{{
                getDisplayUnitText('dashboard.graph.filter.energy', 'kg', false) | translate
              }}
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </div>

    <div class="graph-container">
      <canvas
        #chart="base-chart"
        [datasets]="[
          {
            label: 'dashboard.graph.production' | translate,
            backgroundColor: '#52a436',
            data: datasets.production,
            hidden: !activeDatasetType.includes(datasetType.ERZEUGUNG)
          },
          {
            label: 'dashboard.graph.demand' | translate,
            backgroundColor: '#007CBE',
            data: datasets.demand,
            hidden: !activeDatasetType.includes(datasetType.BEDARF)
          }
        ]"
        [labels]="labels$ | async"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="'bar'"
        baseChart
        class="chart"
        height="1"
        width="3"
      >
      </canvas>
    </div>
  </div>
  <div class="no-data-container" *ngIf="(labels$ | async) === null">
    {{ 'dashboard.graph.no_data' | translate }}
  </div>
</ng-template>
