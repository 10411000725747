import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  styleUrls: ['./app.component.scss'],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {}
