export const SpecificErrors = {
  'Eine Facility gleichen Namens':
    'registration.register_location.error_duplicate',
};

// add the url and a string of the error in the detail or title
export const noErrorForSpecificUrls = {
  '/dokumente/Document': 'does not exist',
  '/VNBPLZ': 'Not Found',
  '/Facility': 'not found in database',
};
