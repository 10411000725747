/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TH2AmountPerYearDTO } from './tH2AmountPerYearDTO';
import { TCompanyShortDTO } from './tCompanyShortDTO';
import { TCO2AmountPerYearDTO } from './tCO2AmountPerYearDTO';


export interface TAmountPerYearDTO { 
    company?: TCompanyShortDTO;
    h2_amounts?: Array<TH2AmountPerYearDTO> | null;
    co2_amounts?: Array<TCO2AmountPerYearDTO> | null;
}

