<header>
  <div class="dialog-first-row">
    <div></div>
    <button class="close-button" mat-dialog-close mat-icon-button>
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>

  <div class="dialog-last-row">
    <h3 class="color-white">{{
        (
          isReadonly ?
            'registration.register_location_headline_readonly'
            : 'registration.register_location_headline')
          | translate
      }}</h3>

    <button (click)="changeIsReadonly()"
            *ngIf="isReadonly && (isUserCo2Customer$ | async)"
            class="location-edit-button button-as-Link"
            mat-flat-button>
      <mat-icon>edit</mat-icon>
      {{ 'registration.register_location_edit' | translate }}
    </button>
  </div>
</header>

<mat-dialog-content>
  <p class="caption-gotham required-field">{{'app.required_fields' | translate }}</p>
  <form [formGroup]="form">
    <app-register-co2-data-form [step]="step"></app-register-co2-data-form>
  </form>
</mat-dialog-content>

<mat-dialog-actions [formGroup]="form">
  <div class="checkbox-container">
    <mat-checkbox color="accent" formControlName="sharingWithAuthorities">
      <mat-label>
        {{ 'registration.co2.sharing_with_authorities' | translate }}
      </mat-label>
      <mat-icon fontIcon="info_outline"
                matTooltip="{{'registration.co2.sharing_with_authorities_tool_tip' | translate}}"
                matTooltipPosition="right"
      ></mat-icon>
    </mat-checkbox>
    <mat-checkbox color="accent" formControlName="sharingWithThirdParty">
      <mat-label>
        {{ 'registration.co2.sharing_with_third_parties' | translate }}
      </mat-label>
      <mat-icon fontIcon="info_outline"
                matTooltip="{{'registration.co2.sharing_with_third_parties_tool_tip' | translate}}"
                matTooltipPosition="right"
      ></mat-icon>
    </mat-checkbox>
  </div>
  <span *ngIf="errors?.length > 0" class="error">
    {{ 'registration.co2.form_invalid' | translate }}
  </span>
  <div *ngIf="!isReadonly" class="button-gotham action-container">
    <div *ngIf="facility === null" class="button-gotham">
      {{ 'registration.co2.step' | translate:{currentStep: (step + 1), allSteps: allSteps} }}
    </div>
    <button (click)="nextStep()"
            *ngIf="step < 3 && facility === null"
            [disabled]="!checkFormValidationOfCurrentStep(step)"
            class="button-gotham"
            color="accent"
            mat-flat-button
            type="submit">
      {{ 'registration.co2.next' | translate }}
    </button>
    <button (click)="onCreate()"
            *ngIf="step >= 3 || facility !== null"
            [disabled]="form?.invalid || isSaving"
            color="accent"
            mat-flat-button
            type="submit">
      {{ 'registration.co2.register' | translate }}
    </button>
  </div>
</mat-dialog-actions>
