<header>
    <app-hydrogen-header *ngIf="isHydrogenCustomer; else co2Headline"></app-hydrogen-header>
    <ng-template #co2Headline>
        <div class="filler"></div>
        <h3 *ngIf="!companyName" class="color-white">{{ 'registration.register_customer' | translate }}</h3>
        <h3 *ngIf="companyName" class="color-white">{{ 'registration.register_company_for_company' | translate: {'company': companyName} }}</h3>
    </ng-template>
</header>

<mat-dialog-content>
    <div *ngIf="(actionState$ | async)?.isLoading; else tplUserForm"
         class="spinner-container">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <ng-template #tplUserForm>
        <form [formGroup]="form">
            <app-registration-personal-data></app-registration-personal-data>
            <p class="caption-gotham required-field">{{'app.required_fields' | translate }}</p>
            <app-customer-registration *ngIf="isHydrogenCustomer; else co2Content"></app-customer-registration>
            <ng-template #co2Content>
                <app-co2-customer-registration></app-co2-customer-registration>
            </ng-template>
        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions [formGroup]="form">
    <app-hydrogen-actions *ngIf="isHydrogenCustomer; else co2Actions"></app-hydrogen-actions>
    <ng-template #co2Actions>
        <app-co2-actions></app-co2-actions>
    </ng-template>
</mat-dialog-actions>
