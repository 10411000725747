import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env';
import { AuthenticationService } from '@authentication';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookieConsentComponent } from '@components';
import { Observable, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DialogService } from '@services';
import { AppStateSelectors } from '@appState/app-state.selector';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  @Select(AppStateSelectors.getCookiesAreAccepted())
  public readonly cookiesAccepted$: Observable<boolean>;
  @Select(AppStateSelectors.isUserLoggedIn())
  public readonly isUserLoggedIn$: Observable<boolean>;
  @Select(AppStateSelectors.isUserApproved())
  public readonly isUserApproved$: Observable<boolean>;

  constructor(
    private readonly translateService: TranslateService,
    private readonly authService: AuthenticationService,
    private readonly dialogService: DialogService,
    private readonly store: Store
  ) {}

  public static appInitializer(
    appInitService: AppInitService
  ): () => Promise<any> {
    return (): Promise<any> => appInitService.init();
  }

  public async init(): Promise<void> {
    this.translateService.setDefaultLang(environment.defaultLang);
    this.checkIsAppInstalledVersion();
    this.checkCookiesAndOpenDialog();
    this.checkIsUserAllowed();
  }

  private checkCookiesAndOpenDialog() {
    this.cookiesAccepted$
      .pipe(untilDestroyed(this))
      .subscribe(cookiesAccepted => {
        if (!cookiesAccepted) {
          this.dialogService.openDialog(CookieConsentComponent, {
            backdropClass: 'blured-map-background',
            hasBackdrop: true,
            disableClose: true,
            maxWidth: '700px',
            panelClass: 'cookie-consent-dialog',
          });
        } else {
          this.authService.handleMsalLoginRedirect();
        }
      });
  }

  private checkIsUserAllowed() {
    combineLatest([this.isUserLoggedIn$, this.isUserApproved$])
      .pipe(
        untilDestroyed(this),
        tap(([isUserLoggedIn, isUserApproved]) => {
          if (!isUserApproved && isUserLoggedIn) {
            const isUserVnb = this.store.selectSnapshot(
              AppStateSelectors.isUserVNB()
            );
            const isUserAdmin = this.store.selectSnapshot(
              AppStateSelectors.isUserAdmin()
            );
            this.dialogService.showCustomDialog({
              title: 'registration.user_not_allowed_headline',
              content:
                isUserVnb && isUserAdmin
                  ? 'registration.user_not_allowed_vnb'
                  : 'registration.user_not_allowed_customer',
              color: 'INFO',
            });
          }
        })
      )
      .subscribe();
  }

  /* PLEASE KEEP for old PWA's !!*/
  private checkIsAppInstalledVersion() {
    // For iOS || For Android
    if (
      window.navigator['standalone'] ||
      window.matchMedia('(display-mode: standalone)').matches
    ) {
      this.dialogService.showCustomDialog(
        {
          title: 'dialog.app_is_deprecated_title',
          content: 'dialog.app_is_deprecated_content',
          color: 'ERROR',
        },
        {
          width: '100%',
          height: '100%',
          maxWidth: '100vw',
          maxHeight: '100vh',
        }
      );
    }
  }
}
