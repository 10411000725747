import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  APP_ID,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { NgxsModule } from '@ngxs/store';
import { AppRoutesModule } from './app-routes.module';
import { AppComponent } from './app.component';
import { PagesModule } from '@pages/pages.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { BackendInterceptor } from '@services';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env';
import { appStore } from '@appState/app.store';
import { AppInitService } from './app-init.service';
import { ApiModule, Configuration } from '@api';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

export function getApiConfig(): Configuration {
  return new Configuration({
    basePath: environment.apiBasePath,
  });
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/_i18n/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.oauth.clientId,
      authority:
        environment.oauth.b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [environment.oauth.b2cPolicies.authorityDomain],

      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: environment.oauth.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback(logLevel: LogLevel, message: string) {
          console.error(`APP-MSAL: ${message}`);
        },
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
      allowRedirectInIframe: true,
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/karte',
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutesModule,
    FeaturesModule,
    MatSidenavModule,
    NgxsModule.forRoot(appStore, {
      developmentMode: !environment.production,
    }),
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MsalModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ApiModule.forRoot(getApiConfig),
    SharedModule,
    MatButtonToggleModule,
    PagesModule,
    CommonModule,
  ],
  providers: [
    provideCharts(withDefaultRegisterables()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitService.appInitializer,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: APP_ID, useValue: 'oge-h2-connect' },
    { provide: 'Window', useValue: window },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
