import { UnitType } from '../models/unit-type';
import { DataType } from '../models/data-type';

const DASHBOARD_ACTION_TYPE = '[Dashboard]';

export class GetYearlySummary {
  public static type = `${DASHBOARD_ACTION_TYPE} GetYearlySummary`;
}

export class GetDashboardOverviewNetworkArea {
  public static type = `${DASHBOARD_ACTION_TYPE} DashboardOverviewNetworkArea`;
}

export class GetDashboardFacilitiesAndCompanies {
  public static type = `${DASHBOARD_ACTION_TYPE} DashboardFacilitiesAndCompanies`;
}

export class SetDisplayUnit {
  public static type = `${DASHBOARD_ACTION_TYPE} SetDisplayUnit`;
  constructor(public unit: string) {}
}

export class SetActiveUnit {
  public static type = `${DASHBOARD_ACTION_TYPE} SetActiveUnit`;
  constructor(public unit: UnitType) {}
}

export class SetDataType {
  public static type = `${DASHBOARD_ACTION_TYPE} SetDataType`;
  constructor(public dataType: DataType) {}
}

export class GetVnbPlz {
  public static type = `${DASHBOARD_ACTION_TYPE} GetVnbPlz`;
  constructor(public plz: string) {}
}
