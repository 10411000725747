/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TCO2CompanyDTO { 
    company_name?: string | null;
    company_city?: string | null;
    company_street?: string | null;
    company_street_number?: string | null;
    company_zip_code?: string | null;
    company_insert_time?: string | null;
    company_facility_count?: number | null;
    company_quelle_facility_count?: number | null;
    company_senke_facility_count?: number | null;
    company_hybrid_facility_count?: number | null;
}

