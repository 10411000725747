import { enumToObject } from './enums/enum-to-object';

export function filterItemsForSearch(
  arrayOfObjects: any,
  searchColumns: string[],
  value: string,
  optionalSearch?: {
    expectedString: string[];
    expectedEnum: any;
    expectedKey: string;
  }
) {
  return arrayOfObjects.filter(
    (object: { [s: string]: unknown } | ArrayLike<unknown>) => {
      const objectMap = new Map(Object.entries(object));

      for (const key of objectMap.keys()) {
        if (searchColumns.includes(key)) {
          if (
            objectMap.get(key) != null &&
            objectMap
              .get(key)
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          ) {
            return true;
          }

          if (optionalSearch != null) {
            const enumObject = enumToObject(optionalSearch.expectedEnum);
            const enumObjectMap = new Map(Object.entries(enumObject));
            for (const [index, [enumKey, enumValue]] of Array.from(
              enumObjectMap.entries()
            ).entries()) {
              if (
                optionalSearch.expectedString[index].includes(
                  value.trim().toString().toLowerCase()
                ) &&
                objectMap.get(key) == enumObject[enumKey] &&
                key == optionalSearch.expectedKey
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    }
  );
}
