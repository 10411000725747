/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { TCompanySuggestionDTO } from '../model/models';
import { TGetCompanyDTO } from '../model/models';
import { TPutCompanyDTO } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CompanyServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param companyTypId 
     */
    apiCompanyCompanyTypIdGet(companyTypId: number, extraHttpRequestParams?: any): Observable<TCompanySuggestionDTO>;

    /**
     * 
     * 
     */
    apiCompanyGet(extraHttpRequestParams?: any): Observable<TGetCompanyDTO>;

    /**
     * 
     * 
     * @param tPutCompanyDTO 
     */
    apiCompanyPut(tPutCompanyDTO?: TPutCompanyDTO, extraHttpRequestParams?: any): Observable<{}>;

}
