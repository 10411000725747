import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import type { Faq } from './models/faq';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Router } from '@angular/router';
import { AuthGuardService } from '../../features/authentication/services/auth-guard.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {
  @Select(AppStateSelectors.isUserOGE())
  public isUserOGE$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isCo2User$: Observable<boolean>;

  @Select(AppStateSelectors.isUserLoggedIn())
  public isUserLoggedIn$: Observable<boolean>;

  public isCo2Url = false;

  public readonly h2Faq$: Observable<Faq> = this.translate
    .get('faq.h2.categories')
    .pipe(
      map((faqString: string): Faq => ({ categories: JSON.parse(faqString) }))
    );

  public readonly co2Faq$: Observable<Faq> = this.translate
    .get('faq.co2.categories')
    .pipe(
      map((faqString: string): Faq => ({ categories: JSON.parse(faqString) }))
    );

  public constructor(
    public readonly translate: TranslateService,
    private readonly router: Router,
    private readonly authGuardService: AuthGuardService
  ) {}

  public ngOnInit(): void {
    this.isCo2Url = this.router.url.includes('/co2');
    this.authGuardService.authGuardForUrl('faq');
  }
}
