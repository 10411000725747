<form class="co2-form" [formGroup]="form">
  <mat-accordion multi>
    <mat-expansion-panel [expanded]="step == 0"
                         class="oge-expansion-panel register-expansion-panel location-data-container">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'registration.co2.title' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="facilityForm">
        <mat-radio-group attr.aria-label="{{'registration.co2.select_unit' | translate}}"
                         class="radio-container requirement-production-container"
                         formControlName="typeId">
          <mat-radio-button [value]="facilityTypeEnum.Einspeisung">{{ 'registration.co2.production' | translate }}</mat-radio-button>
          <mat-radio-button [value]="facilityTypeEnum.Ausspeisung">{{ 'registration.co2.requirement' | translate }}</mat-radio-button>
          <mat-radio-button [value]="facilityTypeEnum.Hybrid">{{ 'registration.co2.hybrid' | translate }}</mat-radio-button>
        </mat-radio-group>
        <div class="data-container">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.co2.location_name' | translate }}</mat-label>
            <input [maxlength]="maxCharacterLength"
                   [minlength]="minCharacterLength"
                   formControlName="facilityName"
                   matInput
                   type="text">
            <mat-error>{{ checkForErrorsIn(facilityForm.controls.facilityName) | translate }}</mat-error>
          </mat-form-field>
          <app-address-search-autocomplete
            title="{{'registration.co2.address' | translate}}"
            [isCo2]="true">
          </app-address-search-autocomplete>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.co2.first_year_of_transport_capability' | translate }}</mat-label>
            <input [max]="maxFirstYear"
                   [min]="minFirstYear"
                   formControlName="firstYearOfTransportCapability"
                   matInput
                   type="number">
            <mat-error>{{ checkForErrorsIn(facilityForm.controls.firstYearOfTransportCapability) | translate }}</mat-error>
            <mat-hint> {{ 'registration.co2.startyear_min_max' | translate:{min: minFirstYear, max: maxFirstYear} }}</mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.co2.emission' | translate }}</mat-label>
            <mat-select formControlName="emissionTypeId">
              <mat-option *ngFor="let emission of emissionTypeArray" [value]="emission.value">{{ getEmissionLabel(emission.label) | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 1"
                         class="register-expansion-panel consumer-container">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ getTitle() | translate }}</mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form class="co2-table-form" [formGroup]="form">
        <ng-container *ngIf="typeId === facilityCo2Type.Einspeisung || typeId === facilityCo2Type.Hybrid" formArrayName="produce_list">
          <table [dataSource]="dataSource" mat-table>

            <!-- Jahr-Spalte -->
            <ng-container matColumnDef="year">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element" mat-cell> {{ element.year }}</td>
            </ng-container>

            <ng-container matColumnDef="ton">
              <th *matHeaderCellDef mat-header-cell> {{ 'registration.co2.produce.ton' | translate }}</th>
              <td *matCellDef="let element; let index = index" [formGroupName]="index" mat-cell>
                <mat-form-field appearance="fill">
                  <input [errorStateMatcher]="validateExtrenumValueMatcher"
                         [min]="0"
                         formControlName="ton"
                         matInput
                         matInputCommified
                         placeholder="0"
                         type="text">
                  <mat-error>{{ checkForErrorsIn(form.controls.produce_list.controls[index].controls.ton) | translate }}</mat-error>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="hour">
              <th *matHeaderCellDef mat-header-cell> {{ 'registration.co2.produce.hours' | translate }}</th>
              <td *matCellDef="let element; let index = index" [formGroupName]="index" mat-cell>
                <mat-form-field appearance="fill">
                  <input [errorStateMatcher]="validateExtrenumValueMatcher"
                         [max]="maxHours"
                         [min]="0"
                         formControlName="hour"
                         matInput
                         matInputCommified
                         placeholder="0"
                         type="text">
                  <mat-error>{{ checkForErrorsIn(form.controls.produce_list.controls[index].controls.hour) | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="bio_ratio">
              <th *matHeaderCellDef mat-header-cell> {{ 'registration.co2.produce.bio_ratio' | translate }}</th>
              <td *matCellDef="let element; let index = index" [formGroupName]="index" mat-cell>
                <mat-form-field appearance="fill">
                  <input [errorStateMatcher]="validateExtrenumValueMatcher"
                         [max]="maxBioRatio"
                         [min]="0"
                         formControlName="bio_ratio"
                         matInput
                         type="number">
                  <mat-error>{{ checkForErrorsIn(form.controls.produce_list.controls[index].controls.bio_ratio) | translate }}</mat-error>
                </mat-form-field>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
          </table>
        </ng-container>
        <ng-container *ngIf="typeId === facilityCo2Type.Ausspeisung || typeId === facilityCo2Type.Hybrid" formArrayName="consume_list">
          <table [dataSource]="dataSource" mat-table>

            <!-- Jahr-Spalte -->
            <ng-container matColumnDef="year">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element" mat-cell> {{ element.year }}</td>
            </ng-container>
            <ng-container matColumnDef="ton">
              <th *matHeaderCellDef mat-header-cell> {{ 'registration.co2.consume.ton' | translate }}</th>
              <td *matCellDef="let element; let index = index" [formGroupName]="index" mat-cell>
                <mat-form-field appearance="fill">
                  <input [errorStateMatcher]="validateExtrenumValueMatcher"
                         [min]="0"
                         formControlName="ton"
                         matInput
                         matInputCommified
                         placeholder="0"
                         type="text">
                  <mat-error>{{ checkForErrorsIn(form.controls.consume_list.controls[index].controls.ton) | translate }}</mat-error>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="hour">
              <th *matHeaderCellDef mat-header-cell> {{ 'registration.co2.consume.hours' | translate }}</th>
              <td *matCellDef="let element; let index = index" [formGroupName]="index" mat-cell>
                <mat-form-field appearance="fill">
                  <input [errorStateMatcher]="validateExtrenumValueMatcher"
                         [max]="maxHours"
                         [min]="0"
                         formControlName="hour"
                         matInput
                         matInputCommified
                         placeholder="0"
                         type="text">
                  <mat-error>{{ checkForErrorsIn(form.controls.consume_list.controls[index].controls.hour) | translate }}</mat-error>
                </mat-form-field>
              </td>
            </ng-container>
            <ng-container matColumnDef="bio_ratio">

              <th *matHeaderCellDef mat-header-cell> {{ 'registration.co2.consume.bio_ratio' | translate }}</th>
              <td *matCellDef="let element; let index = index" [formGroupName]="index" mat-cell>
                <mat-form-field appearance="fill">
                  <input [errorStateMatcher]="validateExtrenumValueMatcher"
                         [max]="maxBioRatio"
                         [min]="0"
                         formControlName="bio_ratio"
                         matInput
                         type="number">
                  <mat-error>{{ checkForErrorsIn(form.controls.consume_list.controls[index].controls.bio_ratio) | translate }}</mat-error>
                </mat-form-field>
              </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
          </table>
        </ng-container>
      </form>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="step === 2" class="register-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'registration.co2.alternative_transport_title' | translate }}</mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="form">
        <div class="data-container">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.co2.customer_declaration_type_id' | translate }}</mat-label>
            <mat-select formControlName="customerDeclarationTypeId">
              <mat-option *ngFor="let decision of existingConsumerArray" [value]="decision.value">{{ getCustomerDeclarationLabel(decision.label) | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="showCustomerDeclartionInput" appearance="outline">
            <mat-label>{{ 'registration.co2.customer_declaration' | translate }}</mat-label>
            <input [maxlength]="maxCharacterLength"
                   [minlength]="minCharacterLength"
                   formControlName="customerDeclaration"
                   matInput
                   type="text">
            <mat-error>{{ checkForErrorsIn(form.controls.customerDeclaration) | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="data-container">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.co2.transport_target' | translate }}</mat-label>
            <input [maxlength]="maxCharacterLength"
                   [minlength]="minCharacterLength"
                   formControlName="transportTarget"
                   matInput
                   type="text">
            <mat-error>{{ checkForErrorsIn(form.controls.transportTarget) | translate }}</mat-error>
            <app-char-counter [currentLength]="transportTargetControl?.value?.length || 0" [maxLength]="maxCharacterLength"></app-char-counter>
          </mat-form-field>
        </div>
        <div class="data-container">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'registration.co2.alternative_transport_selection' | translate }}</mat-label>
            <mat-select [multiple]="true" formControlName="alternativeTransportTypeId">
              <mat-option *ngFor="let alternativeTransport of alternativeTransportArray"
                          [disabled]="hasNoneSelected && alternativeTransport.value !== alternativeTransportEnum.NONE"
                          [value]="alternativeTransport.value">{{ getAlternativeTypeLabel(alternativeTransport.label) | translate }}
              </mat-option>
            </mat-select>
            <mat-error>{{ checkForErrorsIn(form.controls.alternativeTransportTypeId) | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="showAlternativeTransportCustomInput" appearance="outline">
            <mat-label>{{ 'registration.co2.alternative_transport_other' | translate }}</mat-label>
            <input [maxlength]="maxCharacterLength"
                   [minlength]="minCharacterLength"
                   formControlName="alternativeTransport"
                   matInput
                   type="text">
            <mat-error>{{ checkForErrorsIn(form.controls.alternativeTransport) | translate }}</mat-error>

          </mat-form-field>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="step === 3" class="register-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'registration.co2.facility_description_title' | translate }}</mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="form">
        <p class="facility-description">{{ 'registration.co2.facility_description' | translate }}</p>
        <mat-form-field appearance="outline" class="facility-description-form-field">
          <mat-label>{{ 'registration.co2.facility_description_label' | translate }}</mat-label>
          <textarea class="textarea-facility-description" [maxlength]="maxLengthDescription" [minlength]="minAreaCharacterLength" formControlName="description" matInput></textarea>
          <app-char-counter [currentLength]="descriptionControl?.value?.length || 0" [maxLength]="maxLengthDescription"></app-char-counter>
        </mat-form-field>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</form>
