import { Component, Input } from '@angular/core';
import { VOgeVnb } from '@api';

@Component({
  selector: 'app-vnb-tile',
  templateUrl: './vnb-tile.component.html',
  styleUrls: ['./vnb-tile.component.scss'],
})
export class VnbTileComponent {
  @Input()
  public vnb: VOgeVnb;

  public constructor() {}
}
