/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TH2CompanyDTO } from './tH2CompanyDTO';
import { TCO2CompanyDTO } from './tCO2CompanyDTO';


export interface TCompanyTableDTO { 
    readonly h2_company_list?: Array<TH2CompanyDTO> | null;
    readonly co2_company_list?: Array<TCO2CompanyDTO> | null;
}

