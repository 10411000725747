<div class="user-card-container body1-gotham">
    <div class="user-card" [class]="color">
        <span class="user-circle" [class]="color">
            <span> {{getUserShortName()}}</span>
        </span>
        <div class="user-data">
            <span class="username"
                  [class]="user.user_state_id === userStatus.accepted ? 'color-blue' : color">{{user.user_first_name}} {{user.user_last_name}}</span>
            <span [class]="user.user_state_id === userStatus.accepted ? 'color-copy' : color">{{user.user_company_name}}</span>
        </div>
        <div class="user-role">
            <span class="user-role-circle mini-light-gotham" [class]="color">{{
              (user.user_is_admin
              ? 'administration.user_role.admin'
              : 'administration.user_role.user')
                | translate }}
            </span>
        </div>
        <div class="user-status">
            <mat-icon *ngIf="user.user_state_id === userStatus.accepted" fontIcon="done"></mat-icon>
            <mat-icon *ngIf="user.user_state_id === userStatus.registered" fontIcon="remove"></mat-icon>
            <mat-icon *ngIf="user.user_state_id === userStatus.closed" fontIcon="lock"></mat-icon>
        </div>
        <div class="user-company">
            <span>{{user.user_company_name}}</span>
        </div>
        <div class="user-email">
            <span>{{user.user_mail}}</span>
        </div>
        <ng-container *ngIf="usersCompany$ | async as usersCompany">
            <div class="actions" [class.with-button]="(user.user_state_id === userStatus.registered) || (isUserLockedFromOtherCompany(user, usersCompany.company_name))">
            <span *ngIf="isUserLockedFromOtherCompany(user, usersCompany.company_name)">
                {{'administration.status.user_locked' | translate}}
            </span>
                <button *ngIf="user.user_state_id === userStatus.registered"
                        class="unlock-btn"
                        (click)="changeUserState(user, userStatus.accepted)"
                        color="primary"
                        mat-flat-button>
                    {{'administration.user_status.unlock'| translate}}
                    <mat-icon iconPositionEnd>add_circle</mat-icon>
                </button>
                <button [disabled]="(user.user_state_id === userStatus.registered
                || (isUserLockedFromOtherCompany(user, usersCompany.company_name)))"
                        class="administration-menu-btn more-icon"
                        mat-icon-button
                        [matMenuTriggerFor]="menu">
                    <mat-icon fontIcon="more_horiz"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button *ngIf="user.user_state_id === userStatus.closed"
                            (click)="changeUserState(user, userStatus.accepted)"
                            mat-menu-item>{{'administration.action.unlock_user' | translate}}</button>
                    <!-- Show only when user is accpeted -->
                    <ng-container *ngIf="user.user_state_id === userStatus.accepted">
                        <!-- Company data only for 'me'-->
                        <button *ngIf="(isUserAdmin$ | async) && user.user_id === (loggedInUserId$ | async)"
                                (click)="changeCompanyData()"
                                mat-menu-item>{{'administration.action.change_company_data' | translate}}</button>
                        <ng-container *ngIf="user.user_id !== (loggedInUserId$ | async)">
                            <button (click)="changeUserState(user, userStatus.closed)"
                                    mat-menu-item>{{'administration.action.lock_user' | translate}}</button>
                            <!-- show only when company is "mine"-->
                            <ng-container *ngIf="user.user_company_name === (usersCompany$ | async).company_name">
                                <button *ngIf="!user.user_is_admin"
                                        class="user-to-admin"
                                        (click)="changeUserAdminRole(user, true)"
                                        mat-menu-item>{{'administration.action.user_to_admin' | translate}}</button>
                                <button *ngIf="user.user_is_admin"
                                        class="admin-to-user"
                                        (click)="changeUserAdminRole(user, false)"
                                        mat-menu-item>{{'administration.action.admin_to_user' | translate}}</button>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </mat-menu>
            </div>
        </ng-container>
    </div>
</div>