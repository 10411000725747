import { Injectable, OnDestroy } from '@angular/core';
import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { Store } from '@ngxs/store';
import { MapsHelperService } from '@map/services/maps-helper.service';
import { every } from 'lodash';
import { Event, NavigationEnd, Router } from '@angular/router';
import { FacilityCo2Type, FacilityH2Type } from '@enums';
import { BehaviorSubject } from 'rxjs';
import {
  LoadSelectedFacilityById,
  SetSelectedFacilityId,
} from '@appState/app.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '@services';
import {
  RegisterCo2LocationComponent,
  RegisterH2LocationComponent,
} from '@facility';
import { TCO2FacilityExtendetDTO, TH2FacilityExtendetDTO, VOgeVnb } from '@api';
import { AppStateSelectors } from '@appState/app-state.selector';
import { AppViewEnum } from '../../../../shared/enums/app-view-enum';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class MapsMarkerService implements OnDestroy {
  public readonly markers$ = new BehaviorSubject(null);
  private allMarkers: google.maps.Marker[] = [];
  private markerClusterer: MarkerClusterer;
  private isCo2View: boolean;

  public constructor(
    private readonly store: Store,
    private readonly mapsHelperService: MapsHelperService,
    private readonly dialogService: DialogService,
    private readonly router: Router
  ) {
    this.handleListenerAfterPageChanged();
    this.store
      .select(AppStateSelectors.getAppView())
      .pipe(untilDestroyed(this))
      .subscribe(appView => {
        this.isCo2View = appView === AppViewEnum.CARBON_DIOXIDE;
      });
  }

  public ngOnDestroy(): void {
    this.markerClusterer?.clearMarkers();
    this.allMarkers = [];
    this.markers$.next([]);
    this.markers$.complete();
  }

  public setMarkersVisibility(
    activeDataType: number[],
    googlemap: google.maps.Map
  ) {
    const appView = this.store.selectSnapshot(AppStateSelectors.getAppView());
    const isUserOge = this.store.selectSnapshot(AppStateSelectors.isUserOGE());
    if (this.allMarkers.length > 0 && this.markerClusterer) {
      this.markerClusterer.clearMarkers();

      this.allMarkers.map(m => {
        if (isUserOge && appView === AppViewEnum.HYDROGEN) {
          if (Object.hasOwn(m, 'vnb')) {
            m.setMap(googlemap);
          } else {
            // not VNB markers
            m.setMap(null);
          }
        } else {
          const typeId = Object.hasOwn(m, 'facility')
            ? m['facility'].facility_type_id
            : null;
          if (!activeDataType.includes(typeId)) {
            m.setMap(null);
          } else {
            m.setMap(googlemap);
          }
        }
        return m;
      });

      this.markerClusterer.addMarkers(
        this.allMarkers.filter(m => m.getMap() !== null)
      );
      this.markerClusterer.render();

      // update markers
      this.markers$.next(this.allMarkers);
    }
  }

  public addFacilitiesMarker(
    googlemap: google.maps.Map,
    facilityList: TH2FacilityExtendetDTO[] | TCO2FacilityExtendetDTO[],
    activeDataType: number[]
  ): void {
    if (facilityList) {
      facilityList.forEach(
        (facility: TH2FacilityExtendetDTO | TCO2FacilityExtendetDTO) => {
          this.removeOtherTypeOfMarker('vnb');
          this.removeDeletedFacilities();
          this.removeMarker(facility.facility_id);

          const marker = new google.maps.Marker({
            ...this.getMarkerOptionsByFacility(
              googlemap,
              facility,
              activeDataType
            ),
          });
          marker['id'] = facility.facility_id;
          marker['facility'] = facility;
          this.addInfoWindowAndListenersToMarkerForMapPage(marker, 'facility');

          this.allMarkers.push(marker);
        }
      );
      this.markers$.next(this.allMarkers);
    }
  }

  public addVNBMarker(googlemap: google.maps.Map, vnbs: VOgeVnb[]) {
    if (vnbs) {
      vnbs.forEach(vnb => {
        this.removeOtherTypeOfMarker('facility');
        this.removeMarker(vnb.vnbId);

        const marker = new google.maps.Marker({
          ...this.getMarkerOptionsByVNB(googlemap, vnb),
        });
        marker['id'] = vnb.vnbId;
        marker['vnb'] = vnb;
        this.addInfoWindowAndListenersToMarkerForMapPage(marker, 'vnb');

        this.allMarkers.push(marker);
      });
      this.markers$.next(this.allMarkers);
    }
  }

  private getMarkerOptionsByFacility(
    googlemap: google.maps.Map,
    facility: TH2FacilityExtendetDTO,
    activeDataType: number[]
  ): google.maps.MarkerOptions {
    return {
      clickable: this.router.url.includes('/karte'),
      map: activeDataType.includes(facility.facility_type_id)
        ? googlemap
        : null,
      icon:
        facility.facility_type_id === FacilityH2Type.Consumer ||
        facility.facility_type_id === FacilityCo2Type.Ausspeisung
          ? 'assets/place_consumer.svg'
          : facility.facility_type_id === FacilityCo2Type.Hybrid
            ? 'assets/place_hybrid.svg'
            : 'assets/place_producer.svg',
      position: {
        lat: facility.facility_latitude,
        lng: facility.facility_longitude,
      },
    };
  }

  private getMarkerOptionsByVNB(
    googlemap: google.maps.Map,
    vnb: VOgeVnb
  ): google.maps.MarkerOptions {
    return {
      clickable: this.router.url.includes('/karte'),
      map: googlemap,
      icon: 'assets/place_vnb.svg',
      position: {
        lat: vnb.vnbLocationLongitude,
        lng: vnb.vnbLocationLatitude,
      },
    };
  }

  private removeOtherTypeOfMarker(entityString: string): void {
    this.allMarkers.forEach((m, index) => {
      if (Object.hasOwn(m, entityString)) {
        this.allMarkers.splice(index, 1);
      }
    });
  }

  private removeMarker(searchId: number) {
    if (this.allMarkers.length > 0) {
      // remove marker when exists... so the marker is up-to-date (after edit)
      const markerExistsIndex = this.allMarkers.findIndex(
        m => m['id'] === searchId
      );

      if (markerExistsIndex != -1) {
        this.allMarkers.splice(markerExistsIndex, 1);
      }
    }
  }

  private addInfoWindowAndListenersToMarkerForMapPage(
    marker: google.maps.Marker,
    type: string
  ): void {
    if (this.router.url.includes('/karte')) {
      const data = marker[type];
      const infoWindow = new google.maps.InfoWindow(
        type == 'facility'
          ? this.getFacilityMarkerContent(data)
          : this.getVNBMarkerContent(data)
      );

      marker.addListener('mouseover', () => {
        if (!marker['infowWindowIsOpen']) {
          if (marker.get('map')) {
            infoWindow.open(
              {
                map: marker.get('map'),
                shouldFocus: false,
              },
              marker
            );
            marker['infowWindowIsOpen'] = true;
          }
        }
      });

      marker.addListener('mouseout', () => {
        infoWindow.close();
        marker['infowWindowIsOpen'] = false;
      });
      if (type === 'facility') {
        marker.setClickable(true);
        marker.addListener('click', () => {
          this.store.dispatch(new SetSelectedFacilityId(data.facility_id));
          this.store.dispatch(new LoadSelectedFacilityById());
          if (this.isCo2View) {
            this.dialogService.openRegisterLocation(
              RegisterCo2LocationComponent
            );
          } else {
            this.dialogService.openRegisterLocation(
              RegisterH2LocationComponent
            );
          }
        });
      }
    }
  }

  private getFacilityMarkerContent(
    facility: TH2FacilityExtendetDTO | TCO2FacilityExtendetDTO
  ) {
    let companyName = `</br>${facility?.facility_company_name}`;
    if (Object.values(FacilityCo2Type).includes(facility?.facility_type_id)) {
      const userCompany = this.store.selectSnapshot(
        AppStateSelectors.getUsersCompany()
      );
      if (userCompany?.company_name === facility.facility_company_name) {
        companyName = '';
      }
    }
    return {
      ariaLabel: 'Standort Infomationen',
      minWidth: 200,
      maxWidth: 300,
      disableAutoPan: true,
      content: `
            <p><strong>${facility.facility_name}</strong>
            </br>${facility.facility_street} ${facility.facility_street_number}
            </br>${facility.facility_zip_code} ${facility.facility_city}
            ${companyName}</p>
            `,
    };
  }

  private getVNBMarkerContent(vnb: VOgeVnb) {
    return {
      ariaLabel: 'VNB Infomationen',
      minWidth: 200,
      maxWidth: 300,
      disableAutoPan: true,
      content: `
            <p><strong>${vnb.vnbName}</strong>
            </br>${vnb.vnbLocationStreet} ${vnb.vnbLocationStreetNumber}
            </br>${vnb.vnbLocationZipCode} ${vnb.vnbLocationCity}
            `,
    };
  }

  public addMarkerCluster(
    googlemap: google.maps.Map,
    withFitBounds?: boolean
  ): void {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }

    if (this.allMarkers.length > 0) {
      this.markerClusterer = new MarkerClusterer({
        map: googlemap,
        markers: this.allMarkers,
        renderer: {
          render({ count, position, markers }): Marker {
            let url = '/assets/cluster_mixed.svg';
            if (every(markers, 'vnb')) {
              url = '/assets/cluster_vnb.svg';
            } else {
              const markersAreConsumers = every(markers, [
                'icon',
                'assets/place_consumer.svg',
              ]);
              const markersAreProducers = every(markers, [
                'icon',
                'assets/place_producer.svg',
              ]);
              if (markersAreConsumers) {
                url = '/assets/cluster_consumer.svg';
              } else if (markersAreProducers) {
                url = '/assets/cluster_producer.svg';
              }
            }

            const marker = new google.maps.Marker({
              visible: count > 1,
              icon: {
                url,
              },
              position,
              label: String(count),
              zIndex: 999,
            });

            /* when marker clickable (map page) show cluster infos */
            if ((markers[0] as google.maps.Marker).getClickable()) {
              const content = markers
                .map(m => {
                  if (Object.hasOwn(m, 'facility')) {
                    return (m as google.maps.Marker)['facility'].facility_name;
                  }
                  if (Object.hasOwn(m, 'vnb')) {
                    return (m as google.maps.Marker)['vnb'].vnbName;
                  }
                })
                .join(',</br>');
              const infoWindow = new google.maps.InfoWindow({
                ariaLabel: 'Cluster Informationen',
                minWidth: 200,
                disableAutoPan: true,
                content: `<p>${content}</p>`,
              });

              marker.addListener('mouseover', () => {
                infoWindow.open(
                  {
                    map: googlemap,
                    shouldFocus: false,
                  },
                  marker
                );
              });

              marker.addListener('mouseout', () => {
                infoWindow.close();
              });
            }
            return marker;
          },
        },
      });
      this.markers$.next(this.allMarkers);
    }

    if (withFitBounds) {
      this.fitBoundsForMarker(googlemap);
    }
  }

  private fitBoundsForMarker(googlemap): void {
    const latLngArray: google.maps.LatLngLiteral[] = [];
    this.allMarkers.forEach(m => {
      latLngArray.push({
        lat: m.getPosition().lat(),
        lng: m.getPosition().lng(),
      });
    });
    this.mapsHelperService.setFocus(googlemap, null, latLngArray);
  }

  private handleListenerAfterPageChanged(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (routerEvent: Event): routerEvent is NavigationEnd =>
            routerEvent instanceof NavigationEnd
        )
      )
      .subscribe(result => {
        this.allMarkers.forEach(m => {
          if (result.url.includes('/karte')) {
            this.addInfoWindowAndListenersToMarkerForMapPage(m, 'facility');
          } else {
            this.resetMarkerListeners(m);
          }
        });
        this.markers$.next(this.allMarkers);
      });
  }

  private resetMarkerListeners(marker: google.maps.Marker) {
    marker.setClickable(false);
    marker.addListener('mouseover', () => {});
    marker.addListener('mouseout', () => {});
    marker.addListener('click', () => {});
  }

  private removeDeletedFacilities(): void {
    const allFacilities: TCO2FacilityExtendetDTO[] | TH2FacilityExtendetDTO[] =
      this.store.selectSnapshot(AppStateSelectors.getFacilityList());

    this.allMarkers.forEach((m, index) => {
      const foundedFacility = allFacilities.find(
        faci => faci.facility_id === m['id']
      );
      if (foundedFacility === undefined) {
        this.allMarkers.splice(index, 1);
      }
    });
  }
}
