/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TGetFacilty02DTO { 
    facility_name?: string | null;
    facility_type_id?: number;
    facility_city?: string | null;
    facility_zip_code?: string | null;
    facility_insert_time?: string | null;
    facility_update_time?: string | null;
}

