import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DefaultUnitTypeEnum,
  H2FacilityForm,
  RegisterH2FacilityForm,
  UnitForm,
} from '../../../../interfaces/register_location';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FacilityH2Type } from '@enums';

@UntilDestroy()
@Component({
  selector: 'app-register-h2-years-form',
  templateUrl: './register-h2-years-form.component.html',
  styleUrls: ['./register-h2-years-form.component.scss'],
})
export class RegisterH2YearsFormComponent {
  @Input()
  public form: FormGroup<RegisterH2FacilityForm>;
  @Input()
  public years: number[];
  @Output()
  public setPage: EventEmitter<1 | 2> = new EventEmitter<1 | 2>();
  public readonly facilityTypeEnum = FacilityH2Type;
  public readonly defaultUnitTypeEnum = DefaultUnitTypeEnum;

  public get maloIdControl(): FormControl {
    return this.form?.controls.facility.controls.maloId;
  }

  public get facilityForm(): FormGroup<H2FacilityForm> {
    return this.form?.controls.facility as FormGroup;
  }

  public get capacityForm(): FormGroup<UnitForm> {
    return this.form?.controls.capacity as FormGroup;
  }

  public get energyForm(): FormGroup<UnitForm> {
    return this.form?.controls.energy as FormGroup;
  }

  public get capacityYearsForm(): FormGroup {
    return this.capacityForm?.controls.years;
  }

  public get energyYearsForm(): FormGroup {
    return this.energyForm?.controls.years;
  }

  public getControlIsInvalid(year: number): boolean {
    return this.energyYearsForm.get(year.toString()).invalid;
  }
}
