/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { TCreateDokumentDTO } from '../model/models';
import { TDokumentDTO } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface DocumentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param companyId 
     * @param documentTypeId 
     */
    apiDokumenteDocumentCompanyIdDocumentTypeIdGet(companyId: number, documentTypeId: number, extraHttpRequestParams?: any): Observable<TDokumentDTO>;

    /**
     * 
     * 
     * @param documentTypeId 
     */
    apiDokumenteDocumentDocumentTypeIdGet(documentTypeId: number, extraHttpRequestParams?: any): Observable<TDokumentDTO>;

    /**
     * 
     * 
     * @param documentTypeId 
     * @param tCreateDokumentDTO 
     */
    apiDokumenteDocumentDocumentTypeIdPut(documentTypeId: number, tCreateDokumentDTO?: TCreateDokumentDTO, extraHttpRequestParams?: any): Observable<{}>;

}
