<div class="row">
    <h4>{{'registration.zip_code_area' | translate}}
        <mat-icon fontIcon="info_outline"
                  class="mat-icon-headline"
                  matTooltip="{{'registration.zip_code_area_tooltip' | translate}}"
                  matTooltipPosition="right"
        ></mat-icon>
    </h4>
    <button mat-flat-button
            type="button"
            class="clear-zip-code body1-gotham"
            (click)="clearZipCodeArea()">
        {{'registration.clear_zip_area' | translate}}
        <mat-icon>delete</mat-icon>
    </button>
</div>
<div class="split-pane">
    <div class="add-zip-container">
        <mat-form-field color="accent" appearance="outline">
            <mat-label>{{'registration.add_zip_code' | translate}}*</mat-label>
            <input [formControl]="zipCodeFormControl"
                   matInput
                   (keydown.enter)="addZipCode()"
                   minLength="5"
                   maxLength="5"
                   [pattern]="NUMBER_REGEX"
                   type="text"
            >
            <button *ngIf="zipCodeFormControl.getRawValue() !== ''"
                    mat-icon-button matSuffix
                    type="button"
                    attr.aria-label="{{'registration.company_add_zip_code' | translate}}" (click)="addZipCode()">
                <mat-icon  class="zip-add-icon">add</mat-icon>
            </button>
            <mat-error *ngIf="zipCodeFormControl.hasError('duplicateZipCode')">{{'registration.company_zip_code_error_duplicate' | translate}}</mat-error>
            <mat-error *ngIf="zipCodeFormControl.hasError('invalid')">{{'registration.company_zip_code_error_invalid' | translate}}</mat-error>
        </mat-form-field>

        <app-file-upload [acceptedFileType]="['csv']" (selectedFile)="onSelectedZipCodeFile($event)"></app-file-upload>
    </div>
    <div class="zip-code-container">
        <div class="zip-code-container-button">
                    <span *ngIf="zipCodeAreaControl.getRawValue().length === 0"
                          class="text-centered" >
                        {{'registration.company_empty_zip_area' | translate}}
                    </span>
            <ng-container *ngFor="let zip of zipCodeAreaControl.getRawValue(); index as i">
                <span class="zip-code-button color-blue">
                    {{ zip }}
                    <button mat-icon-button type="button" (click)="removeZipCode(i)">
                    <mat-icon fontIcon="cancel" attr.aria-label="{{'registration.company_remove_zip_code' | translate}}"></mat-icon>
                </button>
                </span>
            </ng-container>
        </div>
    </div>
</div>