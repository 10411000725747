<app-content-page>
  <div class="data_table table">
    <div class="table-header">
      <div *ngIf="isEmptyValue; else searchHeadline" class="headlines">
        <h1>{{ 'data_table.headline'| translate }}</h1>
        <h2>{{ 'data_table.subheadline' | translate }}</h2>
      </div>
      <ng-template #searchHeadline>
        <div class="search-headlines headlines">
          <h2>{{
              'search.headline' | translate:{
                search: searchControl.value, count:
                  (this.companyTable?.length ?? 0) +
                  (this.facilityTable?.length ?? 0) +
                  (this.vnbTable?.length ?? 0)
              }
            }}
          </h2>
        </div>
      </ng-template>
      <div class="header-actions">
        <button (click)="exportDataAsCSV()" class="body1-gotham" mat-button>
          {{ 'table.export_csv' | translate }}
          <mat-icon fontIcon="file_download" iconPositionEnd></mat-icon>
        </button>
        <mat-form-field appearance="outline" color="accent">
          <mat-label class="body1-gotham">{{ 'data_table.search.placeholder' | translate }}</mat-label>
          <input [formControl]="searchControl"
                 matInput
                 type="text"
          />
          <mat-icon (click)="searchControl.value.length > 0 ? resetSearch() : null" *ngIf="searchControl.value?.length > 0" [class.reset]="searchControl.value"
                    matIconSuffix>close
          </mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="table-content">
      <mat-accordion multi>
        <app-loading-spinner *ngIf="isLoading$ | async"></app-loading-spinner>

        <!--COMPANY TABLE FOR VNB AND NOT OGE NOT CUSOMTER-->
        <app-date-table-expansion-panel (sortFunction)="companySort($event)"
                                        *ngIf="(isVNB$ | async) && (isUserOGE$ | async) === false"
                                        [expanded]="(getTableType$ | async) === dataTableType.company || (companyTable?.length > 0 && (getTableType$ | async) === null)"
                                        [hasActions]="true"
                                        [isVNB]="isVNB$ | async"
                                        [tableHeaders]="vnbCompanyTable"
                                        [title]="'data_table.company' | translate:{count: companyTable?.length ?? 0}"
                                        headerClass="h2-company"
        >
          <ng-container *ngIf="companyTable?.length > 0; else emptyData">
            <div *ngFor="let company of companyTable" class="mat-row oge-tiles">
              <app-h2-company-tile [company]="company" class="mat-cell"></app-h2-company-tile>
            </div>
          </ng-container>
        </app-date-table-expansion-panel>

        <!--FACILITY TABLE FOR VNB AND H2 CUSOMTER-->
        <app-date-table-expansion-panel (sortFunction)="facilitySort($event)"
                                        *ngIf="(isUserOGE$ | async) === false && (isUserCo2Customer$ | async) === false"
                                        [expanded]="(getTableType$ | async) === dataTableType.facility || (facilityTable?.length > 0 && (getTableType$ | async) === null)"
                                        [hasActions]="true"
                                        [isVNB]="isVNB$ | async"
                                        [tableHeaders]="(isVNB$ | async) ? vnbH2FacilityTable : customerH2FacilityTable"
                                        [title]="'data_table.facility' | translate:{count: facilityTable?.length ?? 0}"
                                        headerClass="h2-facility"
        >
          <ng-container *ngIf="facilityTable?.length > 0; else emptyData">
            <div *ngFor="let facility of facilityTable" class="mat-row oge-tiles">
              <app-h2-facility-tile [facility]="facility" class="mat-cell"></app-h2-facility-tile>
            </div>
          </ng-container>
        </app-date-table-expansion-panel>


        <!--FACILITY TABLE FOR CO2 CUSOMTER-->
        <app-date-table-expansion-panel (sortFunction)="facilitySort($event)"
                                        *ngIf="(isUserOGE$ | async) === false && (isUserCo2Customer$ | async)"
                                        [expanded]="(getTableType$ | async) === dataTableType.facility || (facilityTable?.length > 0 && (getTableType$ | async) === null)"
                                        [hasActions]="true"
                                        [tableHeaders]="co2CustomerTable"
                                        [title]="'data_table.facility' | translate:{count: facilityTable?.length ?? 0}"
                                        headerClass="co2-facility"
        >
          <ng-container *ngIf="facilityTable?.length > 0; else emptyData">
            <div *ngFor="let facility of facilityTable" class="mat-row oge-tiles">
              <app-co2-facility-tile [facility]="facility" class="mat-cell"></app-co2-facility-tile>
            </div>
          </ng-container>

        </app-date-table-expansion-panel>

        <!--VNB TABLE FOR OGE NOT CO2-->
        <app-date-table-expansion-panel (sortFunction)="vnbSort($event)"
                                        *ngIf="(isUserOGE$ | async) && (isAppViewCo2$ | async) === false"
                                        [expanded]="(getTableType$ | async) === dataTableType.vnb || (vnbTable?.length > 0 && (getTableType$ | async) === null)"
                                        [hasActions]="false"
                                        [tableHeaders]="ogeVNBTable"
                                        [title]="'data_table.vnb' | translate:{count: vnbTable?.length ?? 0}"
                                        headerClass="vnb"
        >
          <ng-container *ngIf="vnbTable?.length > 0; else emptyData">
            <div *ngFor="let vnb of vnbTable" class="mat-row oge-tiles">
              <app-vnb-tile [vnb]="vnb" class="mat-cell"></app-vnb-tile>
            </div>
          </ng-container>
        </app-date-table-expansion-panel>

        <!--FACILITY TABLE FOR OGE CO2-->
        <app-date-table-expansion-panel (sortFunction)="facilitySort($event)"
                                        *ngIf="(isUserOGE$ | async) && (isAppViewCo2$ | async)"
                                        [expanded]="(getTableType$ | async) === dataTableType.facility || (facilityTable?.length > 0 && (getTableType$ | async) === null)"
                                        [hasActions]="false"
                                        [tableHeaders]="ogeFacilityCo2Table"
                                        [title]="'data_table.facility' | translate:{count: facilityTable?.length ?? 0}"
                                        headerClass="co2-facility"
        >
          <ng-container *ngIf="facilityTable?.length > 0; else emptyData">
            <div *ngFor="let facility of facilityTable" class="mat-row oge-tiles">
              <app-co2-facility-tile [facility]="facility" class="mat-cell"></app-co2-facility-tile>
            </div>
          </ng-container>
        </app-date-table-expansion-panel>


        <!--COMPANY TABLE FOR OGE CO2-->
        <app-date-table-expansion-panel (sortFunction)="companySort($event)"
                                        *ngIf="(isUserOGE$ | async) && (isAppViewCo2$ | async)"
                                        [expanded]="(getTableType$ | async) === dataTableType.company || (companyTable?.length > 0 && (getTableType$ | async) === null)"
                                        [hasActions]="false"
                                        [tableHeaders]="ogeCompanyCo2Table"
                                        [title]="'data_table.company' | translate:{count: companyTable?.length ?? 0}"
                                        headerClass="co2-company"
        >
          <ng-container *ngIf="companyTable?.length > 0; else emptyData">
            <div *ngFor="let company of companyTable" class="mat-row oge-tiles">
              <app-co2-company-tile [company]="company" class="mat-cell"></app-co2-company-tile>
            </div>
          </ng-container>
        </app-date-table-expansion-panel>


        <ng-template #emptyData>
          <span *ngIf="(isLoading$|async) === false">{{ 'app.data_not_found' | translate }}</span>
        </ng-template>
      </mat-accordion>
    </div>
  </div>
</app-content-page>
