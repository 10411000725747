import { AppState } from '@appState/app.state';
import { DashboardState } from '@pages/dashboard/state/dashboard.state';
import { MapState } from '@pages/map/state/map.state';
import { AppStateModel } from '@appState/app-state.model';
import { DashboardStateModel } from '@pages/dashboard/state/dashboard-state.model';
import { MapStateModel } from '@pages/map/state/map-state.model';

export interface AppStoreModel {
  appState: AppStateModel;
  dashboardState: DashboardStateModel;
  mapState: MapStateModel;
}
export const appStore = [AppState, DashboardState, MapState];
