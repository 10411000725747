import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import {
  LoadFacilityList,
  LoadSelectedFacilityById,
} from '@appState/app.actions';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable } from 'rxjs';
import {
  FacilityService,
  TDokumentDTO,
  TGetH2FacilityDTO,
  TH2FacilityExtendetDTO,
  TNameundIdDTO,
  TPutFacilityDTO,
} from '@api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DashboardSelectors } from '@pages/dashboard/state/dashboard.selectors';
import { ErrorHandlingService, LegalService } from '@services';
import { switchMap, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { RegisterFacilityFormSmall } from '@facility';
import { GetVnbPlz } from '@pages/dashboard/state/dashboard.actions';
import { RegistrationService } from '@registration/services/registration.service';
import { UnitTypeForBackendEnum } from '@enums';

@UntilDestroy()
@Component({
  selector: 'app-dialog-vnb-plz',
  templateUrl: './dialog-vnb-plz.component.html',
  styleUrls: ['./dialog-vnb-plz.component.scss'],
})
export class DialogVnbPlzComponent implements OnInit {
  public form: FormGroup<RegisterFacilityFormSmall>;

  @Select(AppStateSelectors.getSelectedFacility())
  public readonly facility$: Observable<TH2FacilityExtendetDTO>;

  @Select(DashboardSelectors.getVNBList)
  public readonly vnbs$: Observable<TNameundIdDTO[]>;

  public vnbPolicy: TDokumentDTO;

  private _facility: TGetH2FacilityDTO;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly registrationService: RegistrationService,
    private readonly legalService: LegalService,
    private readonly facilityService: FacilityService,
    private readonly errorHandlingService: ErrorHandlingService,
    public dialogRef: MatDialogRef<DialogVnbPlzComponent>
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new LoadSelectedFacilityById());

    this.form = this.formBuilder.group({
      facility: this.formBuilder.group({
        companyAddress: this.registrationService.buildAddressForm(),
      }),
      vnb: [0, [Validators.required, Validators.min(1)]],
      legal: [false, [Validators.requiredTrue]],
      policy: [false],
    });

    this.fillFormWithData();

    this.form
      .get('vnb')
      .valueChanges.pipe(
        untilDestroyed(this),
        switchMap(vnb => {
          return this.registrationService.getPolicy(vnb);
        }),
        tap((policy: TDokumentDTO) => {
          this.vnbPolicy = policy;
          if (this.vnbPolicy) {
            this.form.get('policy')?.setValidators(Validators.requiredTrue);
          } else {
            this.form.get('policy')?.clearValidators();
          }
        })
      )
      .subscribe();
  }

  public onSave(): void {
    const vnbId = this.form.get('vnb').getRawValue();
    const data: TPutFacilityDTO = {
      h2_facility: {
        ...this._facility,
        facility_plan: {
          facility_unit_typ_for_power_array: UnitTypeForBackendEnum.KG_H,
          facility_unit_typ_for_energy_array: UnitTypeForBackendEnum.KG,
          facility_values_power:
            this._facility.facility_plan.facility_values_power_kg,
          facility_values_energy:
            this._facility.facility_plan.facility_values_energy_kg,
        },
        facility_latitude: this._facility.facility_longitude,
        facility_longitude: this._facility.facility_latitude,
        facility_related_vnb_id: vnbId,
      },
    };
    this.facilityService.apiFacilityPut(data).subscribe({
      next: () => {
        this.dialogRef.close();
        this.store.dispatch(new LoadFacilityList());
      },
      error: err => this.errorHandlingService.showError(err),
    });
  }

  public openPolicy(): void {
    this.legalService.openPDF(this.vnbPolicy.document_string);
  }

  public hasPolicy(): boolean {
    return !!this.vnbPolicy;
  }

  private fillFormWithData(): void {
    this.facility$.pipe(untilDestroyed(this)).subscribe(result => {
      if (result) {
        this._facility = result;
        this.form.patchValue({
          facility: {
            companyAddress: {
              address: '',
              street: result.facility_street,
              streetNumber: result.facility_street_number,
              zipCode: result.facility_zip_code,
              city: result.facility_city,
              lat: result.facility_latitude,
              lng: result.facility_longitude,
            },
          },
          vnb: result.facility_related_vnb_id,
        });
        this.store.dispatch(new GetVnbPlz(result.facility_zip_code));
      }
    });
  }
}
