import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { DashboardState } from './dashboard.state';
import type { DashboardStateModel } from './dashboard-state.model';
import type { H2YearlySummaryGraphDataModel } from '../models/h2-yearly-summary-graph-data.model';
import {
  TDashboardOverviewNetworkDTO,
  TNameundIdDTO,
  TNewRegistrationDTO,
} from '@api';
import { ActionStateModel } from '@interfaces';
import { Co2YearlySummaryGraphDataModel } from '@pages/dashboard/models/Co2YearlySummaryGraphDataModel';

export interface NetworkArea {
  readonly maxCount: number;
  readonly customers: {
    readonly count: number;
    readonly percentage: number;
  };
  readonly demand: {
    readonly count: number;
    readonly percentage: number;
  };
  readonly locations: {
    readonly count: number;
    readonly percentage: number;
  };
  readonly production: {
    readonly count: number;
    readonly percentage: number;
  };
  readonly both: {
    readonly count: number;
    readonly percentage: number;
  };
}

@Injectable()
export class DashboardSelectors {
  @Selector([DashboardState])
  public static getGraphData(
    state: DashboardStateModel
  ):
    | H2YearlySummaryGraphDataModel
    | Co2YearlySummaryGraphDataModel
    | undefined {
    return state.graphData;
  }

  @Selector([DashboardState])
  public static getActionState(state: DashboardStateModel): ActionStateModel {
    return state.actionState;
  }

  @Selector([DashboardState])
  public static getNetworkAreaOverview(
    state: DashboardStateModel
  ): NetworkArea | undefined {
    const networkArea: TDashboardOverviewNetworkDTO | undefined =
      state.networkAreaOverview;

    if (!networkArea) {
      return undefined;
    }

    const maxCount: number = Math.max(
      ...[
        networkArea.amount_customers,
        networkArea.amount_demand,
        networkArea.amount_locations,
        networkArea.amount_production,
      ]
    );

    const getPercentage: (count: number) => number = (count: number) =>
      (count / maxCount) * 100;

    return {
      maxCount,
      customers: {
        count: networkArea.amount_customers,
        percentage: getPercentage(networkArea.amount_customers),
      },
      demand: {
        count: networkArea.amount_demand,
        percentage: getPercentage(networkArea.amount_demand),
      },
      locations: {
        count: networkArea.amount_locations,
        percentage: getPercentage(networkArea.amount_locations),
      },
      production: {
        count: networkArea.amount_production,
        percentage: getPercentage(networkArea.amount_production),
      },
      both: {
        count: networkArea.amount_both,
        percentage: getPercentage(networkArea.amount_both),
      },
    };
  }

  @Selector([DashboardState])
  public static getDisplayUnit(state: DashboardStateModel): string {
    return state.displayUnit === undefined ? 'kg' : state.displayUnit;
  }

  @Selector([DashboardState])
  public static getActiveUnit(state: DashboardStateModel): string {
    return state.activeUnitType;
  }

  @Selector([DashboardState])
  public static getActiveDataType(state: DashboardStateModel): string {
    return state.activeDataType;
  }

  @Selector([DashboardState])
  public static getVNBList(state: DashboardStateModel): TNameundIdDTO[] {
    return state.vnbList;
  }

  @Selector([DashboardState])
  public static getFacilitiesAndCompanies(
    state: DashboardStateModel
  ): TNewRegistrationDTO {
    return state.facilitiesAndCompanies;
  }
}
