import { DataTableType } from '../../../shared/enums/data-table-type-enum';
import { EExportBrille } from '@api';

const DATA_VIEW_ACTION_TYPE = '[DataView]';

export class GetCompanyTable {
  public static type = `${DATA_VIEW_ACTION_TYPE} GetCompanyTable`;
}

export class SetActiveTable {
  public static type = `${DATA_VIEW_ACTION_TYPE} setActiveTable`;

  constructor(public type: DataTableType) {}
}

export class ExportFacilityTable {
  public static type = `${DATA_VIEW_ACTION_TYPE} exportFacilityTable`;
}

export class ExportCompanyTable {
  public static type = `${DATA_VIEW_ACTION_TYPE} exportCompanyTable`;

  constructor(public readonly glasses?: EExportBrille) {}
}

export class OGEExportVNB {
  public static type = `${DATA_VIEW_ACTION_TYPE} OGEExportVNB`;
}

export class OGEExportVNBSummary {
  public static type = `${DATA_VIEW_ACTION_TYPE} OGEExportVNBSummary`;
}

export class LoadVNBDisplayData {
  public static type = `${DATA_VIEW_ACTION_TYPE} LoadVNBDisplayData`;
}
