import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { HydrogenRegistrationService } from '@registration/components/hydrogen/hydrogen-registration.service';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { HydrogenUserRegistrationFormModel } from '@registration/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-customer-registration',
  templateUrl: './customer-registration.component.html',
  styleUrls: ['./customer-registration.component.scss'],
})
export class CustomerRegistrationComponent implements OnInit {
  public form: FormGroup<HydrogenUserRegistrationFormModel>;

  public get currentPage(): string {
    return this.hydrogenService.currentPage;
  }

  public get companyDataForm(): FormGroup {
    return this.form.controls.companyData;
  }

  constructor(
    private readonly hydrogenService: HydrogenRegistrationService,
    private readonly parentFormGroup: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
  }
}
