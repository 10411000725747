<div class="company-card-container">
  <div class="company-card">
    <div class="company_name">
      <span class="name"> {{ company.company_name }}</span>
    </div>
    <div class="company_address">
      <div class="color-copy">{{ company.company_street }} {{ company.company_street_number }}</div>
      <div class="color-copy">{{ company.company_zip_code }} {{ company.company_city }}</div>
    </div>
    <div class="company_facilty_count center">
      {{ company.company_facility_count }}
    </div>
    <div class="company_einspeisung_count center">
      <span>{{ company.company_quelle_facility_count }}</span>
    </div>
    <div class="company_ausspeisung_count center">
      <span>{{ company.company_senke_facility_count }}</span>
    </div>
    <div class="company_hybrid_count center">
      <span>{{ company.company_hybrid_facility_count }}</span>
    </div>
  </div>
</div>
