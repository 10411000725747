import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { HydrogenRegistrationService } from '@registration/components/hydrogen/hydrogen-registration.service';
import { AppStateSelectors } from '@appState/app-state.selector';
import { TGetH2Company } from '@api';

@Component({
  selector: 'app-hydrogen-header',
  templateUrl: './hydrogen-header.component.html',
  styleUrls: ['./hydrogen-header.component.scss'],
})
export class HydrogenHeaderComponent {
  @Select(AppStateSelectors.getUsersCompany())
  public usersCompanyData$: Observable<TGetH2Company>;

  public get currentPage(): string {
    return this.hydrogenRegistrationService.currentPage;
  }
  public get domainIsNotExists(): boolean {
    return this.hydrogenRegistrationService.domainIsNotExists;
  }

  constructor(
    private readonly hydrogenRegistrationService: HydrogenRegistrationService
  ) {}

  public backToStart(): void {
    this.hydrogenRegistrationService.backToStart();
  }
}
