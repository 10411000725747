import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-customer-vnb-registration',
  templateUrl: './customer-vnb-registration.component.html',
  styleUrls: ['./customer-vnb-registration.component.scss'],
})
export class CustomerVnbRegistrationComponent implements OnInit {
  public form: FormGroup;
  public imagesHasError = false;
  private fileReader = new FileReader();

  public constructor(
    private readonly parentFormGroup: FormGroupDirective,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
  }

  public isCompanyAlreadyExists(): boolean {
    const companyData = this.store.selectSnapshot(
      AppStateSelectors.getUsersCompany()
    );
    if (companyData) {
      return companyData.company_id !== undefined;
    }
    return false;
  }

  public onSelectedCompanyLogo(file: File): void {
    this.fileReader.onload = () => {
      const img = new Image();
      if (this.fileReader.result) {
        img.src = this.fileReader.result.toString();
        img.onload = () => {
          this.imagesHasError =
            img.width < 50 ||
            img.width > 500 ||
            img.height < 50 ||
            img.height > 500;

          if (!this.imagesHasError) {
            const base64StringFile = this.fileReader.result
              ?.toString()
              .replace(/^data:(.*,)?/, '');
            this.form.get('fileLogo')?.setValue(base64StringFile);
          }
        };
      }
    };
    this.fileReader.readAsDataURL(file);
  }
}
