import { Injectable, TemplateRef } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { CustomDialogComponent, CustomDialogModel } from '@components';

@Injectable()
export class DialogService {
  private _customDialogRef: MatDialogRef<CustomDialogComponent>;

  constructor(private readonly dialog: MatDialog) {}

  public openDialog<T>(
    component: ComponentType<T> | TemplateRef<T>,
    option: MatDialogConfig
  ): MatDialogRef<T> {
    const dialogExists = this.dialog.openDialogs.find(d => d.id === option.id);
    if (dialogExists) {
      return dialogExists;
    } else {
      return this.dialog.open(component, {
        ...option,
      });
    }
  }

  public openRegisterLocation<T>(
    component: ComponentType<T> | TemplateRef<T>
  ): void {
    // @TODO find better solution? import RegisterLocationComponent is fatal
    this.openDialog(component, {
      id: 'register-location-dialog-id',
      backdropClass: 'blurred',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'form-dialog',
    });
  }

  public showCustomDialog(
    customData?: CustomDialogModel,
    additionalOptions?: MatDialogConfig
  ) {
    if (!this._customDialogRef) {
      this._customDialogRef = this.openDialog(CustomDialogComponent, {
        data: customData ? customData : null,
        panelClass: 'custom-dialog',
        hasBackdrop: false,
        disableClose: true,
        minWidth: '400px',
        maxWidth: '600px',
        minHeight: '150px',
        ...additionalOptions,
      });
      if (this._customDialogRef) {
        this._customDialogRef
          .afterClosed()
          .subscribe(() => (this._customDialogRef = null));
      }
    }
  }
}
