<form class="actions" [formGroup]="form">
    <div class="legal">
        <form [formGroup]="legalFormGroup">
            <div class="terms-conditions-policy-container">
                <div class="checkbox-href-container default-text">
                    <mat-checkbox color="accent" formControlName="legal"></mat-checkbox>
                    <mat-label class="color-copy">
                        {{ 'registration.terms_policy_start' | translate }}
                        <button (click)="openProceduralRules()" class="button-as-Link medium" data-title="Co2-AGB">{{ 'registration.co2_terms' | translate }}</button>
                        &
                        <button (click)="openTerms()" class="button-as-Link medium" data-title="AGB">{{ 'registration.terms' | translate }}</button>
                        {{ 'registration.terms_policy_end' | translate }}
                    </mat-label>
                </div>
                <div class="checkbox-href-container default-text">
                    <mat-checkbox color="accent" formControlName="policy"></mat-checkbox>
                    <mat-label class="color-copy">
                        {{ 'registration.terms_policy_start' | translate }}
                        <button (click)="openPolicy()" class="button-as-Link medium">{{ 'registration.policy' | translate }}</button>
                        {{ 'registration.terms_policy_end' | translate }}
                    </mat-label>
                </div>
            </div>
        </form>
    </div>
    <button (click)="onCo2Registration()"
            [disabled]="form?.invalid"
            class="button-gotham"
            color="accent"
            mat-flat-button
            type="submit">
        {{ 'registration.register' | translate }}
    </button>
</form>