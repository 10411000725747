import { Component, Input } from '@angular/core';
import { FacilityCo2Type, FacilityH2Type } from '@enums';

@Component({
  selector: 'app-facility-type-badge',
  templateUrl: './facility-type-badge.component.html',
  styleUrls: ['./facility-type-badge.component.scss'],
})
export class FacilityTypeBadgeComponent {
  @Input()
  public typeId: number;
  @Input()
  public isCo2!: boolean | null;

  public getTypeIdString() {
    return this.isCo2
      ? Object.keys(FacilityCo2Type)
          .find(key => FacilityCo2Type[key] === this.typeId)
          .toLowerCase()
      : Object.keys(FacilityH2Type)
          .find(key => FacilityH2Type[key] === this.typeId)
          .toLowerCase();
  }
}
