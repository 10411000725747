/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TPutH2Company } from './tPutH2Company';
import { TPutCO2Company } from './tPutCO2Company';


export interface TPutCompanyDTO { 
    h2_company?: TPutH2Company;
    co2_company?: TPutCO2Company;
}

