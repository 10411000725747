import { Component, Input } from '@angular/core';
import { TH2CompanyDTO } from '@api';

@Component({
  selector: 'app-h2-company-tile',
  templateUrl: './h2-company-tile.component.html',
  styleUrls: ['./h2-company-tile.component.scss'],
})
export class H2CompanyTileComponent {
  @Input()
  public company: TH2CompanyDTO;
}
