import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { environment } from '@env';
import { NumericService } from '@services';

@Directive({
  selector: 'input[matInputCommified]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: MatInputCommifiedDirective,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputCommifiedDirective),
      multi: true,
    },
  ],
})
export class MatInputCommifiedDirective {
  @Input() isInteger: boolean = false;
  private replaceIntegerRegex = /[^\d-]/g;
  private replaceFloatRegex = /[^\d.,-]/g;
  private replaceString = environment.defaultLang === 'de' ? '.' : ',';

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private readonly numericService: NumericService
  ) {}

  // tslint:disable-next-line:variable-name
  private _value: string | null;

  get value(): string | null {
    return this._value;
  }

  @Input()
  set value(value: string | null) {
    this._value = value?.replace(
      this.isInteger ? this.replaceIntegerRegex : this.replaceFloatRegex,
      ''
    );
    this.unFormatValue();
    this.formatValue();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._value = value.replace(
      this.isInteger ? this.replaceIntegerRegex : this.replaceFloatRegex,
      ''
    );
    this.elementRef.nativeElement.value = this._value;
    this._onChange(this._value); // here to notify Angular Validators
  }

  @HostListener('blur')
  _onBlur() {
    this.unFormatValue();
    this.formatValue();
  }

  @HostListener('focus')
  onFocus() {
    if (this.elementRef.nativeElement.value == '0') {
      this.elementRef.nativeElement.value = ' ';
    } else {
      this.unFormatValue();
    }
  }

  _onChange(value: any): void {}

  writeValue(value: any) {
    this._value = value;
    this.formatValue();
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}

  private formatValue() {
    if (
      this._value !== null &&
      this._value != '-' &&
      this._value != ',' &&
      this._value != '.'
    ) {
      this._value = this.numericService.numberFormat(Number(this._value));
      this.elementRef.nativeElement.value = this._value;
    } else {
      this._value = '0';
      this.elementRef.nativeElement.value = this._value;
      this._onChange(this._value); // here to notify Angular Validators
    }
  }

  private unFormatValue() {
    if (
      this._value !== null &&
      this._value != '-' &&
      this._value != ',' &&
      this._value != '.'
    ) {
      this._value = this.numericService.cleanFormatting(this._value);
      this.elementRef.nativeElement.value = this._value;
    } else {
      this._value = '0';
      this.elementRef.nativeElement.value = this._value;
      this._onChange(this._value); // here to notify Angular Validators
    }
  }
}
