<div class="vnb-card-container">
  <div class="vnb-card">
    <div class="vnb_name">
      <span class="name"> {{ vnb.vnbName }}</span>
    </div>
    <div class="center">
      <span> {{ vnb.vnbLocationStreet }}</span>
    </div>
    <div class="center">
      <span> {{ vnb.vnbLocationStreetNumber }}</span>
    </div>
    <div class="center">
      <span> {{ vnb.vnbLocationZipCode }}</span>
    </div>
    <div class="center">
      <span> {{ vnb.vnbLocationCity }}</span>
    </div>
  </div>
</div>
