/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TPostCO2UserDTO } from './tPostCO2UserDTO';
import { TCreateStandardUserDTO } from './tCreateStandardUserDTO';
import { TCreateVNBUserDTO } from './tCreateVNBUserDTO';


export interface TPostUserDTO { 
    h2_vnb_user?: TCreateVNBUserDTO;
    h2_std_user?: TCreateStandardUserDTO;
    h2_co2_user?: TPostCO2UserDTO;
}

