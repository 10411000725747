import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { DashboardSelectors } from '@pages/dashboard/state/dashboard.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TNameundIdDTO } from '@api';
import { RegisterH2FacilityForm } from '../../../../interfaces/register_location';
import { GetVnbPlz } from '@pages/dashboard/state/dashboard.actions';

@UntilDestroy()
@Component({
  selector: 'app-verteilnetzbetreiber',
  templateUrl: './verteilnetzbetreiber.component.html',
  styleUrls: ['./verteilnetzbetreiber.component.scss'],
})
export class VerteilnetzbetreiberComponent implements OnInit {
  public form: FormGroup<RegisterH2FacilityForm>;

  @Select(DashboardSelectors.getVNBList)
  public readonly vnbs$: Observable<TNameundIdDTO[]>;

  constructor(
    private readonly store: Store,
    private readonly parentFormGroup: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
    const zipCode = this.form?.controls.facility
      ?.get('companyAddress')
      ?.get('zipCode')
      ?.getRawValue();
    if (zipCode) {
      this.store.dispatch(new GetVnbPlz(zipCode));
    }

    this.vnbs$.pipe(untilDestroyed(this)).subscribe(vnbs => {
      const vnbsLength = vnbs.length;
      if (this.form.contains('vnb')) {
        if (vnbsLength === 0) {
          this.form.controls.vnb.reset(0);
          this.form.controls.vnb.clearValidators();
        } else if (vnbsLength === 1) {
          this.form.controls.vnb.setValue(vnbs[0].id);
        } else {
          this.form.controls.vnb.addValidators([
            Validators.required,
            Validators.min(1),
          ]);
        }
      }
    });
  }
}
