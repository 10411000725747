<div class="facility-card-container">
  <div class="facility-card">
    <div class="facility_name">
      <span class="name"> {{ facility.facility_name }}</span>
    </div>
    <div class="facility_address">
      <div class="color-copy"> {{ facility.facility_street }} {{ facility.facility_street_number }}</div>
      <div class="color-copy"> {{ facility.facility_zip_code }} {{ facility.facility_city }}</div>
    </div>
    <div class="facility_first_year_of_transport_capability center">
      <div class="color-copy"> {{ facility.facility_first_year_of_transport_capability }}</div>
    </div>
    <div class="facility_facilty_type center">
      <app-facility-type-badge [isCo2]="isAppViewCo2$ | async" [typeId]="facility.facility_type_id"></app-facility-type-badge>
    </div>
    <div class="facility_facility_first_year_of_transport_capability">
      <div class="actions">
        <button [matMenuTriggerFor]="menu" class="more-icon" mat-icon-button>
          <mat-icon fontIcon="more_horiz"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button (click)="onShowInformation(facility)" mat-menu-item>{{ 'facility-tile.show_info' | translate }}</button>
          <button (click)="onDeleteFacility(facility)"
                  *ngIf="isUserCo2Customer$ | async" mat-menu-item>{{ 'data_table.actions.delete_facility'| translate }}
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

</div>
