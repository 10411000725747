/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TPostFacilityAndPlannedCO2AmountDTO } from './tPostFacilityAndPlannedCO2AmountDTO';
import { TPostFacilityAndPlannedH2AmountDTO } from './tPostFacilityAndPlannedH2AmountDTO';


export interface PostFacilityDTO { 
    h2_facility?: TPostFacilityAndPlannedH2AmountDTO;
    co2_facility?: TPostFacilityAndPlannedCO2AmountDTO;
}

