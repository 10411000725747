<ng-container *ngIf="mapState$ | async as mapState">
  <div *ngIf="mapState.actionState['isLoading']" class="loading-container">
    <app-loading-spinner data-cy="map-loading-spinner"></app-loading-spinner>
  </div>

  <app-map></app-map>

  <app-floating-app-open-button></app-floating-app-open-button>

  <ng-container *ngIf="(isUserAllowed$ | async)">
    <!-- customer (h2&co2) and VNBs are allowed. OGE is only in the appView co2 allowed -->
    <div class="unit-switch-container">
      <mat-button-toggle-group *ngIf="(isUserOGE$ | async) === false || ((isUserOGE$ | async) === true && (appView$ | async) === appViewEnum.CARBON_DIOXIDE)"
              (change)="onChanges($event.value)"
              [multiple]="true"
              [value]="activeDatasetType"
              class="dataset-toggles"
      >
        <ng-container *ngIf="(appView$ | async) === appViewEnum.CARBON_DIOXIDE; else h2ToggleButtons">
          <mat-button-toggle [value]="co2FacilityType.Einspeisung">
            <mat-icon class="dataset-icon">arrow_upward</mat-icon>
            {{ 'dashboard.graph.source' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="co2FacilityType.Ausspeisung">
            <mat-icon class="dataset-icon">arrow_downward</mat-icon>
            {{ 'dashboard.graph.sink' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="co2FacilityType.Hybrid">
            <mat-icon class="dataset-icon">arrow_upward</mat-icon>
            <mat-icon class="dataset-icon">arrow_downward</mat-icon>
            {{ 'dashboard.graph.source_sink' | translate }}
          </mat-button-toggle>
        </ng-container>
        <ng-template #h2ToggleButtons>
          <mat-button-toggle [value]="h2FacilityType.Producer">
            <mat-icon class="dataset-icon">arrow_upward</mat-icon>
            {{ 'dashboard.graph.production' | translate }}
          </mat-button-toggle>
          <mat-button-toggle [value]="h2FacilityType.Consumer">
            <mat-icon class="dataset-icon">arrow_downward</mat-icon>
            {{ 'dashboard.graph.demand' | translate }}
          </mat-button-toggle>
        </ng-template>
      </mat-button-toggle-group>
    </div>
    <button *ngIf="(isUserCustomer$ | async) || (isUserCo2Customer$ | async)"
            (click)="openRegistration()"
            aria-label="Standort eintragen"
            class="hover-button mdc-fab-mini"
            mat-icon-button>
      <mat-icon>add</mat-icon>
      <span class="text-on-hover">{{ 'registration.register_location_button' | translate }}</span>
    </button>
  </ng-container>
</ng-container>
