import { Component, OnInit } from '@angular/core';
import { number_regex } from '@regex';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-zip-code-area',
  templateUrl: './zip-code-area.component.html',
  styleUrls: ['./zip-code-area.component.scss'],
})
export class ZipCodeAreaComponent implements OnInit {
  public readonly NUMBER_REGEX = number_regex;
  private fileReader = new FileReader();
  public form: FormGroup;
  public zipCodeFormControl = new FormControl(null, [
    Validators.maxLength(5),
    Validators.minLength(5),
    Validators.pattern(this.NUMBER_REGEX),
  ]);
  public get zipCodeAreaControl(): FormControl {
    return this.form.get('zipCodeArea') as FormControl;
  }

  public constructor(private readonly parentFormGroup: FormGroupDirective) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
  }

  public addZipCode(): void {
    const newZip = this.zipCodeFormControl.getRawValue();
    if (!!newZip && this.zipCodeFormControl.valid) {
      if (this.zipCodeAreaControl.getRawValue().indexOf(newZip) === -1) {
        this.zipCodeAreaControl.setValue([
          ...this.zipCodeAreaControl.getRawValue(),
          newZip,
        ]);
        this.zipCodeAreaControl.markAsDirty();
        this.zipCodeFormControl.reset('');
      } else {
        this.zipCodeFormControl.setErrors({ duplicateZipCode: true });
      }
    } else {
      this.zipCodeFormControl.setErrors({ invalid: true });
    }
  }

  public removeZipCode(index: number): void {
    this.zipCodeAreaControl.getRawValue().splice(index, 1);
    this.zipCodeAreaControl.markAsDirty();
  }

  public onSelectedZipCodeFile(file: File): void {
    this.fileReader.onload = () => {
      const csvResult = this.fileReader.result;
      if (csvResult) {
        const array = csvResult.toString().split('\r');
        array.forEach((zip: string) => {
          // remove whitepaces and co
          zip = zip.replace(/(\r\n|\n|\r)/gm, '');
          if (
            this.zipCodeAreaControl.getRawValue().indexOf(zip) === -1 &&
            zip.length === 5
          ) {
            this.zipCodeAreaControl.setValue([
              ...this.zipCodeAreaControl.getRawValue(),
              zip,
            ]);
          }
        });
      }
    };
    this.fileReader.readAsText(file, 'UTF-8');
  }

  public clearZipCodeArea(): void {
    this.zipCodeAreaControl.setValue([]);
  }
}
