/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TCompanyTypeDTO } from './tCompanyTypeDTO';
import { TLocationDTO } from './tLocationDTO';
import { TGetFacilty03DTO } from './tGetFacilty03DTO';


export interface TGetH2Company { 
    company_id?: number;
    company_name?: string | null;
    company_location?: TLocationDTO;
    company_type?: TCompanyTypeDTO;
    readonly vnb_zip_codes?: Array<string> | null;
    readonly facilities?: Array<TGetFacilty03DTO> | null;
}

