/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EAlternativ = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;

export const EAlternativ = {
    NUMBER_0: 0 as EAlternativ,
    NUMBER_1: 1 as EAlternativ,
    NUMBER_2: 2 as EAlternativ,
    NUMBER_3: 3 as EAlternativ,
    NUMBER_4: 4 as EAlternativ,
    NUMBER_5: 5 as EAlternativ,
    NUMBER_6: 6 as EAlternativ,
    NUMBER_7: 7 as EAlternativ,
    NUMBER_8: 8 as EAlternativ,
    NUMBER_9: 9 as EAlternativ,
    NUMBER_10: 10 as EAlternativ,
    NUMBER_11: 11 as EAlternativ,
    NUMBER_12: 12 as EAlternativ,
    NUMBER_13: 13 as EAlternativ,
    NUMBER_14: 14 as EAlternativ,
    NUMBER_15: 15 as EAlternativ
};

