import { Injectable } from '@angular/core';

import {
  featureStrokeStyleOptions,
  transparentFeatureStyleOptions,
} from '@map/map-options/map-options';
@Injectable()
export class MapsGeocodingService {
  private allPlaceIds: string[] = [];
  private _map: google.maps.Map;

  public async handleGeocoderRequest(
    geocoderRequest: google.maps.GeocoderRequest
  ): Promise<readonly google.maps.GeocoderResult[]> {
    await google.maps.importLibrary('geocoding');
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode(geocoderRequest);
    return response.results;
  }

  public async drawZipCodeArea(googlemap: google.maps.Map, zipCode: string) {
    const result = await this.handleGeocoderRequest({
      address: zipCode,
      componentRestrictions: {
        country: 'DE',
      },
    });
    if (result) {
      this._map = googlemap;
      const placeId = result[0].place_id;
      this.allPlaceIds.push(placeId);

      this.setFeatureLayerStyle();

      return placeId;
    }
    return null;
  }

  public setFeatureLayerStyle(): void {
    if (this._map) {
      const featureLayer = this._map.getFeatureLayer(
        google.maps.FeatureType.POSTAL_CODE
      );

      if (featureLayer) {
        // @ts-ignore
        featureLayer.style = (options: { feature: { placeId: string } }) => {
          if (this.allPlaceIds.length === 0) {
            return transparentFeatureStyleOptions;
          }

          if (this.allPlaceIds.includes(options.feature.placeId)) {
            return {
              ...featureStrokeStyleOptions,
            };
          }
        };
      }
    }
  }
}
