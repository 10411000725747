import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTablePageComponent } from '@pages/data-table/data-table.page.component';
import { NgxsModule } from '@ngxs/store';
import { DataTableState } from '@pages/data-table/state/data-table.state';
import { DataTablePageRoutingModule } from '@pages/data-table/data-table-page-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { ReactiveFormsModule } from '@angular/forms';
import { H2CompanyTileComponent } from '../../features/table-tiles';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { H2FacilityTileComponent } from '../../features/table-tiles/h2-facility-tile/h2-facility-tile.component';
import { VnbTileComponent } from '../../features/table-tiles/vnb-tile/vnb-tile.component';
import { Co2FacilityTileComponent } from '../../features/table-tiles/co2-facility-tile/co2-facility-tile.component';
import { Co2CompanyTileComponent } from '../../features/table-tiles/co2-company-tile/co2-company-tile.component';
import { DateTableExpansionPanelComponent } from './components/date-table-expansion-panel/date-table-expansion-panel.component';

@NgModule({
  declarations: [
    DataTablePageComponent,
    H2CompanyTileComponent,
    Co2CompanyTileComponent,
    H2FacilityTileComponent,
    VnbTileComponent,
    Co2FacilityTileComponent,
    DateTableExpansionPanelComponent,
  ],
  imports: [
    CommonModule,
    NgxsModule.forFeature([DataTableState]),
    DataTablePageRoutingModule,
    HttpClientModule,
    SharedModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSortModule,
    ReactiveFormsModule,
    MatGridListModule,
  ],
})
export class DataTablePageModule {}
