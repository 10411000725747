export enum UnitType {
  KG = 'Kg',
  KWH = 'Kwh',
}


export enum DisplayUnitTypeEnergy {
  kg = 'kg',
  t = 't',
  kt = 'kt',
  mt = 'Mt',
  kwh = 'kWh',
  mwh = 'MWh',
  gwh = 'GWh',
  twh = 'TWh',
}

export enum DisplayUnitTypePower {
  kg_h = 'kg/h',
  t_h = 't/h',
  kt_h = 'kt/h',
  mt_h = 'Mt/h',
  kwh_h = 'kWh/h',
  mwh_h = 'MWh/h',
  gwh_h = 'GWh/h',
  twh_h = 'TWh/h',
}
