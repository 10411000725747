import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  AlternativeTransportEnum,
  Co2FacilityForm,
  CustomerDeclaration,
  EmissionType,
  RegisterCo2FacilityForm,
} from '../../../../interfaces/register_location';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacilityCo2Type } from '@enums';
import {
  maxBioRatio,
  maxCharacterLength,
  maxFirstYear,
  maxHours,
  maxLengthDescription,
  minAreaCharacterLength,
  minCharacterLength,
  minFirstYear,
} from '../../../../../../shared/constants/form-constants';
import { ValidateExtrenumValueMatcher } from '../../../../../../shared/validators/validate-value-to-number';

@UntilDestroy()
@Component({
  selector: 'app-register-co2-data-form',
  templateUrl: './register-co2-data-form.component.html',
  styleUrls: ['./register-co2-data-form.component.scss'],
})
export class RegisterCo2DataFormComponent implements OnInit, AfterViewInit {
  @Input()
  public step = 0;
  @Output()
  public readonly facilityTypeEnum = FacilityCo2Type;
  public readonly facilityCo2Type = FacilityCo2Type;
  public readonly alternativeTransportEnum = AlternativeTransportEnum;
  public form: FormGroup<RegisterCo2FacilityForm>;
  public readonly emissionTypeArray = Object.keys(EmissionType)
    .filter(key => !isNaN(Number(EmissionType[key])))
    .map(key => ({ label: key, value: EmissionType[key] }));
  public readonly existingConsumerArray = Object.keys(CustomerDeclaration)
    .filter(key => !isNaN(Number(CustomerDeclaration[key])))
    .map(key => ({ label: key, value: CustomerDeclaration[key] }));
  public readonly alternativeTransportArray = Object.keys(
    AlternativeTransportEnum
  )
    .filter(key => !isNaN(Number(AlternativeTransportEnum[key])))
    .map(key => ({ label: key, value: AlternativeTransportEnum[key] }));

  public years: number[] = [2030, 2035, 2040, 2045];

  public dataSource = this.years.map(year => ({
    year: year,
    ton: 0,
    hour: 0,
    bio_ratio: 0,
  }));
  public validateExtrenumValueMatcher = new ValidateExtrenumValueMatcher();

  public displayedColumns = ['year', 'ton', 'hour', 'bio_ratio'];
  public showAlternativeTransportCustomInput = false;
  public showCustomerDeclartionInput = false;
  public hasNoneSelected = false;
  public maxLengthDescription = maxLengthDescription;
  public minCharacterLength = minCharacterLength;
  public minAreaCharacterLength = minAreaCharacterLength;
  public maxCharacterLength = maxCharacterLength;
  public maxBioRatio = maxBioRatio;
  public maxHours = maxHours;
  public maxFirstYear = maxFirstYear;
  public minFirstYear = minFirstYear;

  constructor(private readonly parentFormGroup: FormGroupDirective) {}

  public get facilityForm(): FormGroup<Co2FacilityForm> {
    return this.form?.controls.facility as FormGroup;
  }

  public get typeId(): FacilityCo2Type {
    return this.facilityForm.controls.typeId.value;
  }

  public get alternativeTransportTypeIdControl(): FormControl {
    return this.form?.controls?.alternativeTransportTypeId;
  }

  public get alternativeTransportControl(): FormControl {
    return this.form?.controls?.alternativeTransport;
  }

  public get transportTargetControl(): FormControl {
    return this.form?.controls.transportTarget;
  }

  public get descriptionControl(): FormControl {
    return this.form?.controls.description;
  }

  public get customerDeclarationTypeIdControl(): FormControl {
    return this.form?.controls.customerDeclarationTypeId;
  }

  public getTitle(): string {
    if (this.typeId == this.facilityCo2Type.Einspeisung) {
      return 'registration.co2.data_type_consumer';
    } else if (this.typeId == this.facilityCo2Type.Ausspeisung) {
      return 'registration.co2.data_type_producer';
    } else if (this.typeId == this.facilityCo2Type.Hybrid) {
      return 'registration.co2.data_type_hybrid';
    }

    return '';
  }

  public ngOnInit() {
    this.form = this.parentFormGroup.control;
    this.alternativeTransportTypeIdControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(typeId => {
        this.showAlternativeTransportCustomInput = typeId.includes(
          AlternativeTransportEnum.FREITEXT
        );

        if (typeId.includes(AlternativeTransportEnum.FREITEXT)) {
          this.alternativeTransportControl.setValidators([Validators.required]);
          this.form.updateValueAndValidity();
        } else {
          this.alternativeTransportControl.clearValidators();
          this.alternativeTransportControl.setValue(null);
          this.form.updateValueAndValidity();
        }

        if (typeId?.includes(AlternativeTransportEnum.NONE)) {
          this.alternativeTransportTypeIdControl.setValue(
            [AlternativeTransportEnum.NONE],
            { emitEvent: false }
          );
          this.hasNoneSelected = true;
          this.showAlternativeTransportCustomInput = false;
        } else {
          this.hasNoneSelected = false;
        }
      });

    this.customerDeclarationTypeIdControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(customerDeclarationTypeId => {
        this.showCustomerDeclartionInput =
          customerDeclarationTypeId == CustomerDeclaration.YES;
        if (this.showCustomerDeclartionInput) {
          // add required!
          this.form.controls.customerDeclaration.setValidators(
            Validators.required
          );
        } else {
          this.form.controls.customerDeclaration.removeValidators(
            Validators.required
          );
          this.form.controls.customerDeclaration.reset();
        }
      });
  }

  public ngAfterViewInit(): void {
    /* UGLY JS Resize textarea - with a hidden div */
    const textarea = document.querySelector('.textarea-facility-description');
    const hiddenDiv = document.createElement('div');
    let content = null;
    hiddenDiv.classList.add('hidden-resize-div-for-textarea');
    hiddenDiv.classList.add('textarea-facility-description');
    textarea.addEventListener('keyup', () => {
      textarea.parentNode.appendChild(hiddenDiv);
      content = (textarea as any).value.replaceAll('\n', '</br>');
      hiddenDiv.innerHTML = content;
      hiddenDiv.style.visibility = 'hidden';
      hiddenDiv.style.display = 'block';
      (textarea as HTMLElement).style.height = hiddenDiv.offsetHeight + 'px';

      // make div invisible again
      hiddenDiv.style.visibility = 'visible';
      hiddenDiv.style.display = 'none';
    });
  }

  public checkForErrorsIn(formControl: AbstractControl): string {
    if (formControl.hasError('required')) {
      return 'registration.co2.required';
    }

    if (
      formControl.hasError('min') ||
      formControl.hasError('max') ||
      formControl.hasError('minLength') ||
      formControl.hasError('maxLength')
    ) {
      return 'registration.co2.value_not_correct';
    }

    return '';
  }

  public getEmissionLabel(translate: string) {
    return 'registration.co2.emission_' + translate.toLowerCase();
  }

  public getCustomerDeclarationLabel(translate: string) {
    return 'registration.co2.customer_declaration_' + translate.toLowerCase();
  }

  public getAlternativeTypeLabel(translate: string) {
    return 'registration.co2.alternative_transport_' + translate.toLowerCase();
  }
}
