/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TNameundIdDTO } from './tNameundIdDTO';


export interface TVNBListDTO { 
    vnb_liste_common_zip_code?: string | null;
    vnb_liste?: Array<TNameundIdDTO> | null;
}

