import { Component, Input } from '@angular/core';
import { TCO2CompanyDTO } from '@api';

@Component({
  selector: 'app-co2-company-tile',
  templateUrl: './co2-company-tile.component.html',
  styleUrls: ['./co2-company-tile.component.scss'],
})
export class Co2CompanyTileComponent {
  @Input()
  public company: TCO2CompanyDTO;
}
