import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { type Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import {
  DashboardSelectors,
  type NetworkArea,
} from '../../state/dashboard.selectors';
import { DataTableType } from '../../../../shared/enums/data-table-type-enum';
import { Router } from '@angular/router';
import { SetActiveTable } from '@pages/data-table/state/data-table.actions';
import { AppStateSelectors } from '@appState/app-state.selector';
import { AppViewEnum } from '../../../../shared/enums/app-view-enum';
import { ActionStateModel } from '@interfaces';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard-network-overview',
  templateUrl: './dashboard-network-overview.component.html',
  styleUrls: ['./dashboard-network-overview.component.scss'],
})
export class DashboardNetworkOverviewComponent {
  @Select(DashboardSelectors.getActionState)
  public readonly actionState$: Observable<ActionStateModel>;

  public isCo2View$: Observable<boolean> = this.store
    .select(AppStateSelectors.getAppView())
    .pipe(map(appView => appView === AppViewEnum.CARBON_DIOXIDE));

  public networkArea$: Observable<NetworkArea> = this.store
    .select<NetworkArea>(DashboardSelectors.getNetworkAreaOverview)
    .pipe(
      untilDestroyed(this),
      filter((value: NetworkArea | undefined): value is NetworkArea => !!value),
      shareReplay(1)
    );

  protected readonly dataTableType = DataTableType;

  constructor(
    private readonly store: Store,
    private readonly router: Router
  ) {}

  public goToDataTable(type: DataTableType) {
    this.store.dispatch(new SetActiveTable(type));
    this.router.navigate(['daten']);
  }
}
