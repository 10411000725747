import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable()
export class MapsHelperService {
  private defaultCenter = {
    ...environment.mapCenter,
    zoom: 6,
  };

  public getGestureHandling(url: string, isUserAllowed = true): string {
    if (!isUserAllowed) {
      return 'none';
    }
    return !url.includes('/karte') ? 'cooperative' : 'greedy';
  }

  public setFocus(
    googlemap: google.maps.Map,
    focus = this.defaultCenter,
    latLngArray: google.maps.LatLngLiteral[] = []
  ): void {
    if (latLngArray.length > 0) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      latLngArray.map(latLng => {
        bounds.extend(latLng);
      });
      googlemap.setCenter(bounds.getCenter());
      googlemap.fitBounds(bounds);
      googlemap.setZoom(googlemap.getZoom() - 0.5);
    } else if (focus) {
      googlemap.setZoom(focus?.zoom ?? 7);
      googlemap.setCenter({
        lat: focus.lat,
        lng: focus.lng,
      });
    }
  }
}
