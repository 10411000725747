import { Component, Input, OnInit } from '@angular/core';
import { UserStatus } from '@enums';
import {
  TGetCO2Company,
  TGetH2Company,
  TUserAdminplusCompanyDTO,
  TUserDTO,
} from '@api';
import { Select, Store } from '@ngxs/store';
import {
  ChangeUserState,
  SetUserToAdmin,
} from '@pages/administration/state/administration.actions';
import { Observable } from 'rxjs';
import { AppStoreModel } from '@appState/app.store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { DialogService } from '@services';
import { CompanyDataComponent } from '../../company/company-data/company-data.component';

@Component({
  selector: 'app-user-administration-tile',
  templateUrl: './user-administration-tile.component.html',
  styleUrls: ['./user-administration-tile.component.scss'],
})
export class UserAdministrationTileComponent implements OnInit {
  @Select((state: AppStoreModel) => state.appState.user.user_id)
  public loggedInUserId$: Observable<number>;

  @Select(AppStateSelectors.getUsersCompany())
  public usersCompany$: Observable<TGetH2Company | TGetCO2Company>;

  @Select(AppStateSelectors.isUserAdmin())
  public isUserAdmin$: Observable<boolean>;

  @Input()
  public user: TUserAdminplusCompanyDTO;

  public color = 'color-pink';

  public readonly userStatus = UserStatus;

  constructor(
    private readonly store: Store,
    private readonly dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.color = this.setColorByStatus();
  }

  public getUserShortName(): any {
    const firstLetter = this.user.user_first_name.charAt(0);
    const firstLetterLastName = this.user.user_last_name.charAt(0);
    return `${firstLetter.toUpperCase()}${firstLetterLastName.toUpperCase()}`;
  }

  public changeUserState(user: TUserDTO, userState: UserStatus): void {
    const username = `${user.user_first_name} ${user.user_last_name}`;
    const content: string =
      userState === this.userStatus.closed
        ? 'administration.dialog.change_status_lock'
        : 'administration.dialog.change_status_unlock';
    this.openDialog(content, { username }, () =>
      this.store.dispatch(new ChangeUserState(user.user_id, userState))
    );
  }

  public changeUserAdminRole(user: TUserDTO, toAdmin: boolean): void {
    this.openDialog(
      toAdmin
        ? 'administration.dialog.add_admin_role'
        : 'administration.dialog.remove_admin_role',
      { username: `${user.user_first_name} ${user.user_last_name}` },
      () => this.store.dispatch(new SetUserToAdmin(user.user_id, toAdmin))
    );
  }

  public isUserLockedFromOtherCompany(
    user: TUserAdminplusCompanyDTO,
    ownCompanyname: string
  ): boolean {
    return (
      user.user_state_setter_company_name !== null &&
      user.user_state_setter_company_name !== ownCompanyname
    );
  }

  public changeCompanyData(): void {
    this.dialogService.openDialog(CompanyDataComponent, {
      id: 'company-data-dialog-id',
      backdropClass: 'blurred',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'form-dialog',
    });
  }

  private setColorByStatus(): string {
    if (this.user.user_state_id === this.userStatus.accepted) {
      if (this.user.user_is_admin) {
        return 'color-blue';
      }
      return 'color-copy';
    } else if (this.user.user_state_id === this.userStatus.closed) {
      return 'color-locked';
    } else {
      return 'color-pink';
    }
  }

  private openDialog(content: string, contentParams: any, callback: any): void {
    this.dialogService.showCustomDialog({
      title: 'administration.dialog.change_title',
      content: content,
      contentParams: contentParams,
      color: 'INFO',
      actions: {
        acceptLabel: 'administration.dialog.accept',
        abortLabel: 'administration.dialog.abort',
        acceptCallback: callback,
      },
    });
  }
}
