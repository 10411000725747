import { DataTableHeader } from '../shared/interfaces/data-table-header';

export const vnbCompanyTableHeader: DataTableHeader[] = [
  { sortColumn: 'company_name', labelColumn: 'data_table.sort.company_name' },
  {
    sortColumn: 'company_facilty_count',
    labelColumn: 'data_table.sort.facilty_count',
  },
  {
    sortColumn: 'company_consumer_facilty_count',
    labelColumn: 'data_table.sort.consumer_facilty_count',
  },
  {
    sortColumn: 'company_producer_facilty_count',
    labelColumn: 'data_table.sort.producer_facilty_count',
  },
  { sortColumn: 'company_insert_time', labelColumn: 'data_table.sort.date' },
];
export const customerH2FacilityTableHeader: DataTableHeader[] = [
  {
    sortColumn: 'facility_name',
    labelColumn: 'data_table.sort.facility_name',
  },
  {
    sortColumn: 'facility_type_id',
    labelColumn: 'data_table.sort.type_id',
  },
  {
    sortColumn: 'facility_malo_id',
    labelColumn: 'data_table.sort.malo_id',
  },
  { sortColumn: 'facility_insert_time', labelColumn: 'data_table.sort.date' },
];
export const vnbH2FacilityTableHeader: DataTableHeader[] = [
  {
    sortColumn: 'facility_name',
    labelColumn: 'data_table.sort.facility_name',
  },
  {
    sortColumn: 'facility_type_id',
    labelColumn: 'data_table.sort.type_id',
  },
  {
    sortColumn: 'facility_malo_id',
    labelColumn: 'data_table.sort.malo_id',
  },
  {
    sortColumn: 'facility_company_name',
    labelColumn: 'data_table.sort.company_name',
  },
  { sortColumn: 'facility_insert_time', labelColumn: 'data_table.sort.date' },
];
export const co2CustomerTableHeader: DataTableHeader[] = [
  {
    sortColumn: 'facility_name',
    labelColumn: 'data_table.sort.facility_name',
  },
  {
    sortColumn: 'facility_address',
    labelColumn: 'data_table.address',
  },
  {
    sortColumn: 'facility_first_year_of_transport_capability',
    labelColumn: 'data_table.sort.first_year_of_transport_capability',
  },
  {
    sortColumn: 'facility_type_id',
    labelColumn: 'data_table.sort.type_id',
  },
  { sortColumn: 'facility_actions', labelColumn: 'data_table.sort.actions' },
];
export const ogeVNBTableHeader: DataTableHeader[] = [
  { sortColumn: 'vnbName', labelColumn: 'data_table.sort.vnb_name' },
  {
    sortColumn: 'vnbLocationStreet',
    labelColumn: 'data_table.sort.street',
  },
  {
    sortColumn: 'vnbLocationStreetNumber',
    labelColumn: 'data_table.sort.streetNumber',
  },
  {
    sortColumn: 'vnbLocationZipCode',
    labelColumn: 'data_table.sort.zipCode',
  },
  { sortColumn: 'vnbLocationCity', labelColumn: 'data_table.sort.city' },
];
export const ogeFacilityCo2TableHeader: DataTableHeader[] = [
  {
    sortColumn: 'facility_name',
    labelColumn: 'data_table.sort.facility_name',
  },
  {
    sortColumn: 'facility_address',
    labelColumn: 'data_table.address',
  },
  {
    sortColumn: 'facility_first_year_of_transport_capability',
    labelColumn: 'data_table.sort.first_year_of_transport_capability',
  },
  {
    sortColumn: 'facility_type_id',
    labelColumn: 'data_table.sort.type_id',
  },
  { sortColumn: 'facility_actions', labelColumn: 'data_table.sort.actions' },
];
export const ogeCompanyCo2TableHeader: DataTableHeader[] = [
  { sortColumn: 'company_name', labelColumn: 'data_table.sort.company_name' },
  {
    sortColumn: 'company_address',
    labelColumn: 'data_table.sort.facility_address',
  },
  {
    sortColumn: 'company_facility_count',
    labelColumn: 'data_table.sort.facilty_count',
  },
  {
    sortColumn: 'company_quelle_facility_count',
    labelColumn: 'data_table.sort.einspeisung_facilty_count',
  },
  {
    sortColumn: 'company_senke_facility_count',
    labelColumn: 'data_table.sort.ausspeisung_facilty_count',
  },
  {
    sortColumn: 'company_hybrid_facility_count',
    labelColumn: 'data_table.sort.hybrid_facilty_count',
  },
];
