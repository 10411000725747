import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AppStateSelectors } from '@appState/app-state.selector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TH2FacilityExtendetDTO, TLocationDTO, VOgeVnb } from '@api';
import { Router } from '@angular/router';
import { MapsMarkerService } from '@map/services/maps-marker/maps-marker.service';
import { MapsHelperService } from '@map/services/maps-helper.service';
import { mapOptions } from '@map/map-options/map-options';
import { SetMapIsLoading } from '@pages/map/state/map.actions';
import { LoadFacilityList } from '@appState/app.actions';
import { environment } from '@env';
import { ActionState, ActionStateModel } from '@interfaces';
import { MapStateSelectors } from '@pages/map/state/map-state-selectors.service';
import { DataTableStateSelectors } from '@pages/data-table/state/data-table.state.selectors';
import { AppViewEnum } from '../../../../shared/enums/app-view-enum';
import { GoogleMap } from '@angular/google-maps';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit {
  public readonly environment = environment;
  public readonly appViewEnum = AppViewEnum;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

  @Select(AppStateSelectors.getCookiesAreAccepted())
  public readonly cookiesAreAccepted$: Observable<boolean>;

  @Select(AppStateSelectors.getAppActionState())
  public readonly appActionState$: Observable<ActionStateModel>;

  @Select(AppStateSelectors.getUsersCompanyZipCodes())
  public readonly companyZipCodes$: Observable<string[]>;

  @Select(AppStateSelectors.getUsersCompanyLocation())
  public readonly companyLocation$: Observable<TLocationDTO>;

  @Select(AppStateSelectors.isUserLoggedIn())
  public readonly isUserLoggedIn$: Observable<boolean>;

  @Select(AppStateSelectors.isUserAllowed())
  public readonly isUserAllowed$: Observable<boolean>;

  @Select(AppStateSelectors.isUserOGE())
  public readonly isUserOGE$: Observable<boolean>;

  @Select(AppStateSelectors.getFacilityList())
  public readonly facilityList$: Observable<TH2FacilityExtendetDTO[]>;

  @Select(MapStateSelectors.isLoading)
  public mapIsLoading$: Observable<boolean>;

  @Select(DataTableStateSelectors.isLoadingOGEVNBData())
  public actionStateOGEVNBData$: Observable<ActionState>;

  @Select(DataTableStateSelectors.vnbData())
  public vnbList$: Observable<VOgeVnb[]>;

  @Select(AppStateSelectors.getAppView())
  public appView$: Observable<AppViewEnum>;

  @Select(MapStateSelectors.getActiveDataTypes)
  public activeDataTypes$: Observable<number[]>;

  public activeDataTypes: number[] = null;

  public mapOptions: google.maps.MapOptions = {
    ...mapOptions,
    mapId: environment.mapId,
  };
  public showMap: boolean;

  public get getFacilityListMarkers$() {
    return this.mapsMarkerService.markers$;
  }

  public constructor(
    private readonly mapsMarkerService: MapsMarkerService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly mapsHelperService: MapsHelperService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public async ngOnInit(): Promise<void> {
    // load google.maps
    await google.maps.importLibrary('maps');
    // show loading
    this.store.dispatch(new SetMapIsLoading(true));
    // load facility list for loggedIn users
    combineLatest([this.isUserLoggedIn$, this.isUserOGE$, this.appView$])
      .pipe(untilDestroyed(this))
      .subscribe(([isUserLoggedIn, isUserOGE, appView]) => {
        if (
          isUserLoggedIn &&
          (appView == AppViewEnum.CARBON_DIOXIDE || !isUserOGE)
        ) {
          this.store.dispatch(new LoadFacilityList());
        }
      });

    combineLatest([
      this.cookiesAreAccepted$,
      this.isUserLoggedIn$,
      this.isUserAllowed$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([cookiesAreAccepted, isUserLoggedIn, isUserAllowed]: [
          boolean,
          boolean,
          boolean,
        ]) => {
          if (cookiesAreAccepted) {
            this.showMap = true;
            this.changeDetectorRef.detectChanges();
            if (isUserAllowed && isUserLoggedIn) {
              // update map options
              if (isUserAllowed && this.map) {
                this.mapOptions = {
                  ...this.mapOptions,
                  gestureHandling: this.mapsHelperService.getGestureHandling(
                    this.router.url,
                    isUserAllowed
                  ),
                };
                this.addListenerToMap();
              }
            }
          } else {
            this.showMap = false;
          }
          this.store.dispatch(new SetMapIsLoading(false));
        }
      );
  }

  public ngAfterViewInit(): void {
    this.companyLocation$
      .pipe(
        distinctUntilChanged(
          (prev, curr) => prev?.location_id === curr?.location_id
        ),
        untilDestroyed(this)
      )
      .subscribe(companyLocation => {
        if (this.map?.googleMap && this.showMap && companyLocation) {
          // set focus to company location
          this.mapsHelperService.setFocus(this.map.googleMap, {
            lng: companyLocation.location_latitude,
            lat: companyLocation.location_longitude,
            zoom: 11,
          });
        }
      });

    // when dataType, appView or list changed, update marker/cluster
    combineLatest([
      this.activeDataTypes$,
      this.appView$,
      this.facilityList$,
      this.vnbList$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([activeDataTypes, appView, facilityList, vnbList]) => {
        let withFitBounds = true;
        if (
          this.activeDataTypes !== null &&
          activeDataTypes !== this.activeDataTypes
        ) {
          withFitBounds = false;
        }
        this.activeDataTypes = activeDataTypes;
        if (this.showMap) {
          if (vnbList && appView == this.appViewEnum.HYDROGEN) {
            this.mapsMarkerService.addVNBMarker(this.map.googleMap, vnbList);
          } else {
            this.mapsMarkerService.addFacilitiesMarker(
              this.map.googleMap,
              facilityList,
              this.activeDataTypes
            );
          }
          // add cluster
          this.mapsMarkerService.addMarkerCluster(
            this.map.googleMap,
            withFitBounds
          );
          this.mapsMarkerService.setMarkersVisibility(
            this.activeDataTypes,
            this.map.googleMap
          );
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  private addListenerToMap(): void {
    this.map?.googleMap?.addListener('zoom_changed', () => {
      this.mapsMarkerService.setMarkersVisibility(
        this.activeDataTypes,
        this.map.googleMap
      );
      this.changeDetectorRef.detectChanges();
    });
  }

  /* @TODO check after updates, if its still necessary
      sometimes the events from the marker is not trigger so do it manual */
  public openInfoWindow(marker: google.maps.Marker): void {
    google.maps.event.trigger(marker, 'mouseover');
  }
  public closeInfoWindow(marker: google.maps.Marker): void {
    google.maps.event.trigger(marker, 'mouseout');
  }
  public clickEvent(marker: google.maps.Marker): void {
    google.maps.event.trigger(marker, 'click');
  }
}
