/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VOgeVnb { 
    vnbId?: number;
    vnbName?: string | null;
    vnbLocationCity?: string | null;
    vnbLocationStreet?: string | null;
    vnbLocationStreetNumber?: string | null;
    vnbLocationZipCode?: string | null;
    vnbLocationLatitude?: number;
    vnbLocationLongitude?: number;
}

