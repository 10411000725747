import { Component, OnInit } from '@angular/core';
import { Co2RoleEnum } from '../../../../../shared/enums/co2-role-enum';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Co2UserRegistrationFormModel } from '@registration/interfaces';

@Component({
  selector: 'app-co2-company-role',
  templateUrl: './co2-company-role.component.html',
  styleUrls: ['./co2-company-role.component.scss'],
})
export class Co2CompanyRoleComponent implements OnInit {
  public readonly roleEnum = Co2RoleEnum;
  public form: FormGroup<Co2UserRegistrationFormModel>;

  constructor(private readonly parentFormGroup: FormGroupDirective) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
  }
}
