import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface CustomDialogModel {
  title: string;
  content: string;
  contentParams?: any;
  color?: 'ERROR' | 'INFO';
  actions?: {
    acceptCallback?: any;
    acceptLabel?: string;
    abortLabel?: string;
  };
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'],
})
export class CustomDialogComponent {
  public customData: CustomDialogModel;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CustomDialogModel,
    private dialogRef: MatDialogRef<CustomDialogComponent>
  ) {
    this.customData = data;
  }

  public onAccept(): void {
    if (this.customData.actions?.acceptCallback) {
      this.customData.actions.acceptCallback();
    }
    this.onAbort();
  }

  public onAbort(): void {
    this.dialogRef.close();
  }
}
