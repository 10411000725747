import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardPageComponent } from './dashboard.page.component';
import { DashboardGraphComponent } from './components/dashboard-graph/dashboard-graph.component';
import { FeaturesModule } from '../../features/features.module';
import { DashboardNetworkOverviewComponent } from './components/dashboard-network-overview/dashboard-network-overview.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import { DashboardFacilitiesCompaniesComponent } from './components/dashboard-facilities-companies/dashboard-facilities-companies.component';
import { DashboardService } from '@pages/dashboard/services/dashboard.service';
import { RouterModule } from '@angular/router';
import { FloatingAppOpenButtonComponent } from '@components';
import { FloatingBtnFullMapComponent } from '../../shared/components/floating-btn-full-map/floating-btn-full-map.component';
import { Co2GraphComponent } from './components/co2-graph/co2-graph.component';
import { BaseChartDirective } from 'ng2-charts';

@NgModule({
  imports: [
    CommonModule,
    FeaturesModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatProgressBarModule,
    SharedModule,
    RouterModule,
    FloatingAppOpenButtonComponent,
    FloatingBtnFullMapComponent,
    BaseChartDirective,
  ],
  declarations: [
    DashboardPageComponent,
    DashboardGraphComponent,
    DashboardNetworkOverviewComponent,
    DashboardFacilitiesCompaniesComponent,
    Co2GraphComponent,
  ],
  providers: [DashboardService],
})
export class DashboardPageModule {}
