import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MapModule } from '@map/map.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CustomerVnbRegistrationComponent } from '@registration/components/hydrogen/customer-vnb-registration/customer-vnb-registration.component';
import { SharedModule } from '../../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { CustomerRegistrationComponent } from '@registration/components/hydrogen/customer-registration/customer-registration.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ZipCodeAreaComponent } from '@registration/components/hydrogen/zip-code-area/zip-code-area.component';
import { UploadCompanyPolicyComponent } from '@registration/components/hydrogen/upload-company-policy/upload-company-policy.component';
import {
  RegisterCo2DataFormComponent,
  RegisterCo2LocationComponent,
  RegisterH2LocationComponent,
  RegisterH2YearsFormComponent,
  VerteilnetzbetreiberComponent,
} from '@facility';
import { Co2CustomerRegistrationComponent } from '@registration/components/co2/co2-customer-registration/co2-customer-registration.component';
import {
  RegistrationEntryComponent,
  RegistrationPersonalDataComponent,
} from '@registration/components';
import { HydrogenActionsComponent } from '@registration/components/hydrogen/hydrogen-actions/hydrogen-actions.component';
import { HydrogenRegistrationService } from '@registration/components/hydrogen/hydrogen-registration.service';
import { RegistrationService } from '@registration/services/registration.service';
import { Co2ActionsComponent } from './components/co2/co2-actions/co2-actions.component';
import { HydrogenHeaderComponent } from './components/hydrogen/hydrogen-header/hydrogen-header.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { Co2CompanyRoleComponent } from './components/co2/co2-company-role/co2-company-role.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MapModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    SharedModule,
    MatSelectModule,
    MatTableModule,
  ],
  declarations: [
    RegisterH2LocationComponent,
    RegisterH2YearsFormComponent,
    RegisterCo2LocationComponent,
    RegisterCo2DataFormComponent,
    VerteilnetzbetreiberComponent,
    CustomerVnbRegistrationComponent,
    CustomerRegistrationComponent,
    ZipCodeAreaComponent,
    UploadCompanyPolicyComponent,
    Co2CustomerRegistrationComponent,
    RegistrationPersonalDataComponent,
    RegistrationEntryComponent,
    HydrogenActionsComponent,
    Co2ActionsComponent,
    HydrogenHeaderComponent,
    Co2CompanyRoleComponent,
  ],
  providers: [HydrogenRegistrationService, RegistrationService],
  exports: [
    RegisterH2LocationComponent,
    RegisterH2YearsFormComponent,
    RegisterCo2LocationComponent,
    RegisterCo2DataFormComponent,
    VerteilnetzbetreiberComponent,
    CustomerVnbRegistrationComponent,
    ZipCodeAreaComponent,
    UploadCompanyPolicyComponent,
    Co2CompanyRoleComponent,
  ],
})
export class RegistrationModule {}
