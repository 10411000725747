import { Injectable } from '@angular/core';
import {
  GetDashboardFacilitiesAndCompanies,
  GetDashboardOverviewNetworkArea,
  GetYearlySummary,
} from '@pages/dashboard/state/dashboard.actions';
import { Select, Store } from '@ngxs/store';
import { LoadFacilityList } from '@appState/app.actions';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppStateSelectors } from '@appState/app-state.selector';

@UntilDestroy()
@Injectable()
export class DashboardService {
  @Select(AppStateSelectors.isUserAllowed())
  public isUserAllowed$: Observable<boolean>;

  constructor(private readonly store: Store) {}

  public initDashboard() {
    this.isUserAllowed$.pipe(untilDestroyed(this)).subscribe(isUserAllowed => {
      if (isUserAllowed) {
        this.store.dispatch(new GetYearlySummary());
        this.store.dispatch(new GetDashboardOverviewNetworkArea());
        this.store.dispatch(new GetDashboardFacilitiesAndCompanies());
        this.store.dispatch(new LoadFacilityList());
      }
    });
  }
}
