/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { PostFacilityDTO } from '../model/models';
import { TGetFacilitiesDTO } from '../model/models';
import { TGetFacilityDTO } from '../model/models';
import { TPutFacilityDTO } from '../model/models';
import { TUserDTO } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FacilityServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param facilityId 
     */
    apiFacilityFacilityIdGet(facilityId: number, extraHttpRequestParams?: any): Observable<TGetFacilityDTO>;

    /**
     * 
     * 
     */
    apiFacilityGet(extraHttpRequestParams?: any): Observable<TGetFacilitiesDTO>;

    /**
     * 
     * 
     * @param id 
     */
    apiFacilityIdDelete(id: number, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * 
     * 
     * @param postFacilityDTO 
     */
    apiFacilityPost(postFacilityDTO?: PostFacilityDTO, extraHttpRequestParams?: any): Observable<TUserDTO>;

    /**
     * 
     * 
     * @param tPutFacilityDTO 
     */
    apiFacilityPut(tPutFacilityDTO?: TPutFacilityDTO, extraHttpRequestParams?: any): Observable<{}>;

}
