/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { FactoryProvider, enableProdMode } from '@angular/core';
import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import { environment } from '@env';

export function getBaseUrl(): string {
  return document.querySelector('base')?.href ?? '';
}

const baseUrlProvider: FactoryProvider = {
  provide: 'BASE_URL',
  useFactory: getBaseUrl,
  deps: [],
};

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

platformBrowserDynamic([baseUrlProvider])
  .bootstrapModule(AppModule)
  .catch((err: unknown) => console.error(err));
