import { ElementRef, Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class MapsPlacesService {
  public async getAutocompletePlace(
    inputElement: ElementRef,
    isCo2: boolean = false
  ) {
    await google.maps.importLibrary('places');
    return new google.maps.places.Autocomplete(inputElement.nativeElement, {
      componentRestrictions: {
        country: isCo2 ? environment.co2CountryCodes : 'de',
      },
      types: ['geocode'],
    });
  }
}
