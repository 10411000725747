import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, combineLatest, take } from 'rxjs';
import { MapStateModel } from './state/map-state.model';
import { MapState } from './state/map.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppStateSelectors } from '@appState/app-state.selector';
import { FacilityCo2Type, FacilityH2Type } from '@enums';
import { DialogService } from '@services';
import {
  RegisterCo2LocationComponent,
  RegisterH2LocationComponent,
} from '@facility';
import { LoadVNBDisplayData } from '@pages/data-table/state/data-table.actions';
import { AppViewEnum } from '../../shared/enums/app-view-enum';
import { SetActiveDataTypes } from '@pages/map/state/map.actions';
import { AuthGuardService } from '../../features/authentication/services/auth-guard.service';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
})
export class MapPageComponent implements OnInit {
  @Select(MapState)
  public readonly mapState$: Observable<MapStateModel>;

  @Select(AppStateSelectors.isUserAllowed())
  public isUserAllowed$: Observable<boolean>;

  @Select(AppStateSelectors.isUserOGE())
  public isUserOGE$: Observable<boolean>;

  @Select(AppStateSelectors.isUserVNB())
  public isUserVNB$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isUserCo2Customer$: Observable<boolean>;

  @Select(AppStateSelectors.isUserH2Customer())
  public isUserCustomer$: Observable<boolean>;

  @Select(AppStateSelectors.getAppView())
  public appView$: Observable<AppViewEnum>;

  public readonly h2FacilityType = FacilityH2Type;
  public readonly co2FacilityType = FacilityCo2Type;
  public readonly appViewEnum = AppViewEnum;
  public activeDatasetType: number[] = [];

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly authGuardService: AuthGuardService
  ) {}

  public ngOnInit() {
    this.authGuardService.authGuardForUrl('karte');
    combineLatest([this.isUserOGE$, this.appView$])
      .pipe(untilDestroyed(this))
      .subscribe(([isUserOGE, appView]) => {
        if (appView === AppViewEnum.CARBON_DIOXIDE) {
          this.activeDatasetType = [
            this.co2FacilityType.Ausspeisung,
            this.co2FacilityType.Einspeisung,
            this.co2FacilityType.Hybrid,
          ];
        } else if (isUserOGE && appView === AppViewEnum.HYDROGEN) {
          this.store.dispatch(new LoadVNBDisplayData());
        } else {
          this.activeDatasetType = [
            this.h2FacilityType.Consumer,
            this.h2FacilityType.Producer,
          ];
        }
        this.store.dispatch(new SetActiveDataTypes(this.activeDatasetType));
      });
  }

  public openRegistration(): void {
    this.isUserCo2Customer$.pipe(take(1)).subscribe(isUserCo2Customer => {
      if (isUserCo2Customer) {
        this.dialogService.openRegisterLocation(RegisterCo2LocationComponent);
      } else {
        this.dialogService.openRegisterLocation(RegisterH2LocationComponent);
      }
    });
  }

  public onChanges(values: readonly number[]): void {
    this.activeDatasetType = [...values];
    this.store.dispatch(new SetActiveDataTypes(this.activeDatasetType));
  }
}
