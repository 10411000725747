import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivationStart,
  Event,
  Router,
} from '@angular/router';
import {
  Action,
  NgxsOnInit,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import {
  DeleteFacilityById,
  GetUsersCompany,
  InitAppState,
  LoadCustomerLogo,
  LoadFacilityList,
  LoadSelectedFacilityById,
  SaveCompanyData,
  SaveDocumentByType,
  SetAccountInfo,
  SetCompany,
  SetCookiesAccepted,
  SetSelectedFacilityId,
  SetUser,
  SetUserMustCompleteRegistration,
  SetVNBId,
  SetViewApp,
  UpdateUserInBE,
} from './app.actions';
import type { AppStateModel } from './app-state.model';
import { Observable, of } from 'rxjs';
import { ErrorHandlingService, LegalService } from '@services';
import {
  CompanyService,
  FacilityService,
  TCO2FacilityExtendetDTO,
  TCompanyShortPlusOptionalPlzListeDTO,
  TDokumentDTO,
  TGetCompanyDTO,
  TGetFacilitiesDTO,
  TGetFacilityDTO,
  TH2FacilityExtendetDTO,
  TUserPutDTO,
  UserService,
} from '@api';
import { patch } from '@ngxs/store/operators';
import { ActionState } from '@interfaces';
import { CompanyTypeEnum } from '@enums';
import { GetUsers } from '@pages/administration/state/administration.actions';
import { AppViewEnum } from '../shared/enums/app-view-enum';

const APP_STATE_TOKEN: StateToken<AppStateModel> = new StateToken('appState');

@State<AppStateModel>({
  name: APP_STATE_TOKEN,
  defaults: {
    actionState: ActionState.create(),
    cookiesAccepted: false,
    userIsApproved: true,
    userIsLocked: false,
    userMustCompleteRegistration: true,
    user: {
      user_is_company_admin: false,
    },
    appView: AppViewEnum.HYDROGEN,
  },
})
@Injectable()
export class AppState implements NgxsOnInit {
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly companyService: CompanyService,
    private readonly userService: UserService,
    private readonly legalService: LegalService,
    private readonly facilityService: FacilityService,
    private readonly store: Store,
    private readonly errorHandlingService: ErrorHandlingService
  ) {}

  public ngxsOnInit(context: StateContext<AppStateModel>): void {
    this.router.events
      .pipe(
        filter(
          (routerEvent: Event): routerEvent is ActivationStart =>
            routerEvent instanceof ActivationStart
        ),
        map(
          (activationStart: ActivationStart): string | undefined =>
            activationStart.snapshot.paramMap.get('vnbId') ?? undefined
        ),
        distinctUntilChanged()
      )
      .subscribe({
        next: (vnbId: string | undefined): void => {
          context.dispatch(new SetVNBId(vnbId));
        },
      });
  }

  @Action(InitAppState)
  public initAppState(context: StateContext<AppStateModel>): void {
    context.setState({
      actionState: ActionState.onStart(),
      cookiesAccepted: false,
      userIsApproved: true,
      userIsLocked: false,
      user: {
        user_is_company_admin: false,
      },
      userMustCompleteRegistration: true,
    });
  }

  @Action(SetVNBId)
  public setVNBId(
    context: StateContext<AppStateModel>,
    action: SetVNBId
  ): void {
    context.patchState({
      ...context,
      actionState: ActionState.onDone(),
      vnbId: action.vnbId,
    });
  }

  @Action(SetAccountInfo)
  public setAccountInfo(
    context: StateContext<AppStateModel>,
    action: SetAccountInfo
  ): void {
    context.patchState({
      ...context,
      actionState: ActionState.onDone(),
      azureUser: action.account,
    });
  }

  @Action(SetCompany)
  public setCompany(
    context: StateContext<AppStateModel>,
    action: SetCompany
  ): void {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    context.patchState({
      ...context,
      actionState: ActionState.onDone(),
      usersCompany: {
        company_id: action.company.company_id,
        company_name: action.company.company_name,
        company_location: {
          ...action.company.company_location,
        },
        company_type: {
          ...action.company.company_type,
        },
        vnb_zip_codes:
          (action.company as TCompanyShortPlusOptionalPlzListeDTO)
            .optionale_postleitzahlen_liste_bei_vnb ?? [],
      },
      userMustCompleteRegistration: false,
    });
  }

  @Action(GetUsersCompany)
  public getUsersCompany(
    context: StateContext<AppStateModel>
  ): Observable<TGetCompanyDTO> {
    return this.companyService.apiCompanyGet().pipe(
      tap({
        next: (result: TGetCompanyDTO) => {
          const company = result.h2_company ?? result.co2_company;
          context.patchState({
            ...context,
            actionState: ActionState.onDone(),
            usersCompany: company,
            userIsApproved: true,
            userMustCompleteRegistration: false,
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionState: ActionState.onError(true),
            userIsApproved: false,
            userMustCompleteRegistration: true,
          });
          this.router.navigate(['karte'], { relativeTo: this.activatedRoute });
        },
      })
    );
  }

  @Action(SaveCompanyData)
  public saveCompanyData(
    context: StateContext<AppStateModel>,
    action: SaveCompanyData
  ) {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    return this.companyService.apiCompanyPut(action.putCompanyDTO).pipe(
      tap({
        next: () => {
          context.setState(
            patch({
              actionState: ActionState.onDone(),
            })
          );
          context.dispatch(new GetUsersCompany());
          context.dispatch(new GetUsers());
        },
        error: err => {
          context.setState(
            patch({
              actionState: ActionState.onError(true),
            })
          );
          this.errorHandlingService.showError(err);
        },
      })
    );
  }

  @Action(LoadCustomerLogo)
  public loadCustomerLogo(
    context: StateContext<AppStateModel>
  ): Observable<TDokumentDTO> | void {
    const state = context.getState();
    if (!!state.azureUser?.account.localAccountId) {
      const companyId = state.usersCompany?.company_id;
      const isUserVNB = state.user.user_company_type_id === CompanyTypeEnum.VNB;
      if (companyId && isUserVNB) {
        // customer logo exists only for VNBs
        return this.legalService.getCustomerLogo(companyId).pipe(
          tap({
            next: document => {
              context.patchState({
                actionState: ActionState.onDone(),
                companyLogo: document.document_string,
              });
            },
            error: () => {
              context.patchState({
                actionState: ActionState.onError(true),
              });
            },
          })
        );
      }
    }
  }

  @Action(SetUser)
  public SetUser(context: StateContext<AppStateModel>, action: SetUser) {
    context.patchState({
      actionState: ActionState.onDone(),
      userIsLocked: action.isUserLocked ?? false,
      user: action.userDTO,
      appView:
        action.userDTO.user_company_type_id === CompanyTypeEnum.CO2_CUSTOMER
          ? AppViewEnum.CARBON_DIOXIDE
          : AppViewEnum.HYDROGEN,
    });

    if (action.isUserLocked) {
      // so the user is not in the dashboard or so
      this.router.navigate(['karte'], { relativeTo: this.activatedRoute });
    }
  }

  @Action(SetCookiesAccepted)
  public setCookiesToAccepted(
    context: StateContext<AppStateModel>,
    action: SetCookiesAccepted
  ) {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    context.patchState({
      actionState: ActionState.onDone(),
      cookiesAccepted: action.cookieAccepted,
    });
    localStorage.setItem(
      'hycoconnect-cookies-accepted',
      String(action.cookieAccepted)
    );
  }

  @Action(LoadFacilityList)
  public loadFacilityList(
    context: StateContext<AppStateModel>
  ): Observable<TGetFacilitiesDTO> {
    context.setState(
      patch({
        actionState: ActionState.onStart(),
      })
    );
    return this.facilityService.apiFacilityGet().pipe(
      tap({
        next: (response: TGetFacilitiesDTO) => {
          // IMPORTANT lat and lng is swapped in BE so switch the attributes
          if (response) {
            const list: TH2FacilityExtendetDTO[] | TCO2FacilityExtendetDTO[] =
              response.h2_facility_list.length > 0
                ? response.h2_facility_list
                : response.co2_facility_list;
            const newFacilityList:
              | TH2FacilityExtendetDTO[]
              | TCO2FacilityExtendetDTO[] = list.map(f => {
              return {
                ...f,
                facility_latitude: f.facility_longitude,
                facility_longitude: f.facility_latitude,
                facility_address: `${f.facility_city} ${f.facility_zip_code} ${f.facility_street} ${f.facility_street_number}`,
              };
            });

            context.patchState({
              ...context,
              actionState: ActionState.onDone(),
              facilityList: newFacilityList,
            });
          }
        },
        error: () => {
          context.patchState({
            actionState: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(SetSelectedFacilityId)
  public setSelectedFacilityId(
    context: StateContext<AppStateModel>,
    action: SetSelectedFacilityId
  ): void {
    const state = context.getState();
    context.patchState({
      actionState: ActionState.onDone(),
      selectedFacilityId: action.id,
      selectedFacility: action.id === null ? null : state.selectedFacility,
    });
  }

  @Action(LoadSelectedFacilityById)
  public loadSelectedFacilityById(
    context: StateContext<AppStateModel>,
    action: LoadSelectedFacilityById
  ): Observable<TGetFacilityDTO> | void {
    const state = context.getState();
    const facilityId = action.id ?? state.selectedFacilityId;
    if (facilityId) {
      context.patchState({
        actionState: ActionState.onStart(),
      });
      return this.facilityService.apiFacilityFacilityIdGet(facilityId).pipe(
        tap({
          next: (result: TGetFacilityDTO) => {
            const facility =
              result.h2_facility == null
                ? result.co2_facility
                : result.h2_facility;
            // IMPORTANT lat and lng is swapped in BE so switch the attributes
            const lat = facility.facility_latitude;
            facility.facility_latitude = facility.facility_longitude;
            facility.facility_longitude = lat;
            context.patchState({
              actionState: ActionState.onDone(),
              selectedFacility: facility,
            });
          },
          error: () => {
            context.patchState({
              actionState: ActionState.onError(true),
            });
          },
        })
      );
    }
  }

  @Action(DeleteFacilityById)
  public deleteFacilityById(
    context: StateContext<AppStateModel>,
    action: DeleteFacilityById
  ): Observable<any> {
    context.patchState({
      actionState: ActionState.onStart(),
    });
    return this.facilityService.apiFacilityIdDelete(action.id).pipe(
      tap({
        next: () => {
          context.patchState({
            actionState: ActionState.onDone(),
          });
          this.store.dispatch(new LoadFacilityList());
        },
        error: err => {
          context.patchState({
            actionState: ActionState.onError(true),
          });
          this.errorHandlingService.showError(err);
        },
      })
    );
  }

  @Action(SaveDocumentByType)
  public saveDocumentByType(
    context: StateContext<AppStateModel>,
    action: SaveDocumentByType
  ) {
    context.patchState({
      ...context,
      actionState: ActionState.onStart(),
    });
    return this.legalService.putDocument(action.type, action.file).pipe(
      tap({
        next: () => {
          context.patchState({
            ...context,
            actionState: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionState: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(UpdateUserInBE)
  public updateUserInBe(
    context: StateContext<AppStateModel>
  ): Observable<void> {
    const state = context.getState();
    const user = state.user;
    const azureUser = state.azureUser;
    if (azureUser && user.user_first_name && user.user_last_name) {
      if (
        azureUser.idTokenClaims['given_name'] !== user.user_first_name ||
        azureUser.idTokenClaims['family_name'] !== user.user_last_name
      ) {
        return this.userService.apiUserPut({
          user_first_name: azureUser.idTokenClaims['given_name'],
          user_last_name: azureUser.idTokenClaims['family_name'],
        } as TUserPutDTO);
      }
    }
    return of();
  }

  @Action(SetViewApp)
  public setViewApp(
    context: StateContext<AppStateModel>,
    action: SetViewApp
  ): void {
    context.patchState({
      ...context,
      actionState: ActionState.onDone(),
      appView: action.appView,
    });
  }

  @Action(SetUserMustCompleteRegistration)
  public setUserMustCompleteRegistration(
    context: StateContext<AppStateModel>
  ): void {
    context.patchState({
      ...context,
      actionState: ActionState.onDone(),
      userMustCompleteRegistration: true,
    });
  }
}
