export function enumToObject(enumType: any): Record<string, number | string> {
  return Object.keys(enumType).reduce(
    (acc, key) => {
      const value = enumType[key];
      if (typeof value === 'number') {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, number | string>
  );
}
