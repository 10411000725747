import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { ActionStateModel } from '@interfaces';
import { MapState } from '@pages/map/state/map.state';
import { MapStateModel } from '@pages/map/state/map-state.model';

@Injectable()
export class MapStateSelectors {
  @Selector([MapState])
  public static getMapActionState(state: MapStateModel): ActionStateModel {
    return state.actionState;
  }

  @Selector([MapState])
  public static isLoading(state: MapStateModel): boolean {
    return state.actionState.isLoading;
  }

  @Selector([MapState])
  public static getActiveDataTypes(state: MapStateModel): number[] {
    return state.activeDataTypes;
  }
}
