import { AfterViewInit, Directive, Input } from '@angular/core';
import { MapsGeocodingService } from '@map/services/maps-geocoding/maps-geocoding.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: 'app-map-zip-area',
})
export class MapZipAreaDirective implements AfterViewInit {
  @Input({ required: true })
  public map: google.maps.Map;
  @Input({ required: true })
  public zipCode: string;

  constructor(private readonly mapsGeocodingService: MapsGeocodingService) {}

  public async ngAfterViewInit(): Promise<void> {
    await this.mapsGeocodingService.drawZipCodeArea(this.map, this.zipCode);
  }
}
