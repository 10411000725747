import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthGuardService } from '../../features/authentication/services/auth-guard.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-impressum',
  templateUrl: './imprint.page.html',
  styleUrls: ['./imprint.page.scss'],
})
export class ImprintPage implements OnInit {
  constructor(private readonly authGuardService: AuthGuardService) {}

  public ngOnInit(): void {
    this.authGuardService.authGuardForUrl('impressum');
  }
}
