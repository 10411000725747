import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Observable, combineLatest, take } from 'rxjs';
import {
  TGetCO2Company,
  TGetH2Company,
  TLocationDTO,
  TPutCompanyDTO,
} from '@api';
import { CompanyData } from '@registration/interfaces';
import { SaveCompanyData, SaveDocumentByType } from '@appState/app.actions';
import { ActionStateModel } from '@interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT_TYPE_ENUM, LegalService } from '@services';
import { RegistrationService } from '@registration/services';
import { Co2RoleEnum } from '../../../shared/enums/co2-role-enum';

@UntilDestroy()
@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss'],
})
export class CompanyDataComponent implements OnInit {
  @Select(AppStateSelectors.isUserCo2Customer())
  public isUserCo2Customer$: Observable<boolean>;

  @Select(AppStateSelectors.getUsersCompany())
  public usersCompany$: Observable<TGetH2Company | TGetCO2Company>;

  @Select(AppStateSelectors.getAppActionState())
  public appActionState$: Observable<ActionStateModel>;

  @Select(AppStateSelectors.isUserVNB())
  public isUserVNB$: Observable<boolean>;

  public companyForm: FormGroup<CompanyData>;

  public imagesHasError = false;
  private fileReader = new FileReader();
  private readonly roleEnum = Co2RoleEnum;

  constructor(
    private readonly store: Store,
    private readonly registrationService: RegistrationService,
    private readonly legalService: LegalService,
    public dialogRef: MatDialogRef<CompanyDataComponent>
  ) {}

  public ngOnInit(): void {
    combineLatest([this.isUserVNB$, this.isUserCo2Customer$])
      .pipe(take(1))
      .subscribe(([isUserVnb, isUserCo2Customer]) => {
        this.companyForm = new FormGroup<CompanyData>({
          companyName: new FormControl('', [Validators.required]),
          companyAddress:
            this.registrationService.buildAddressForm(isUserCo2Customer),
        });
        if (isUserVnb) {
          this.companyForm.addControl(
            'zipCodeArea',
            new FormControl([], [Validators.required])
          );
          this.companyForm.addControl(
            'fileLogo',
            new FormControl('', [Validators.required])
          );
          this.companyForm.addControl(
            'filePolicy',
            new FormControl('', [Validators.required])
          );
        }

        if (isUserCo2Customer) {
          this.companyForm.addControl(
            'contactFirstname',
            new FormControl('', [Validators.required])
          );
          this.companyForm.addControl(
            'contactLastname',
            new FormControl('', [Validators.required])
          );
          this.companyForm.addControl(
            'contactTelephone',
            new FormControl('', [Validators.required])
          );
          this.companyForm.addControl('contactFunction', new FormControl(''));
          this.companyForm.addControl(
            'role',
            this.registrationService.buildCo2RoleGroup()
          );
        }

        this.fillCompanyData();
      });
  }

  public save(): void {
    let changes: TPutCompanyDTO = {};

    const rawValue = this.companyForm.getRawValue();
    const location = rawValue.companyAddress;
    const baseCompanyData = {
      company_name: rawValue.companyName,
      company_location_city: location.city,
      // Change lat/lng for BE
      company_location_latitude: location.lng,
      company_location_longitude: location.lat,
      company_location_street: location.street,
      company_location_street_number: location.streetNumber,
      company_location_zip_code: location.zipCode,
    };
    if (Object.hasOwn(rawValue, 'contactFirstname')) {
      // is co2
      changes = {
        co2_company: {
          ...baseCompanyData,
          contact_first_name: rawValue.contactFirstname,
          contact_last_name: rawValue.contactLastname,
          contact_telephone: rawValue.contactTelephone,
          contact_function: rawValue.contactFunction,
          company_roles_list: Object.keys(rawValue.role)
            .filter(r => {
              const control = this.companyForm.controls.role.get(r);
              return !!control.getRawValue();
            })
            .map(r => {
              return this.roleEnum[r.toUpperCase()];
            }),
        },
      };
    } else {
      // h2
      changes = {
        h2_company: {
          ...baseCompanyData,
          company_zip_code_list: rawValue.zipCodeArea,
        },
      };
    }
    this.store.dispatch(new SaveCompanyData(changes));

    if (
      this.companyForm.contains('filePolicy') &&
      this.companyForm.controls.filePolicy.getRawValue() !== ''
    ) {
      this.store.dispatch(
        new SaveDocumentByType(DOCUMENT_TYPE_ENUM.DATENSCHUTZERKLAERUNG, {
          document_string: this.companyForm.controls.filePolicy.getRawValue(),
          document_description: 'Datenschutzerklärung',
        })
      );
    }

    if (
      this.companyForm.contains('fileLogo') &&
      this.companyForm.controls.fileLogo.getRawValue() !== ''
    ) {
      this.store.dispatch(
        new SaveDocumentByType(DOCUMENT_TYPE_ENUM.LOGO, {
          document_string: this.companyForm.controls.fileLogo.getRawValue(),
          document_description: 'Kunden-Logo',
        })
      );
    }

    this.appActionState$.pipe(untilDestroyed(this)).subscribe(actionState => {
      if (actionState.done) {
        this.dialogRef.close();
      }
    });
  }

  private fillCompanyData() {
    this.usersCompany$
      .pipe(take(1))
      .subscribe((company: TGetH2Company | TGetCO2Company) => {
        if (company) {
          const location: TLocationDTO = company.company_location;
          this.companyForm.patchValue({
            companyName: company.company_name,
            companyAddress: {
              address: `${location.location_street} ${location.location_street_number}, ${location.location_zip_code} ${location.location_city}`,
              city: location.location_city,
              // Change lat/lng because of BE
              lat: location.location_longitude,
              lng: location.location_latitude,
              street: location.location_street,
              streetNumber: location.location_street_number,
              zipCode: location.location_zip_code,
            },
          });
          if (Object.hasOwn(company, 'vnb_zip_codes')) {
            this.companyForm.controls.zipCodeArea.setValue([
              ...(company as TGetH2Company).vnb_zip_codes,
            ]);
          } else {
            // is co2 company
            this.companyForm.controls.contactFirstname.setValue(
              (company as TGetCO2Company).contact_first_name
            );
            this.companyForm.controls.contactLastname.setValue(
              (company as TGetCO2Company).contact_last_name
            );
            this.companyForm.controls.contactTelephone.setValue(
              (company as TGetCO2Company).contact_telephone
            );
            this.companyForm.controls.contactFunction.setValue(
              (company as TGetCO2Company).contact_function
            );
          }
          (company as TGetCO2Company).company_roles_list?.map(value => {
            const key = this.roleEnum[value].toLowerCase();
            if (this.companyForm.controls.role.contains(key)) {
              this.companyForm.controls.role.get(key).setValue(true);
            }
          });
          this.companyForm.updateValueAndValidity();
        }
      });
  }

  public onSelectedCompanyLogo(file: File): void {
    this.fileReader.onload = () => {
      const img = new Image();
      if (this.fileReader.result) {
        img.src = this.fileReader.result.toString();
        img.onload = () => {
          this.imagesHasError =
            img.width < 50 ||
            img.width > 500 ||
            img.height < 50 ||
            img.height > 500;

          if (!this.imagesHasError) {
            const base64StringFile = this.fileReader.result
              ?.toString()
              .replace(/^data:(.*,)?/, '');
            this.companyForm.get('fileLogo')?.setValue(base64StringFile);
          }
        };
      }
    };
    this.fileReader.readAsDataURL(file);
  }

  public getPolicy(): void {
    const company = this.store.selectSnapshot(
      AppStateSelectors.getUsersCompany()
    );
    this.legalService.getPolicy(company.company_id).subscribe(data => {
      this.legalService.openPDF(data.document_string, 'Datenschutzerklärung');
    });
  }
}
