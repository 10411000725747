import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ErrorHandlingService, LegalService, NumericService } from '@services';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FacilityService,
  TDokumentDTO,
  TGetH2FacilityDTO,
  TNameundIdDTO,
  TPostFacilityAndPlannedH2AmountDTO,
} from '@api';
import { FacilityH2Type, UnitTypeForBackendEnum } from '@enums';
import { DashboardService } from '@pages/dashboard/services/dashboard.service';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from 'rxjs';
import {
  LoadFacilityList,
  LoadSelectedFacilityById,
  SetSelectedFacilityId,
} from '@appState/app.actions';
import { MatDialogRef } from '@angular/material/dialog';
import {
  DefaultUnitTypeEnum,
  H2FacilityForm,
  RegisterFacilityModel,
  RegisterH2FacilityForm,
  UnitForm,
} from '../../interfaces/register_location';
import { AppStateSelectors } from '@appState/app-state.selector';
import { DashboardSelectors } from '@pages/dashboard/state/dashboard.selectors';
import { RegistrationService } from '@registration/services/registration.service';
import { ActionStateModel } from '@interfaces';

@UntilDestroy()
@Component({
  selector: 'app-register-h2-location',
  templateUrl: './register-h2-location.component.html',
  styleUrls: ['./register-h2-location.component.scss'],
})
export class RegisterH2LocationComponent implements OnInit, OnDestroy {
  public readonly facilityType = FacilityH2Type;
  public readonly defaultUnitTypeEnum = DefaultUnitTypeEnum;
  public currentPage: 1 | 2 = 1;
  public errorYears: string[] = [];
  public form: FormGroup<RegisterH2FacilityForm>;

  @Select(AppStateSelectors.isUserH2Customer())
  public isUserCustomer$: Observable<boolean>;
  @Select(DashboardSelectors.getVNBList)
  public readonly vnbs$: Observable<TNameundIdDTO[]>;

  public isReadonly = false;
  private readonly today = new Date('2023-12-31');
  // 15 Jahre - 12 Jahre hinter einander, dann 3x plus 5 Jahre
  public years: number[] = [
    this.today.getFullYear() + 1,
    this.today.getFullYear() + 2,
    this.today.getFullYear() + 3,
    this.today.getFullYear() + 4,
    this.today.getFullYear() + 5,
    this.today.getFullYear() + 6,
    this.today.getFullYear() + 7,
    this.today.getFullYear() + 8,
    this.today.getFullYear() + 9,
    this.today.getFullYear() + 10,
    this.today.getFullYear() + 11,
    this.today.getFullYear() + 12,
    this.today.getFullYear() + 17,
    this.today.getFullYear() + 22,
    this.today.getFullYear() + 27,
  ];
  @Select(AppStateSelectors.getSelectedFacility())
  private readonly facility$: Observable<TGetH2FacilityDTO>;
  @Select(AppStateSelectors.getAppActionState())
  public actionState$: Observable<ActionStateModel>;

  private vnbPolicy: TDokumentDTO;
  private _facility: TGetH2FacilityDTO;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly dashboardService: DashboardService,
    private readonly registrationService: RegistrationService,
    private readonly legalService: LegalService,
    private readonly facilityService: FacilityService,
    private readonly store: Store,
    private readonly numericService: NumericService,
    private readonly errorHandlingService: ErrorHandlingService,
    public dialogRef: MatDialogRef<RegisterH2LocationComponent>
  ) {}

  public get facilityForm(): FormGroup<H2FacilityForm> {
    return this.form.controls.facility as FormGroup;
  }

  public get capacityForm(): FormGroup<UnitForm> {
    return this.form.controls.capacity as FormGroup;
  }

  public get energyForm(): FormGroup<UnitForm> {
    return this.form.controls.energy as FormGroup;
  }

  public get legalControl(): FormControl {
    return this.form.controls.legal as FormControl;
  }

  public get capacityUnitControl(): FormControl {
    return this.capacityForm.controls.unit;
  }

  public get capacityYearsForm(): FormGroup {
    return this.capacityForm.controls.years;
  }

  public get energyYearsForm(): FormGroup {
    return this.energyForm.controls.years;
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      facility: this.formBuilder.group({
        requirementProduction: [
          this.facilityType.Consumer,
          [Validators.required],
        ],
        facilityName: ['', [Validators.required]],
        companyAddress: this.registrationService.buildAddressForm(),
        maloId: this.formBuilder.nonNullable.control(null),
      }),
      capacity: this.buildFormGroupForYears(true),
      energy: this.buildFormGroupForYears(false),
      vnb: [0, [Validators.required, Validators.min(1)]],
      legal: [false, [Validators.requiredTrue]],
      policy: [false],
    });

    this.capacityUnitControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(unit => {
        this.onRadioButtonChange(unit);
      });

    this.facility$
      .pipe(untilDestroyed(this))
      .subscribe((loadedFacility: TGetH2FacilityDTO) => {
        if (loadedFacility) {
          this.isReadonly = true;
          this.form.disable();
          this.capacityUnitControl.enable();
          this._facility = loadedFacility;
          this.fillFormWithFacilityData(loadedFacility);
        } else {
          this.store.dispatch(new LoadSelectedFacilityById());
        }
      });

    this.form.controls.vnb.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(() => this.currentPage === 2),
        switchMap(vnb => {
          return this.registrationService.getPolicy(vnb);
        }),
        tap((policy: TDokumentDTO) => {
          this.vnbPolicy = policy;
          if (this.vnbPolicy) {
            this.form.controls.policy.setValidators(Validators.requiredTrue);
          } else {
            this.form.controls.policy.clearValidators();
          }
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    // clean selected facility
    this.store.dispatch(new SetSelectedFacilityId(null));
  }

  public onCreate(): void {
    const h2_facility = this.mapRegisterLocationToApiModel(
      this.form.getRawValue()
    );
    if (!!this._facility) {
      this.facilityService
        .apiFacilityPut({
          h2_facility: {
            ...h2_facility,
            facility_id: this._facility.facility_id,
          },
        })
        .subscribe({
          next: () => {
            this.dialogRef.close();
            this.store.dispatch(new LoadFacilityList());
          },
          error: err => this.errorHandlingService.showError(err),
        });
    } else {
      // NEW facility
      this.facilityService
        .apiFacilityPost({
          h2_facility,
        })
        .subscribe({
          next: () => {
            this.dialogRef.close();
            this.dashboardService.initDashboard();
          },
          error: err => this.errorHandlingService.showError(err),
        });
    }
  }

  public openPolicy(): void {
    this.legalService.openPDF(this.vnbPolicy.document_string);
  }

  public isCurrentPageSecondOneAndVnbHasPolicy(): boolean {
    return this.currentPage === 2 && !!this.vnbPolicy;
  }

  public backToStart(): void {
    this.currentPage = 1;
    this.form.controls.vnb.reset(0);
  }

  public checkFormValidation(): void {
    // Add Validation for MaloId
    this.form.controls.facility.controls.maloId.setValidators([
      Validators.minLength(11),
      Validators.maxLength(11),
      Validators.pattern('\\d{11}'),
    ]);
    this.form.controls.facility.controls.maloId.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    // check years formControls
    this.checkHoursOfUse();

    if (
      this.energyYearsForm.valid &&
      this.capacityYearsForm.valid &&
      this.facilityForm.valid
    ) {
      this.nextPage();
    }
  }

  public changeIsReadonly(): void {
    this.isReadonly = !this.isReadonly;
    this.form.enable();
    this.capacityUnitControl.disable();
  }

  private onRadioButtonChange(unit: DefaultUnitTypeEnum) {
    switch (unit) {
      case DefaultUnitTypeEnum.MWH_H:
        this.form.controls.energy.get('unit').setValue(DefaultUnitTypeEnum.MWH);
        if (!!this._facility) {
          this.capacityYearsForm.patchValue(
            this.processCorrectYearValueForFrontend(
              this._facility.facility_plan.facility_values_power_kwh,
              DefaultUnitTypeEnum.MWH
            )
          );
          this.energyYearsForm.patchValue(
            this.processCorrectYearValueForFrontend(
              this._facility.facility_plan.facility_values_energy_kwh,
              DefaultUnitTypeEnum.MWH
            )
          );
        }
        break;
      case DefaultUnitTypeEnum.KWH_H:
        this.form.controls.energy.get('unit').setValue(DefaultUnitTypeEnum.KWH);
        if (!!this._facility) {
          this.capacityYearsForm.patchValue(
            this.processCorrectYearValueForFrontend(
              this._facility.facility_plan.facility_values_power_kwh
            )
          );
          this.energyYearsForm.patchValue(
            this.processCorrectYearValueForFrontend(
              this._facility.facility_plan.facility_values_energy_kwh
            )
          );
        }
        break;
      case DefaultUnitTypeEnum.KG_H:
        this.form.controls.energy.get('unit').setValue(DefaultUnitTypeEnum.KG);
        if (!!this._facility) {
          this.capacityYearsForm.patchValue(
            this.processCorrectYearValueForFrontend(
              this._facility.facility_plan.facility_values_power_kg
            )
          );
          this.energyYearsForm.patchValue(
            this.processCorrectYearValueForFrontend(
              this._facility.facility_plan.facility_values_energy_kg
            )
          );
        }
        break;
    }
  }

  private checkHoursOfUse(): void {
    // Nutzungstunden = Energie / Kapazität <= 8760h
    // Energie > Power * 8760
    const hoursOfUse = 8760;
    this.errorYears = [];
    this.energyYearsForm.setErrors(null);
    Object.keys(this.energyYearsForm.controls).forEach(key => {
      const energy = this.energyYearsForm.get(key.toString());
      const capacity = this.capacityYearsForm.get(key.toString());
      // reset errors
      energy.setErrors(null);
      capacity.setErrors(null);
      // replace separator
      energy.setValue(
        this.numericService.cleanFormatting(energy.value.toString())
      );
      capacity.setValue(
        this.numericService.cleanFormatting(capacity.value.toString())
      );

      const isEnergyHigher = energy.value > capacity.value * hoursOfUse;
      const isCapacityZero = energy.value > 0 && capacity.value == 0;
      if (isCapacityZero || isEnergyHigher) {
        this.errorYears.push(key);
        energy.setErrors({ hoursOfUse: true });
        capacity.setErrors({ hoursOfUse: true });
      }
    });
  }

  private fillFormWithFacilityData(data: TGetH2FacilityDTO) {
    this.form.setValue({
      facility: {
        requirementProduction: data.facility_type_id,
        facilityName: data.facility_name,
        companyAddress: {
          address: `${data.facility_street} ${data.facility_street_number} ${data.facility_zip_code} ${data.facility_city}`,
          street: data.facility_street,
          streetNumber: data.facility_street_number,
          zipCode: data.facility_zip_code,
          city: data.facility_city,
          lat: data.facility_latitude,
          lng: data.facility_longitude,
        },
        maloId:
          data.facility_malo_id === null ? null : Number(data.facility_malo_id),
      },
      capacity: {
        unit: this.defaultUnitTypeEnum.MWH_H,
        years: this.processCorrectYearValueForFrontend(
          data.facility_plan.facility_values_power_kwh,
          this.defaultUnitTypeEnum.MWH_H
        ),
      },
      energy: {
        unit: this.defaultUnitTypeEnum.MWH,
        years: this.processCorrectYearValueForFrontend(
          data.facility_plan.facility_values_energy_kwh,
          this.defaultUnitTypeEnum.MWH
        ),
      },
      vnb: data.facility_related_vnb_id,
      legal: false,
      policy: false,
    });
    this.onRadioButtonChange(this.defaultUnitTypeEnum.MWH_H);
  }

  private buildFormGroupForYears(isCapacity: boolean): FormGroup {
    return this.formBuilder.group<UnitForm>({
      unit: new FormControl(
        isCapacity
          ? this.defaultUnitTypeEnum.MWH_H
          : this.defaultUnitTypeEnum.MWH
      ),
      years: this.yearsToFormGroup(isCapacity),
    });
  }

  private yearsToFormGroup(isCapacity: boolean) {
    const years = {};

    this.years.forEach(year => {
      years[year] = new FormControl(0, [Validators.required]) as FormControl;
    });
    return new FormGroup(years);
  }

  private nextPage() {
    this.vnbs$.pipe(take(1)).subscribe(vnb => {
      if (vnb.length === 0) {
        this.legalControl.clearValidators();
      }
    });
    this.currentPage = 2;
  }

  private mapRegisterLocationToApiModel(
    formData: RegisterFacilityModel
  ): TPostFacilityAndPlannedH2AmountDTO {
    const address = formData.facility.companyAddress;
    let capacityYears = Object.values(formData.capacity.years).map(year =>
      Number(year)
    );
    let energyYears = Object.values(formData.energy.years).map(year =>
      Number(year)
    );
    if (this.capacityUnitControl.value == DefaultUnitTypeEnum.MWH_H) {
      capacityYears = this.calculateCorrectYearValueForBackend(capacityYears);
      energyYears = this.calculateCorrectYearValueForBackend(energyYears);
    }
    return {
      facility_name: formData.facility.facilityName,
      facility_type_id: formData.facility.requirementProduction,
      facility_city: address.city,
      // IM BE ist es falsch deswegen vertauschen!!!
      facility_latitude: address.lng,
      facility_longitude: address.lat,
      facility_street: address.street,
      facility_street_number: address.streetNumber,
      facility_zip_code: address.zipCode,
      facility_related_vnb_id: formData.vnb > 2 ? formData.vnb : 2,
      facility_malo_id: formData.facility.maloId?.toString(),
      facility_plan: {
        facility_unit_typ_for_power_array:
          formData.capacity.unit === this.defaultUnitTypeEnum.KG_H
            ? UnitTypeForBackendEnum.KG_H
            : UnitTypeForBackendEnum.KWH_H,
        facility_values_power: capacityYears,
        facility_unit_typ_for_energy_array:
          formData.energy.unit === this.defaultUnitTypeEnum.KG
            ? UnitTypeForBackendEnum.KG
            : UnitTypeForBackendEnum.KWH,
        facility_values_energy: energyYears,
      },
    };
  }

  private calculateCorrectYearValueForBackend(yearsGroup) {
    let years = yearsGroup;
    years = Object.values(years).map((year: number) => year * 1000);
    return years;
  }

  private processCorrectYearValueForFrontend(
    data: number[],
    unitType?: DefaultUnitTypeEnum
  ) {
    return data.reduce((obje, currentValue, currentIndex) => {
      const year = this.years[currentIndex];
      return {
        ...obje,
        [year]:
          unitType == DefaultUnitTypeEnum.MWH
            ? currentValue / 1000
            : currentValue,
      };
    }, {});
  }
}
