import { Injectable } from '@angular/core';
import { environment } from '@env';

@Injectable()
export class NumericService {
  private locale = environment.defaultLang === 'de' ? 'de-DE' : 'en-US';
  private replaceRegex = environment.defaultLang === 'de' ? ',' : '.';
  private replaceString = environment.defaultLang === 'de' ? '.' : ',';

  public numberFormat(value: number): string {
    return new Intl.NumberFormat(this.locale, {
      maximumFractionDigits: 2,
    }).format(value);
  }

  public cleanFormatting(value: string): string {
    return value.toString().replaceAll('.', '').replaceAll(',', '.');
  }
}
