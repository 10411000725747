/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TPostCO2UserDTO { 
    user_first_name?: string | null;
    user_last_name?: string | null;
    user_mail?: string | null;
    user_b2c_object_id?: string | null;
    company_id?: number;
    company_name?: string | null;
    company_city?: string | null;
    company_latitude?: number | null;
    company_longitude?: number | null;
    company_street?: string | null;
    company_street_number?: string | null;
    company_zip_code?: string | null;
    company_contact_first_name?: string | null;
    company_contact_last_name?: string | null;
    company_contact_telephone?: string | null;
    company_contact_function?: string | null;
    company_roles_list?: Array<number> | null;
}

