import { Action, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  CompanyExportService,
  CompanyTableService,
  FacilityExportService,
  OgeVnbExportService,
  OgeVnbSummaryExportService,
  OgeVnbTableService,
  TCompanyTableDTO,
} from '@api';
import { ActionState } from '@interfaces';
import { DataTableStateModel } from '@pages/data-table/state/date-table-state.model';
import {
  ExportCompanyTable,
  ExportFacilityTable,
  GetCompanyTable,
  LoadVNBDisplayData,
  OGEExportVNB,
  OGEExportVNBSummary,
  SetActiveTable,
} from '@pages/data-table/state/data-table.actions';
import { patch } from '@ngxs/store/operators';

const DATA_TABLE_STATE_TOKEN: StateToken<DataTableStateModel> = new StateToken(
  'dataTableState'
);

@State<DataTableStateModel>({
  name: DATA_TABLE_STATE_TOKEN,
  defaults: {
    selectedActiveType: null,
    actionState: ActionState.create(),
    companyTable: [],
    actionStateCompanyCSV: ActionState.create(),
    exportCompanyCSVString: null,
    actionStateFacilityCSV: ActionState.create(),
    exportFacilityCSVString: null,
    actionStateOGEVNBData: ActionState.create(),
    vnbData: null,
    actionStateOGEVNBCSV: ActionState.create(),
    exportVNBCSVString: null,
    actionStateOGEVNBSummaryCSV: ActionState.create(),
    exportVNBSummaryCSVString: null,
  },
})
@Injectable()
export class DataTableState {
  constructor(
    private readonly companyTableService: CompanyTableService,
    private readonly facilityExportService: FacilityExportService,
    private readonly companyExportService: CompanyExportService,
    private readonly ogeVnbExportService: OgeVnbExportService,
    private readonly ogeVnbSummaryExportService: OgeVnbSummaryExportService,
    private readonly ogeVNBTable: OgeVnbTableService
  ) {}

  @Action(GetCompanyTable)
  public getCompanyTable(
    context: StateContext<DataTableStateModel>
  ): Observable<TCompanyTableDTO> {
    context.patchState({
      ...context,
      actionState: ActionState.onStart(),
    });
    return this.companyTableService.apiCompanyTableGet().pipe(
      tap({
        next: (result: TCompanyTableDTO) => {
          let list =
            result.h2_company_list.length > 0
              ? result.h2_company_list
              : result.co2_company_list;
          list = list.map(i => {
            return {
              ...i,
              company_address: `${i.company_city} ${i.company_zip_code} ${i.company_street} ${i.company_street_number}`,
            };
          });
          context.patchState({
            ...context,
            companyTable: list,
            actionState: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionState: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(SetActiveTable)
  public setActiveTable(
    context: StateContext<DataTableStateModel>,
    action: SetActiveTable
  ): void {
    context.setState(
      patch({
        selectedActiveType: action.type,
      })
    );
  }

  @Action(ExportFacilityTable)
  public exportFacilityTable(context: StateContext<DataTableStateModel>) {
    context.patchState({
      ...context,
      actionStateFacilityCSV: ActionState.onStart(),
    });
    return this.facilityExportService.apiFacilityExportGet().pipe(
      tap({
        next: (csvString: string) => {
          context.patchState({
            ...context,
            exportFacilityCSVString: csvString,
            actionStateFacilityCSV: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionStateFacilityCSV: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(ExportCompanyTable)
  public exportCompanyTable(
    context: StateContext<DataTableStateModel>,
    action: ExportCompanyTable
  ) {
    context.patchState({
      ...context,
      actionStateCompanyCSV: ActionState.onStart(),
    });
    const exportBrille = action?.glasses ?? 0;
    return this.companyExportService.apiCompanyExportGet(exportBrille).pipe(
      tap({
        next: (csvString: string) => {
          context.patchState({
            ...context,
            exportCompanyCSVString: csvString,
            actionStateCompanyCSV: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionStateCompanyCSV: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(OGEExportVNB)
  public oGEExportVNB(context: StateContext<DataTableStateModel>) {
    return this.ogeVnbExportService.apiOgeVnbExportGet().pipe(
      tap({
        next: (csvString: string) => {
          context.patchState({
            ...context,
            exportVNBCSVString: csvString,
            actionStateOGEVNBCSV: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionStateOGEVNBCSV: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(OGEExportVNBSummary)
  public oGEExportVNBSummary(context: StateContext<DataTableStateModel>) {
    return this.ogeVnbSummaryExportService.apiOgeVnbSummaryExportGet().pipe(
      tap({
        next: (csvString: string) => {
          context.patchState({
            ...context,
            exportVNBSummaryCSVString: csvString,
            actionStateOGEVNBSummaryCSV: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionStateOGEVNBSummaryCSV: ActionState.onError(true),
          });
        },
      })
    );
  }

  @Action(LoadVNBDisplayData)
  public loadVNBDisplayData(context: StateContext<DataTableStateModel>) {
    context.patchState({
      ...context,
      actionStateOGEVNBData: ActionState.onStart(),
    });
    return this.ogeVNBTable.apiOgeVnbTableGet().pipe(
      tap({
        next: data => {
          context.patchState({
            ...context,
            vnbData: data,
            actionStateOGEVNBData: ActionState.onDone(),
          });
        },
        error: () => {
          context.patchState({
            ...context,
            actionStateOGEVNBData: ActionState.onError(true),
          });
        },
      })
    );
  }
}
