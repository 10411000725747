import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-registration-personal-data',
  templateUrl: './registration-personal-data.component.html',
  styleUrls: ['./registration-personal-data.component.scss'],
})
export class RegistrationPersonalDataComponent implements OnInit {
  public form: FormGroup;

  public constructor(private readonly parentFormGroup: FormGroupDirective) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
  }
}
