import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, combineLatest, switchMap, take } from 'rxjs';
import {
  EExportBrille,
  TCO2CompanyDTO,
  TCO2FacilityExtendetDTO,
  TH2CompanyDTO,
  TH2FacilityExtendetDTO,
  VOgeVnb,
} from '@api';
import { Select, Store } from '@ngxs/store';
import {
  ExportCompanyTable,
  ExportFacilityTable,
  GetCompanyTable,
  OGEExportVNB,
  OGEExportVNBSummary,
  SetActiveTable,
} from '@pages/data-table/state/data-table.actions';
import { MatSort, Sort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  fromMatSort,
  sortRows,
} from '../../features/table-tiles/services/sort-helper';
import { AppStateSelectors } from '@appState/app-state.selector';
import { DataTableType } from '../../shared/enums/data-table-type-enum';
import { LoadFacilityList } from '@appState/app.actions';
import { FacilityCo2Type, FacilityH2Type } from '@enums';
import { FileService } from '../../shared/services/file.service';
import { filterItemsForSearch } from '../../shared/filter-items-for-search';
import { DataTableStateSelectors } from '@pages/data-table/state/data-table.state.selectors';
import { AuthGuardService } from '../../features/authentication/services/auth-guard.service';
import { AppViewEnum } from '../../shared/enums/app-view-enum';
import { ActionState } from '@interfaces';
import {
  co2CustomerTableHeader,
  customerH2FacilityTableHeader,
  ogeCompanyCo2TableHeader,
  ogeFacilityCo2TableHeader,
  ogeVNBTableHeader,
  vnbCompanyTableHeader,
  vnbH2FacilityTableHeader,
} from '@pages/data-table-headers';

@UntilDestroy()
@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.page.component.html',
  styleUrls: ['./data-table.page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTablePageComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Select(DataTableStateSelectors.actionStateDone)
  public readonly done$: Observable<boolean>;
  @Select(DataTableStateSelectors.isLoading)
  public readonly isLoading$: Observable<boolean>;
  @Select(DataTableStateSelectors.getTableType)
  public readonly getTableType$: Observable<DataTableType>;

  @Select(DataTableStateSelectors.isLoadingCompanyCSV)
  public readonly isLoadingCompanyCSV$: Observable<boolean>;
  @Select(DataTableStateSelectors.getCompanyTable)
  public readonly companyTable$: Observable<TCO2CompanyDTO[] | TH2CompanyDTO[]>;
  @Select(DataTableStateSelectors.getExportCompanyCSVString)
  public readonly getExportCompanyCSVString$: Observable<string>;

  @Select(DataTableStateSelectors.isLoadingFacilityCSV)
  public readonly isLoadingFacilityCSV$: Observable<boolean>;
  @Select(AppStateSelectors.getFacilityList())
  public readonly facilityList$: Observable<
    TH2FacilityExtendetDTO[] | TCO2FacilityExtendetDTO[]
  >;
  @Select(DataTableStateSelectors.getExportFacilityCSVString)
  public readonly getExportFacilityCSVString$: Observable<string>;

  @Select(DataTableStateSelectors.isLoadingOGEVNBData())
  public readonly actionStateOGEVNBData$: Observable<ActionState>;
  @Select(DataTableStateSelectors.vnbData())
  public readonly vnbData$: Observable<VOgeVnb[]>;

  @Select(DataTableStateSelectors.isLoadingOGEVNBCSV())
  public readonly isLoadingOGEVNBCSV$: Observable<boolean>;
  @Select(DataTableStateSelectors.exportVNBCSVString())
  public readonly getExportVNBCSVString$: Observable<string>;

  @Select(DataTableStateSelectors.isLoadingOGEVNBSummaryCSV())
  public readonly isLoadingOGEVNBSummaryCSV$: Observable<boolean>;
  @Select(DataTableStateSelectors.exportVNBSummaryCSVString())
  public readonly getExportVNBSummaryCSVString$: Observable<string>;

  @Select(AppStateSelectors.isUserLoggedIn())
  public isUserLoggedIn$: Observable<boolean>;

  @Select(AppStateSelectors.isUserVNB())
  public isVNB$: Observable<boolean>;

  @Select(AppStateSelectors.isUserOGE())
  public isUserOGE$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public isUserCo2Customer$: Observable<boolean>;

  @Select(AppStateSelectors.getAppView())
  public readonly appView$: Observable<AppViewEnum>;

  public searchControl: FormControl = new FormControl<string>('');
  public sortedCompanyTable$: Observable<TH2CompanyDTO[] | TCO2CompanyDTO[]>;
  public sortedFacilityTable$: Observable<
    TH2FacilityExtendetDTO[] | TCO2FacilityExtendetDTO[]
  >;
  public sortedVnbTable$: Observable<VOgeVnb[]>;

  public companyTable: TH2CompanyDTO[] | TCO2CompanyDTO[] = [];
  public facilityTable: TH2FacilityExtendetDTO[] | TCO2FacilityExtendetDTO[] =
    [];
  public dataTableType = DataTableType;
  public vnbTable: VOgeVnb[] = [];

  public vnbCompanyTable = vnbCompanyTableHeader;
  public vnbH2FacilityTable = vnbH2FacilityTableHeader;
  public customerH2FacilityTable = customerH2FacilityTableHeader;
  public co2CustomerTable = co2CustomerTableHeader;
  public ogeVNBTable = ogeVNBTableHeader;
  public ogeFacilityCo2Table = ogeFacilityCo2TableHeader;
  public ogeCompanyCo2Table = ogeCompanyCo2TableHeader;

  public appView: AppViewEnum = AppViewEnum.HYDROGEN;

  public constructor(
    private readonly fileService: FileService,
    private readonly store: Store,
    private readonly authGuardService: AuthGuardService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public get isAppViewCo2$(): Observable<boolean> {
    return this.appView$.pipe(
      take(1),
      map(view => view === AppViewEnum.CARBON_DIOXIDE)
    );
  }

  public get isEmptyValue(): boolean {
    return (
      this.searchControl.value == null || this.searchControl.value.length == 0
    );
  }

  public ngOnInit(): void {
    this.authGuardService.authGuardWithRedirect();

    this.isUserLoggedIn$
      .pipe(
        filter(isUserLoggedIn => isUserLoggedIn),
        untilDestroyed(this),
        switchMap(() =>
          combineLatest([this.isVNB$, this.isUserOGE$, this.isAppViewCo2$])
        )
      )
      .subscribe(([isVNB, isOOGE, isAppViewCo2]) => {
        this.store.dispatch(new LoadFacilityList());
        if (isVNB || (isOOGE && isAppViewCo2))
          this.store.dispatch(new GetCompanyTable());
      });
    this.setTable();
  }

  public ngAfterViewInit() {
    this.setTable();
    this.searchControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this.setTable();
        if ((value != null && value.length > 2) || this.isNumber(value)) {
          // for oge
          if (this.vnbTable != null) {
            this.vnbTable = filterItemsForSearch(
              this.vnbTable,
              [
                'vnbName',
                'vnbLocationCity',
                'vnbLocationStreet',
                'vnbLocationStreetNumber',
                'vnbLocationZipCode',
              ],
              value,
              null
            );
          }
          // for vnb
          if (this.companyTable != null) {
            this.companyTable = filterItemsForSearch(
              this.companyTable,
              [
                'company_name',
                'company_city',
                'company_street',
                'company_street_number',
                'company_zip_code',
                'company_facility_count',
                'company_quelle_facility_count',
                'company_senke_facility_count',
                'company_hybrid_facility_count',
              ],
              value
            );
          }
          // for vnb and customer || oge co2
          if (this.facilityTable != null) {
            const isCo2User = this.store.selectSnapshot(
              AppStateSelectors.isUserCo2Customer()
            );
            const isUserVNB = this.store.selectSnapshot(
              AppStateSelectors.isUserVNB()
            );
            const isUserOge = this.store.selectSnapshot(
              AppStateSelectors.isUserOGE()
            );
            const baseColumns = [
              'facility_name',
              'facility_city',
              'facility_street',
              'facility_street_number',
              'facility_zip_code',
              'facility_type_id',
            ];
            this.facilityTable = filterItemsForSearch(
              this.facilityTable,
              isUserOge
                ? [
                    ...baseColumns,
                    'facility_first_year_of_transport_capability',
                    'facility_company_name',
                  ]
                : isCo2User
                  ? [
                      ...baseColumns,
                      'facility_first_year_of_transport_capability',
                    ]
                  : isUserVNB
                    ? [
                        ...baseColumns,
                        'facility_malo_id',
                        'facility_company_name',
                      ]
                    : [...baseColumns, 'facility_malo_id'],
              value,
              {
                expectedString: isCo2User
                  ? ['einspeisung', 'ausspeisung', 'hybrid']
                  : ['bedarf', 'erzeugung'],
                expectedKey: 'facility_type_id',
                expectedEnum: isCo2User ? FacilityCo2Type : FacilityH2Type,
              }
            );
          }
        }
        this.changeDetectorRef.detectChanges();
      });

    // for updates e.g. delete facility update the list for the view
    this.facilityList$.pipe(untilDestroyed(this)).subscribe(list => {
      this.facilityTable = list;
      this.setTable();
      this.changeDetectorRef.detectChanges();
    });
  }

  public ngOnDestroy() {
    this.store.dispatch(new SetActiveTable(null));
  }

  public resetSearch() {
    this.setTable();
    this.searchControl.reset();
  }

  public exportDataAsCSV() {
    const isUserVNB = this.store.selectSnapshot(AppStateSelectors.isUserVNB());
    const isUserOGE = this.store.selectSnapshot(AppStateSelectors.isUserOGE());
    const appView = this.store.selectSnapshot(AppStateSelectors.getAppView());

    if (!isUserOGE) {
      this.store.dispatch(new ExportFacilityTable());
      this.isLoadingAnyGivenCSV(
        this.isLoadingFacilityCSV$,
        this.getExportFacilityCSVString$,
        'daten-standorte'
      );
      if (isUserVNB) {
        this.store.dispatch(new ExportCompanyTable());
        this.isLoadingAnyGivenCSV(
          this.isLoadingCompanyCSV$,
          this.getExportCompanyCSVString$,
          'daten-unternehmen'
        );
      }
    } else {
      if (appView == AppViewEnum.HYDROGEN) {
        this.store.dispatch(new OGEExportVNB());
        this.isLoadingAnyGivenCSV(
          this.isLoadingOGEVNBCSV$,
          this.getExportVNBCSVString$,
          'vnb-daten'
        );
        this.store.dispatch(new OGEExportVNBSummary());
        this.isLoadingAnyGivenCSV(
          this.isLoadingOGEVNBSummaryCSV$,
          this.getExportVNBSummaryCSVString$,
          'vnb-daten-zusammenfassung'
        );
      } else if (appView == AppViewEnum.CARBON_DIOXIDE) {
        this.store.dispatch(new ExportFacilityTable());
        this.isLoadingAnyGivenCSV(
          this.isLoadingFacilityCSV$,
          this.getExportFacilityCSVString$,
          'co2-daten-standorte'
        );
        this.store.dispatch(new ExportCompanyTable(EExportBrille.NUMBER_1562));
        this.isLoadingAnyGivenCSV(
          this.isLoadingCompanyCSV$,
          this.getExportCompanyCSVString$,
          'co2-daten-unternehmen'
        );
      }
    }
  }

  public facilitySort(sort: MatSort) {
    if (this.facilityTable?.length == 0 && sort != null) {
      const facilitySortEvents$: Observable<Sort> = fromMatSort(sort);

      this.sortedFacilityTable$ = this.facilityList$.pipe(
        sortRows(facilitySortEvents$),
        untilDestroyed(this)
      );

      this.sortedFacilityTable$
        .pipe(untilDestroyed(this))
        .subscribe(sortedFacilityTable => {
          this.facilityTable = sortedFacilityTable;
        });
    }
  }

  public companySort(sort: MatSort) {
    if (this.companyTable?.length == 0 && sort != null) {
      combineLatest([this.isVNB$, this.isUserOGE$, this.isAppViewCo2$])
        .pipe(untilDestroyed(this))
        .subscribe(([isVNB, isOOGE, isAppViewCo2]) => {
          if (isVNB || (isOOGE && isAppViewCo2)) {
            const companySortEvents$: Observable<Sort> = fromMatSort(sort);
            this.sortedCompanyTable$ = this.companyTable$.pipe(
              untilDestroyed(this),
              sortRows(companySortEvents$)
            );
            combineLatest([this.isLoading$, this.sortedCompanyTable$])
              .pipe(
                untilDestroyed(this),
                filter(([loading, _]) => !loading)
              )
              .subscribe(([_, companyTable]) => {
                this.companyTable = companyTable;
              });
          }
        });
    }
  }

  public vnbSort(sort: MatSort) {
    if (this.vnbTable.length == 0 && sort != null) {
      const vnbSortEvents$: Observable<Sort> = fromMatSort(sort);

      this.sortedVnbTable$ = this.vnbData$.pipe(
        sortRows(vnbSortEvents$),
        untilDestroyed(this)
      );

      this.sortedVnbTable$
        .pipe(untilDestroyed(this))
        .subscribe(sortedVNBTable => {
          this.vnbTable = sortedVNBTable;
        });
    }
  }

  private isNumber(string: string) {
    const int = parseInt(string, 10);
    return !isNaN(int) && Number.isInteger(int);
  }

  private setTable() {
    if (this.sortedCompanyTable$ != null) {
      this.sortedCompanyTable$
        .pipe(take(1))
        .subscribe(companyTable => (this.companyTable = companyTable));
    }
    if (this.sortedFacilityTable$ != null) {
      this.sortedFacilityTable$
        .pipe(take(1))
        .subscribe(facilityTable => (this.facilityTable = facilityTable));
    }

    if (this.sortedVnbTable$ != null) {
      this.sortedVnbTable$
        .pipe(take(1))
        .subscribe(vnbTable => (this.vnbTable = vnbTable));
    }
  }

  private buildCSVName(name: string): string {
    const now = new Date();
    const datePart = now.toLocaleDateString('de-DE').replace(/\./g, '-'); // z.B., 14-02-2023
    const timePart = now
      .toLocaleTimeString('de-DE', { hour12: false })
      .replace(/:/g, '-'); // z.B., 14-23
    return `${datePart}--${timePart}-${name}`;
  }

  private isLoadingAnyGivenCSV(
    isLoading$: Observable<boolean>,
    csvObservable$: Observable<string>,
    fileName: string
  ) {
    isLoading$
      .pipe(
        filter(isLoading => !isLoading),
        take(1),
        switchMap(() => csvObservable$)
      )
      .subscribe(csv => {
        if (csv !== null) {
          const generatedFileName = this.buildCSVName(fileName);
          this.fileService.downloadCsvFromBase64(csv, generatedFileName);
        }
      });
  }
}
