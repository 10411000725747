import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DashboardSelectors } from './state/dashboard.selectors';
import { Observable, combineLatest, take } from 'rxjs';
import { H2YearlySummaryGraphDataModel } from './models/h2-yearly-summary-graph-data.model';
import { AppStateSelectors } from '@appState/app-state.selector';
import { DashboardService } from '@pages/dashboard/services/dashboard.service';
import { DialogService } from '@services';
import {
  RegisterCo2LocationComponent,
  RegisterH2LocationComponent,
} from '@facility';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthGuardService } from '../../features/authentication/services/auth-guard.service';
import { AppViewEnum } from '../../shared/enums/app-view-enum';
import { FacilityCo2Type, FacilityH2Type } from '@enums';
import { SetActiveDataTypes } from '@pages/map/state/map.actions';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.component.html',
  styleUrls: ['./dashboard.page.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  public readonly h2FacilityType = FacilityH2Type;
  public readonly co2FacilityType = FacilityCo2Type;
  @Select(DashboardSelectors.getGraphData)
  public readonly data$: Observable<H2YearlySummaryGraphDataModel>;

  @Select(AppStateSelectors.isUserVNB())
  public readonly isUserVnb$: Observable<boolean>;

  @Select(AppStateSelectors.isUserOGE())
  public readonly isUserOGE$: Observable<boolean>;

  @Select(AppStateSelectors.getAppView())
  public readonly appView$: Observable<AppViewEnum>;

  @Select(AppStateSelectors.isUserAllowed())
  public readonly isUserAllowed$: Observable<boolean>;

  @Select(AppStateSelectors.isUserLoggedIn())
  public readonly isUserLoggedIn$: Observable<boolean>;

  @Select(AppStateSelectors.isUserCo2Customer())
  public readonly isUserCo2Customer$: Observable<boolean>;

  public isCo2View$: Observable<boolean> = this.store
    .select(AppStateSelectors.getAppView())
    .pipe(map(appView => appView === AppViewEnum.CARBON_DIOXIDE));

  @Select(AppStateSelectors.userMustCompleteRegistration())
  public readonly userMustCompleteRegistration$: Observable<boolean>;

  public constructor(
    private readonly dashboardService: DashboardService,
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authGuardService: AuthGuardService,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.authGuardService.authGuard(),
      this.isUserOGE$,
      this.appView$,
      this.isUserCo2Customer$,
      this.userMustCompleteRegistration$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(
        ([
          isAllowed,
          isUserOge,
          appView,
          isCo2Customer,
          mustCompleteRegistration,
        ]) => {
          if (!mustCompleteRegistration) {
            if (!this.router.url.includes('co2') && isCo2Customer) {
              // redirect to co2
              this.router.navigate(['/co2/uebersicht']);
            } else if (this.router.url.includes('co2') && !isCo2Customer) {
              this.router.navigate(['/uebersicht']);
            } else if (
              isAllowed &&
              ((isUserOge && appView === AppViewEnum.CARBON_DIOXIDE) ||
                !isUserOge)
            ) {
              this.dashboardService.initDashboard();
            } else {
              this.router.navigate(['karte'], {
                relativeTo: this.activatedRoute,
              });
            }

            let activeDatasetType = [];
            if (appView === AppViewEnum.CARBON_DIOXIDE) {
              activeDatasetType = [
                this.co2FacilityType.Ausspeisung,
                this.co2FacilityType.Einspeisung,
                this.co2FacilityType.Hybrid,
              ];
            } else {
              activeDatasetType = [
                this.h2FacilityType.Consumer,
                this.h2FacilityType.Producer,
              ];
            }
            this.store.dispatch(new SetActiveDataTypes(activeDatasetType));
          } else if (!isAllowed) {
            this.router.navigate(['karte'], {
              relativeTo: this.activatedRoute,
            });
          }
        }
      );
  }

  public openRegisterLocation(): void {
    this.isUserCo2Customer$.pipe(take(1)).subscribe(isUserCo2Customer => {
      if (isUserCo2Customer) {
        this.dialogService.openRegisterLocation(RegisterCo2LocationComponent);
      } else {
        this.dialogService.openRegisterLocation(RegisterH2LocationComponent);
      }
    });
  }
}
