/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TCompanyShortDTO } from './tCompanyShortDTO';


export interface TDashboardOverviewNetworkDTO { 
    amount_customers?: number;
    amount_locations?: number;
    amount_demand?: number;
    amount_production?: number;
    amount_both?: number;
    company?: TCompanyShortDTO;
}

