<header>
  <div class="dialog-first-row">
    <div *ngIf="currentPage === 1"></div>
    <button (click)="backToStart()"
            *ngIf="currentPage === 2"
            class="back-button"
            mat-icon-button>
      <mat-icon>west</mat-icon>
    </button>
    <button class="close-button" mat-dialog-close mat-icon-button>
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>

  <div class="dialog-last-row">
    <h3 class="color-white">{{
        (
          isReadonly ?
            'registration.register_location_headline_readonly'
            : 'registration.register_location_headline')
          | translate
      }}</h3>

    <button (click)="changeIsReadonly()"
            *ngIf="isReadonly && (isUserCustomer$ | async)"
            class="location-edit-button button-as-Link"
            mat-flat-button>
      <mat-icon>edit</mat-icon>
      {{ 'registration.register_location_edit' | translate }}
    </button>
  </div>
</header>

<mat-dialog-content>
  <p *ngIf="currentPage === 1" class="caption-gotham required-field">{{'app.required_fields' | translate }}</p>
  <app-loading-spinner *ngIf="(actionState$ | async).isLoading; else data"></app-loading-spinner>
  <ng-template #data>
    <form [class.is-second-page]="isCurrentPageSecondOneAndVnbHasPolicy" [formGroup]="form">
      <app-register-h2-years-form *ngIf="currentPage === 1"
                                  [form]="form"
                                  [years]="years">
      </app-register-h2-years-form>
      <app-verteilnetzbetreiber *ngIf="currentPage === 2">
      </app-verteilnetzbetreiber>
    </form>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!isReadonly" [formGroup]="form">
  <div class="checkbox-container">
    <ng-container *ngIf="(vnbs$ | async).length > 0">
      <mat-checkbox *ngIf="currentPage === 2" color="accent" formControlName="legal">
        <mat-label>
          {{ 'registration.location.accepted' | translate }}
        </mat-label>
      </mat-checkbox>
      <div *ngIf="isCurrentPageSecondOneAndVnbHasPolicy()"
           class="checkbox-href-container default-text">
        <mat-checkbox color="accent" formControlName="policy">
          <mat-label>
            {{ 'registration.terms_policy_start' | translate }}
            <button (click)="openPolicy()" class="button-as-Link medium">{{ 'registration.policy' | translate }}</button>
            {{ 'registration.terms_policy_end' | translate }}
          </mat-label>
        </mat-checkbox>
      </div>
    </ng-container>
  </div>
  <span *ngIf="errorYears.length > 0" class="error">
    {{
      (errorYears.length === 1
        ? 'registration.register_location.error'
        : 'registration.register_location.error_plural') | translate : {'year': errorYears.join(', ')}
    }}
  </span>
  <button (click)="checkFormValidation()"
          *ngIf="currentPage === 1"
          class="button-gotham"
          color="accent"
          mat-flat-button
          type="submit">
    {{ 'registration.register_location.next' | translate }}
  </button>
  <button (click)="onCreate()"
          *ngIf="currentPage === 2"
          [disabled]="form?.invalid"
          color="accent"
          mat-flat-button
          type="submit">
    {{ 'registration.register_location.register' | translate }}
  </button>
</mat-dialog-actions>
