import { Injectable } from '@angular/core';
import {
  CustomerVNBRegistrationModel,
  UserRegistrationModel,
} from '@registration/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import {
  TCreateStandardUserDTO,
  TCreateVNBUserDTO,
  TUserDTO,
  UserService,
} from '@api';

@UntilDestroy()
@Injectable()
export class HydrogenRegistrationService {
  public currentPage = 'start';
  public domainIsNotExists = true;
  public companyId: number;

  constructor(private readonly userService: UserService) {}

  public backToStart() {
    this.currentPage = 'start';
  }

  public registration(rawValue) {
    if (this.currentPage === 'customer') {
      return this.onCustomerRegistration(rawValue);
    } else {
      // vnb registration
      return this.onVNBRegistration(rawValue);
    }
  }

  private onCustomerRegistration(rawData): Observable<TUserDTO> {
    return this.userService.apiUserPost({
      h2_std_user: this.mapCustomerUserFormToCustomerApiModel(rawData),
    });
  }

  private onVNBRegistration(rawData): Observable<TUserDTO> {
    return this.userService.apiUserPost({
      h2_vnb_user: this.mapCustomerVNBFormToCustomerApiModel(rawData),
    });
  }

  private mapCustomerUserFormToCustomerApiModel(
    customerUserForm: UserRegistrationModel
  ): TCreateStandardUserDTO {
    const address = customerUserForm.companyData.companyAddress;
    return {
      user_first_name: customerUserForm.firstname,
      user_last_name: customerUserForm.lastname,
      user_mail: customerUserForm.email,
      user_b2c_object_id: customerUserForm.userB2cObjectId,
      user_company: {
        company_id: this.companyId,
        company_name: customerUserForm.companyData.companyName,
        company_location: {
          location_city: address.city,
          location_latitude: address.lng,
          location_longitude: address.lat,
          location_street: address.street,
          location_street_number: address.streetNumber,
          location_zip_code: address.zipCode,
        },
      },
    };
  }

  private mapCustomerVNBFormToCustomerApiModel(
    customerVNBForm: CustomerVNBRegistrationModel
  ): TCreateVNBUserDTO {
    const address = customerVNBForm.companyData.companyAddress;
    return {
      user_first_name: customerVNBForm.firstname,
      user_last_name: customerVNBForm.lastname,
      user_mail: customerVNBForm.email,
      user_b2c_object_id: customerVNBForm.userB2cObjectId,
      user_company: {
        company_id: this.companyId,
        company_name: customerVNBForm.companyData.companyName,
        company_location: {
          location_city: address.city,
          location_latitude: address.lng,
          location_longitude: address.lat,
          location_street: address.street,
          location_street_number: address.streetNumber,
          location_zip_code: address.zipCode,
        },
      },
      company_plz_liste: customerVNBForm.companyData.zipCodeArea,
      company_logo: customerVNBForm.companyData.fileLogo
        ? {
            document_string: customerVNBForm.companyData.fileLogo,
            document_description: 'Company Logo',
          }
        : null,
      company_datenschutz_erklaerung: customerVNBForm.companyData.filePolicy
        ? {
            document_string: customerVNBForm.companyData.filePolicy,
            document_description: 'Datenschutz',
          }
        : null,
    };
  }
}
