import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { OssLicensesComponent } from '@pages/oss-licenses/oss-licenses.component';
import { DashboardPageComponent } from '@pages/dashboard/dashboard.page.component';
import { FaqPage } from '@pages/faq/faq.page';
import { ImprintPage } from '@pages/imprint/imprint.page';
import { MapPageComponent } from '@pages/map/map-page.component';
import { urlGuard } from '@guard';

const routes: readonly Readonly<Route>[] = Object.freeze([
  {
    path: 'uebersicht',
    component: DashboardPageComponent,
    canActivate: [MsalGuard, urlGuard],
  },
  { path: 'faq', component: FaqPage, canActivate: [urlGuard] },
  {
    path: 'licenses',
    component: OssLicensesComponent,
    canActivate: [urlGuard],
  },
  { path: 'impressum', component: ImprintPage, canActivate: [urlGuard] },
  { path: 'karte', component: MapPageComponent, canActivate: [urlGuard] },
  {
    path: 'daten',
    loadChildren: () =>
      import('@pages/data-table/data-table.page.module').then(
        m => m.DataTablePageModule
      ),
  },
  {
    path: 'verwaltung',
    loadChildren: () =>
      import('./pages/administration/administration-page.module').then(
        m => m.AdministrationPageModule
      ),
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent,
  },
  // redirects
  { path: 'imprint', redirectTo: 'impressum' },
  { path: '**', redirectTo: 'karte' },
]);

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: 'vnb/:vnbId',
          children: [...(routes as Routes)],
        },
        {
          path: 'co2',
          children: [...(routes as Routes)],
        },
        {
          path: 'h2',
          children: [
            ...(routes as Routes),
            {
              path: 'vnb/:vnbId',
              children: [...(routes as Routes)],
            },
          ],
        },
        ...routes,
      ],
      {
        useHash: false,
      }
    ),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutesModule {}
