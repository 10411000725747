import { ActionStateModel } from './action-state-model';

export class ActionState {
  public static create(): ActionStateModel {
    return {
      isLoading: false,
      done: false,
      error: null,
    };
  }

  public static onStart(): ActionStateModel {
    return {
      isLoading: true,
      done: false,
      error: null,
    };
  }

  public static onDone(): ActionStateModel {
    return {
      isLoading: false,
      done: true,
      error: null,
    };
  }

  public static onError(error: boolean): ActionStateModel {
    return {
      isLoading: false,
      done: false,
      error: error,
    };
  }
}
