import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetCookiesAccepted } from '@appState/app.actions';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { LegalService } from '@services';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent {
  public cookieFunctionalControl: FormControl = new FormControl<boolean>(
    { value: true, disabled: true },
    [Validators.requiredTrue],
  );

  constructor(
    private readonly store: Store,
    private readonly matDialogRef: MatDialogRef<CookieConsentComponent>,
    private readonly legalService: LegalService,
  ) {}

  public onAccept(): void {
    this.store.dispatch(new SetCookiesAccepted(true));
    this.matDialogRef.close();
  }

  public openPolicy(): void {
    this.legalService.getPolicy().subscribe((data) => {
      this.legalService.openPDF(
        data.document_string,
        'Datenschutzerklärung - HyCo Connect',
      );
    });
  }
}
