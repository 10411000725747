<mat-toolbar [class.right-padding]="(isUserLoggedIn$ | async)">
  <img [class.isVNB]="(isUserVNB$ | async)" [appRelativeLink]="((isUserAllowed$| async) === false) ? 'karte': 'uebersicht'" alt="OGE Logo" class="logo" src="../../../../assets/OGE_HyCo_Connect_Logo_rgb_dbl.svg"/>
  <img *ngIf="companyLogoPath" [src]="companyLogoPath" alt="Kunden Logo" class="logo customer-logo"/>
  <nav class="main">
    <a *ngIf=" ((isUserOGE$ | async) && (isAppViewCo2$ | async)) || (isUserAllowed$ | async) && (isUserOGE$ | async)===false" appRelativeLink="uebersicht"
       class="body1-gotham">
      {{ 'navMenu.items.dashboard' | translate }}
    </a>

    <a appRelativeLink="karte" class="body1-gotham">
      {{ 'navMenu.items.map' | translate }}
    </a>

    <a *ngIf="isUserAllowed$ | async" appRelativeLink="daten" class="body1-gotham">
      {{ 'navMenu.items.data-table' | translate }}
    </a>

    <a appRelativeLink="faq" class="body1-gotham">
      {{ 'navMenu.items.faq' | translate }}
    </a>
  </nav>

  <div class="authentication-buttons"
       [class.loggedIn]="isUserLoggedIn$ | async">
    <div *ngIf="(isUserLoggedIn$ | async) === false" class="full-buttons">
      <button (click)="login()" class="full-button" color="primary" mat-stroked-button>{{'navigation.user.login' | translate}}</button>
      <button (click)="register()" class="full-button" color="primary" mat-flat-button>{{'navigation.user.register' | translate}}</button>
    </div>
    <div *ngIf="isUserLoggedIn$ | async" class="user-info">
      <mat-button-toggle-group *ngIf="isUserOGE$ | async"
                               class="oge-app-view"
                               (change)="toggleAppViewForOGE($event.value)"
                               [value]="appViewEnum.HYDROGEN">
        <mat-button-toggle class="caption-gotham" [value]="appViewEnum.HYDROGEN">{{'navMenu.items.oge.hydrogen' | translate}}</mat-button-toggle>
        <mat-button-toggle class="caption-gotham" [value]="appViewEnum.CARBON_DIOXIDE">{{'navMenu.items.oge.carbon_dioxide' | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
      <button class="account-btn" [matMenuTriggerFor]="menu" aria-label="Profile Account" mat-icon-button>
        <mat-icon color="accent">account_circle_outlined</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="user">
          <span class="username button-gotham color-copy">{{username$ | async | titlecase}}</span>
          <span *ngIf="userCompanyName$ | async as userCompany" class="user-company body1-gotham color-copy">
            {{ userCompany }}
          </span>
        </div>
        <div class="dividing-line"></div>
        <button *ngIf="(isUserAllowed$ | async) && ((isUserAdmin$ | async) || (isUserOGE$ | async) || (isUserVNB$ | async))"
                mat-menu-item routerLink="verwaltung">
          <mat-icon class="nav-icon-button">edit</mat-icon>
          {{'navigation.user.manage' | translate}}
        </button>
        <button (click)="logout()" mat-menu-item>
          <mat-icon class="nav-icon-button">logout</mat-icon>
          {{'navigation.user.logout' | translate}}
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
