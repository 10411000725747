/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TCO2LeistungDTO } from './tCO2LeistungDTO';


export interface TCO2AmountPerYearDTO { 
    year?: number;
    demand?: TCO2LeistungDTO;
    production?: TCO2LeistungDTO;
}

