<form [formGroup]="form">
    <h4>{{ 'registration.personal_data' | translate }}</h4>
    <div class="split-pane">
        <mat-form-field appearance="outline" color="accent">
            <mat-label>{{ 'registration.first_name' | translate }}</mat-label>
            <input
                    formControlName="firstname"
                    matInput
                    type="text"
            />
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">
            <mat-label>{{ 'registration.last_name' | translate }}</mat-label>
            <input
                    formControlName="lastname"
                    matInput
                    type="text"
            />
        </mat-form-field>

        <mat-form-field appearance="outline" color="accent">

            <mat-label>{{ 'registration.e_mail' | translate }}</mat-label>
            <input
                    formControlName="email"
                    matInput
                    type="text"
            />
        </mat-form-field>
    </div>
</form>