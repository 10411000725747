/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TGetCO2Company } from './tGetCO2Company';
import { TGetH2Company } from './tGetH2Company';


export interface TGetCompanyDTO { 
    h2_company?: TGetH2Company;
    co2_company?: TGetCO2Company;
}

