import { Component, OnInit } from '@angular/core';
import { HydrogenRegistrationService } from '@registration/components/hydrogen/hydrogen-registration.service';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Select, Store } from '@ngxs/store';
import { RegistrationService } from '@registration/services/registration.service';
import { HydrogenUserRegistrationFormModel } from '@registration/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyTypeEnum } from '@enums';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { RegistrationEntryComponent } from '@registration/components';
import { GetUsersCompany, LoadFacilityList } from '@appState/app.actions';
import { ErrorHandlingService } from '@services';
import { TGetH2Company } from '@api';

@UntilDestroy()
@Component({
  selector: 'app-hydrogen-actions',
  templateUrl: './hydrogen-actions.component.html',
  styleUrls: ['./hydrogen-actions.component.scss'],
})
export class HydrogenActionsComponent implements OnInit {
  @Select(AppStateSelectors.getUsersCompany())
  public readonly usersCompanyData$: Observable<TGetH2Company>;

  public form: FormGroup<HydrogenUserRegistrationFormModel>;

  public get legalFormGroup(): FormGroup {
    return this.form.controls.legalData;
  }

  public get currentPage(): string {
    return this.hydrogenRegistrationService.currentPage;
  }

  private _companyData: TGetH2Company;

  constructor(
    private readonly hydrogenRegistrationService: HydrogenRegistrationService,
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly errorHandlingService: ErrorHandlingService,
    private readonly parentFormGroup: FormGroupDirective,
    public dialogRef: MatDialogRef<RegistrationEntryComponent>
  ) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;

    this.usersCompanyData$.pipe(untilDestroyed(this)).subscribe(companyData => {
      if (companyData) {
        this._companyData = companyData;
        if (CompanyTypeEnum.VNB == companyData.company_type.company_type_id) {
          this.form.controls.registrationType.setValue('1');
        }
        this.openNextRegistrationPage();
        this.fillCompanyData();
      }
    });
  }

  public openTerms(): void {
    this.registrationService.openTerms();
  }

  public openPolicy(): void {
    this.registrationService.openPolicy();
  }

  public openNextRegistrationPage(): void {
    this.updateUserFormForNextPage();

    if (this.form.controls.registrationType.value === '0') {
      // kunde
      this.updateUserFormGroupForVNB(this.form, 'remove');
      this.hydrogenRegistrationService.currentPage = 'customer';
    } else {
      // VNB
      this.updateUserFormGroupForVNB(this.form, 'add');
      this.hydrogenRegistrationService.currentPage = 'vnb';
    }
  }

  public onRegistration(): void {
    this.hydrogenRegistrationService
      .registration(this.form.getRawValue())
      .subscribe({
        next: () => {
          this.store.dispatch(new LoadFacilityList());
          this.store.dispatch(new GetUsersCompany());
          this.dialogRef.close();
        },
        error: err => this.errorHandlingService.showError(err),
      });
  }

  private updateUserFormForNextPage(): void {
    if (!this.form.contains('companyData')) {
      this.form.addControl(
        'companyData',
        new FormGroup({
          companyName: new FormControl('', [Validators.required]),
          companyAddress: this.registrationService.buildAddressForm(),
        })
      );
    }
    if (!this.form.contains('legalData')) {
      this.form.addControl(
        'legalData',
        this.registrationService.buildLegalDataForm()
      );
    }
  }

  private updateUserFormGroupForVNB(
    form: FormGroup<HydrogenUserRegistrationFormModel>,
    update: 'add' | 'remove'
  ) {
    if (update === 'add') {
      form.controls.companyData.addControl(
        'zipCodeArea',
        new FormControl([], [Validators.required])
      );
      form.controls.companyData.addControl('fileLogo', new FormControl(''));
      form.controls.companyData.addControl(
        'filePolicy',
        new FormControl('', [Validators.required])
      );
    } else {
      form.controls.companyData.removeControl('zipCodeArea');
      form.controls.companyData.removeControl('fileLogo');
      form.controls.companyData.removeControl('filePolicy');
    }
  }

  private fillCompanyData(): void {
    this.hydrogenRegistrationService.companyId = this._companyData.company_id;
    this.form.controls.companyData.controls.companyName.setValue(
      this._companyData.company_name
    );
    const companyLocation = this._companyData.company_location;
    this.form.controls.companyData.controls.companyAddress.setValue({
      address: `${companyLocation.location_street} ${companyLocation.location_street_number}, ${companyLocation.location_zip_code} ${companyLocation.location_city}`,
      street: companyLocation.location_street,
      streetNumber: companyLocation.location_street_number,
      zipCode: companyLocation.location_zip_code,
      city: companyLocation.location_zip_code,
      lat: companyLocation.location_longitude,
      lng: companyLocation.location_latitude,
    });
    if (this.form.controls.companyData.contains('zipCodeArea')) {
      this.form.controls.companyData.controls.zipCodeArea.setValue([
        ...this._companyData.vnb_zip_codes,
      ]);
    }
    this.hydrogenRegistrationService.domainIsNotExists = false;
    this.form.controls.companyData.disable();
  }
}
