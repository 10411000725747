<form [formGroup]="form">
  <ng-container *ngIf="vnbs$ | async as vnbs">
    <mat-expansion-panel class="app-expansion-panel" expanded hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title class="body2-gotham">
          {{ (vnbs.length > 1 ? 'registration.vnb.title' : 'registration.vnb.title_single') | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="vnb-expansion-panel body2-gotham">
                <span *ngIf="vnbs.length === 0">
                        {{ 'registration.vnb.no_vnb_found' | translate }}
                    </span>
        <span *ngIf="vnbs.length === 1"
              class="single-vnb body2-gotham">
                        {{ vnbs[0].name }}
          <mat-icon fontIcon="done"></mat-icon>
                    </span>
        <div *ngIf="vnbs.length > 1">
          <mat-radio-group aria-labelledby="auswahl eines verteilnetzbetreiber"
                           class="vnb-radio-container"
                           formControlName="vnb">
            <mat-radio-button *ngFor="let vnb of vnbs" [value]="vnb.id"
                              color="accent">
              {{ vnb.name }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-expansion-panel>
  </ng-container>
</form>
