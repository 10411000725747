/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TGetCO2FacilityDTO } from './tGetCO2FacilityDTO';
import { TGetH2FacilityDTO } from './tGetH2FacilityDTO';


export interface TGetFacilityDTO { 
    h2_facility?: TGetH2FacilityDTO;
    co2_facility?: TGetCO2FacilityDTO;
}

