export interface TermsAndConditionsModal {
  version: string;
  allgemeine_geschaefts_bedingungen_pdf_as_string: string;
}

export interface PolicyModal {
  version: string;
  company_name: string;
  datenschutz_erklaerung_pdf_as_string: string;
}

export interface DocumentModel {
  "document_id": number;
  "document_description": string;
  "document_version": string;
  "document_string": string;
}