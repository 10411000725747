import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-upload-company-policy',
  templateUrl: './upload-company-policy.component.html',
  styleUrls: ['./upload-company-policy.component.scss'],
})
export class UploadCompanyPolicyComponent implements OnInit {
  @Input({ required: false })
  public isRequired = true;
  public form: FormGroup;
  private fileReader = new FileReader();

  public constructor(private readonly parentFormGroup: FormGroupDirective) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
  }

  public onSelectedPolicy(file: File): void {
    this.fileReader.onload = () => {
      const base64StringFile = this.fileReader.result
        ?.toString()
        .replace(/^data:(.*,)?/, '');
      this.form.get('filePolicy')?.setValue(base64StringFile);
    };
    this.fileReader.readAsDataURL(file);
  }
}
