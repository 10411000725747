/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TCompanyTypeDTO } from './tCompanyTypeDTO';
import { TLocationDTO } from './tLocationDTO';


export interface TGetCO2Company { 
    company_id?: number;
    company_name?: string | null;
    company_location?: TLocationDTO;
    company_type?: TCompanyTypeDTO;
    contact_first_name?: string | null;
    contact_last_name?: string | null;
    contact_telephone?: string | null;
    contact_function?: string | null;
    company_roles_list?: Array<number> | null;
}

