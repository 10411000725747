/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { TPostUserDTO } from '../model/models';
import { TUserAdminplusCompanyDTO } from '../model/models';
import { TUserDTO } from '../model/models';
import { TUserPutDTO } from '../model/models';
import { TUserWithStateDTO } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param email 
     */
    apiUserEmailGet(email: string, extraHttpRequestParams?: any): Observable<TUserWithStateDTO>;

    /**
     * 
     * 
     */
    apiUserGet(extraHttpRequestParams?: any): Observable<Array<TUserAdminplusCompanyDTO>>;

    /**
     * 
     * 
     * @param tPostUserDTO 
     */
    apiUserPost(tPostUserDTO?: TPostUserDTO, extraHttpRequestParams?: any): Observable<TUserDTO>;

    /**
     * 
     * 
     * @param tUserPutDTO 
     */
    apiUserPut(tUserPutDTO?: TUserPutDTO, extraHttpRequestParams?: any): Observable<{}>;

}
