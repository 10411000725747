/**
 * H2C Backend
 * An ASP.NET Core Web API for managing H2C User 
 *
 * The version of the OpenAPI document: V32:2024_09_17_04
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TGetFacilty03DTO { 
    facility_id?: number;
    facility_company_id?: number;
    facility_location_id?: number;
    facility_name?: string | null;
    facility_is_public?: boolean;
    facility_type_id?: number;
    facility_logo?: string | null;
}

