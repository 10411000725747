<form [formGroup]="form">
    <mat-label class="headline-gotham-black">
        {{ 'registration.select_role' | translate }}*
    </mat-label>
    <section class="role-container" id="role-form-id" [formGroup]="form.controls.role">
        <mat-checkbox formControlName="io"
                      [value]="roleEnum.IO.toString()">{{'registration.role_io' | translate}}
            <mat-icon fontIcon="info_outline"
                      class="mat-icon-headline"
                      matTooltip="{{'registration.role_io_info' | translate}}"
                      matTooltipPosition="right"
                      matTooltipTouchGestures="on"
            ></mat-icon>
        </mat-checkbox>
        <mat-checkbox formControlName="consume"
                      [value]="roleEnum.CONSUME.toString()">{{'registration.role_consume' | translate}}
            <mat-icon fontIcon="info_outline"
                      class="mat-icon-headline"
                      matTooltip="{{'registration.role_consume_info' | translate}}"
                      matTooltipPosition="right"
                      matTooltipTouchGestures="on"
            ></mat-icon>
        </mat-checkbox>
        <mat-checkbox formControlName="vchain"
                      [value]="roleEnum.VCHAIN.toString()">{{'registration.role_vchain' | translate}}
            <mat-icon fontIcon="info_outline"
                      class="mat-icon-headline"
                      matTooltip="{{'registration.role_vchain_info' | translate}}"
                      matTooltipPosition="right"
                      matTooltipTouchGestures="on"
            ></mat-icon>
        </mat-checkbox>
        <mat-checkbox formControlName="store"
                      [value]="roleEnum.STORE.toString()">{{'registration.role_store' | translate}}
            <mat-icon fontIcon="info_outline"
                      class="mat-icon-headline"
                      matTooltip="{{'registration.role_store_info' | translate}}"
                      matTooltipPosition="right"
                      matTooltipTouchGestures="on"
            ></mat-icon>
        </mat-checkbox>
        <mat-checkbox formControlName="hub"
                      [value]="roleEnum.HUB.toString()">{{'registration.role_hub' | translate}}
            <mat-icon fontIcon="info_outline"
                      class="mat-icon-headline"
                      matTooltip="{{'registration.role_hub_info' | translate}}"
                      matTooltipPosition="right"
                      matTooltipTouchGestures="on"
            ></mat-icon>
        </mat-checkbox>
        <mat-checkbox formControlName="cluster"
                      [value]="roleEnum.CLUSTER.toString()">{{'registration.role_cluster' | translate}}
            <mat-icon fontIcon="info_outline"
                      class="mat-icon-headline"
                      matTooltip="{{'registration.role_cluster_info' | translate}}"
                      matTooltipPosition="right"
                      matTooltipTouchGestures="on"
            ></mat-icon>
        </mat-checkbox>
    </section>
</form>