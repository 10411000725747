import { NgModule } from '@angular/core';
import { AuthenticationModule } from '@authentication';
import { MapModule } from '@map/map.module';
import { RegistrationModule } from '@registration/registration.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { DialogVnbPlzComponent } from './facility/components/dialog-vnb-plz/dialog-vnb-plz.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [
    AuthenticationModule,
    MapModule,
    RegistrationModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    AsyncPipe,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    NgIf,
    ReactiveFormsModule,
  ],
  exports: [AuthenticationModule, MapModule, RegistrationModule],
  declarations: [DialogVnbPlzComponent],
})
export class FeaturesModule {}
