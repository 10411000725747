import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { Co2UserRegistrationFormModel } from '@registration/interfaces';
import { RegistrationService } from '@registration/services';
import { AppStateSelectors } from '@appState/app-state.selector';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompanyTypeEnum } from '@enums';
import { TGetCO2Company } from '@api';

@UntilDestroy()
@Component({
  selector: 'app-co2-customer-registration',
  templateUrl: './co2-customer-registration.component.html',
  styleUrls: ['./co2-customer-registration.component.scss'],
})
export class Co2CustomerRegistrationComponent implements OnInit {
  @Select(AppStateSelectors.getUsersCompany())
  public usersCompany$: Observable<TGetCO2Company>;

  public form: FormGroup<Co2UserRegistrationFormModel>;

  public userHasCompany = false;

  constructor(
    private readonly parentFormGroup: FormGroupDirective,
    private readonly registrationService: RegistrationService
  ) {}

  public ngOnInit(): void {
    this.form = this.parentFormGroup.control;
    // add new controls
    this.buildCo2Form();

    this.usersCompany$.pipe(untilDestroyed(this)).subscribe(company => {
      if (
        company &&
        company.company_type.company_type_id === CompanyTypeEnum.CO2_CUSTOMER
      ) {
        this.userHasCompany = true;
        this.fillCompanyData(company);
      }
    });
  }

  private buildCo2Form() {
    if (!this.form.contains('companyName')) {
      this.form.addControl(
        'companyName',
        new FormControl('', [Validators.required])
      );
    }

    if (!this.form.contains('companyAddress')) {
      this.form.addControl(
        'companyAddress',
        this.registrationService.buildAddressForm()
      );
    }

    if (!this.form.contains('legalData')) {
      this.form.addControl(
        'legalData',
        this.registrationService.buildLegalDataForm()
      );
    }

    if (!this.form.contains('contactPersonFirstname')) {
      this.form.addControl(
        'contactPersonFirstname',
        new FormControl('', [Validators.required])
      );
    }

    if (!this.form.contains('contactPersonLastname')) {
      this.form.addControl(
        'contactPersonLastname',
        new FormControl('', [Validators.required])
      );
    }

    if (!this.form.contains('roleOfContactPerson')) {
      this.form.addControl('roleOfContactPerson', new FormControl(''));
    }

    if (!this.form.contains('phoneNumberOfContactPerson')) {
      this.form.addControl(
        'phoneNumberOfContactPerson',
        new FormControl('', [
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(5),
          Validators.maxLength(20),
        ])
      );
    }

    if (!this.form.contains('role')) {
      this.form.addControl(
        'role',
        this.registrationService.buildCo2RoleGroup()
      );
    }
  }

  private fillCompanyData(company: TGetCO2Company): void {
    const address = company.company_location;
    this.form.controls.companyName.setValue(company.company_name);
    this.form.controls.companyAddress.setValue({
      address: `${address.location_street} ${address.location_street_number} ${address.location_zip_code} ${address.location_city}`,
      street: address.location_street,
      streetNumber: address.location_street_number,
      city: address.location_city,
      zipCode: address.location_zip_code,
      lat: address.location_longitude,
      lng: address.location_latitude,
    });
    this.form.disable();
    this.form.controls.legalData.enable();
  }
}
