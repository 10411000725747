const MAP_ACTION_TYPE = '[Map]';

export class SetMapIsLoading {
  public static type = `${MAP_ACTION_TYPE} SetMapIsLoading`;

  public constructor(public readonly isLoading?: boolean) {}
}

export class SetActiveDataTypes {
  public static type = `${MAP_ACTION_TYPE} SetActiveDataTypes`;

  public constructor(public readonly dataTypes: number[]) {}
}
