import { UserStatus } from '@enums';
import { ExportGlass } from '../../../shared/enums/export-glass';

const ADMINISTRATION_ACTION_TYPE = '[Administration]';

export class GetUsers {
  public static type = `${ADMINISTRATION_ACTION_TYPE} GetUsers`;
}

export class CleanExportData {
  public static type = `${ADMINISTRATION_ACTION_TYPE} CleanExportData`;
}

export class ExportUserData {
  public static type = `${ADMINISTRATION_ACTION_TYPE} ExportUserData`;

  public constructor(
    public readonly glasses?: ExportGlass,
    public readonly filename?: string
  ) {}
}

export class ChangeUserState {
  public static type = `${ADMINISTRATION_ACTION_TYPE} ChangeUserState`;

  public constructor(
    public readonly userId: number,
    public readonly setUserStateId: UserStatus
  ) {}
}

export class SetUserToAdmin {
  public static type = `${ADMINISTRATION_ACTION_TYPE} SetUserToAdmin`;

  public constructor(
    public readonly userId: number,
    public readonly setIsUserCompanyAdmin: boolean
  ) {}
}
